<app-navbar></app-navbar>

<div class="main" id="scroll_top">
  <section
    class="hero section-padding pb-0 bg-gray"
    id="driving"
    data-scroll-index="driving"
  >
    <div class="bg-gray text-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about mb-50">
              <h4>Learn  More</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="hero pb-0 section-padding padd90 section-space40"
    id="driving"
    data-scroll-index="driving"
  >
    <div class=" ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row mt-5 vertical-content">
              <div class="col-lg-12">
                <div class="terms-div"></div>

                <h4 class="terms-heading">1.GENERAL</h4>

                <br />
                <p class="terms-para">
                  b.For the purpose of these Terms of Use (“Terms”), wherever
                  the context so requires,
                </p>
                <ol type="a">
                  <li>
                    <i>“We”, “Our”,</i> and <i>“Us”</i> shall mean and refer to
                    the Platform and/or the Company, as the context so requires.
                  </li>

                  <li>
                    <i>“You”, “Your”, “Yourself”, “User” </i>shall mean and
                    refer to natural and legal individuals who use the
                    Application or Website and who is competent to enter into
                    binding contracts, as per Indian laws.
                  </li>
                  <li>
                    <i>“User” </i>shall refer to the individuals having access
                    to and using the Platform.
                  </li>
                  <li>
                    <i>“Services” </i>refer to providing a digital communication
                    platform where people can connect with new people, brands,
                    businesses without sharing their personal mobile number
                    where the User will be given a specific "calvary". Services
                    provided by the platform with the usage of the calvary,
                    User's can make free voice calls, send messages, and send
                    disappearing messages that are meant to disappear. The
                    mission of the development of this Application is to protect
                    the privacy of peoples.
                  </li>

                  <li>
                    <i>“Third Parties” </i>refer to any Application, company or
                    individual apart from the User and the creator of this
                    Application.
                  </li>

                  <li>
                    The term <i>“Platform”</i> refers to the Application and/or
                    Website which provides the User with the services.
                  </li>
                  <li>
                    <b
                      >The headings of each section in these Terms are only for
                      the purpose of organizing the various provisions under
                      these Terms in an orderly manner and shall not be used by
                      either Party to interpret the provisions contained herein
                      in any manner. Further, it is specifically agreed to by
                      the Parties that the headings shall have no legal or
                      contractual value.</b
                    >
                  </li>

                  <li>
                    The use of this Platform by the Users is solely governed by
                    these Terms as well as the Privacy Policy, and any
                    modifications or amendments made thereto by the Company,
                    from time to time, at its sole discretion. If You continue
                    to access and use this Platform, you are agreeing to comply
                    with and be bound by the following Terms and Conditions of
                    Use and Our Privacy Policy. The User expressly agrees and
                    acknowledges that these Terms and Policy are co-terminus in
                    nature and that expiry/termination of either one will lead
                    to the termination of the other.
                  </li>

                  <li>
                    The User unequivocally agrees that these Terms and the
                    aforementioned Policy constitute a legally binding agreement
                    between the User and the Company, and that the User shall be
                    subject to the rules, guidelines, policies, terms, and
                    conditions applicable to any service that is provided by the
                    Platform, and that the same shall be deemed to be
                    incorporated into these Terms, and shall be treated as part
                    and parcel of the same. The User acknowledges and agrees
                    that no signature or express act is required to make these
                    Terms and the Policy binding on the User and that the User’s
                    act of visiting any part of the Platform constitutes the
                    User’s full and final acceptance of these Terms and the
                    aforementioned Policy.
                  </li>

                  <li>
                    The Company reserves the sole and exclusive right to amend
                    or modify these Terms without any prior permission or
                    intimation to the User, and the User expressly agrees that
                    any such amendments or modifications shall come into effect
                    immediately. The User has a duty to periodically check the
                    terms and stay updated on its requirements. If the User
                    continues to use the Platform following such a change, the
                    User will be deemed to have consented to any and all
                    amendments/modifications made to the Terms. In so far as the
                    User complies with these Terms, it is granted a personal,
                    non-exclusive, non-transferable, revocable, limited
                    privilege to access and use the Platform and the Services.
                    If the User does not adhere to the changes, You must stop
                    using the Services at once. Your continued use of the
                    Services will signify your acceptance of the changed terms.
                  </li>
                </ol>
                <br />

                <h4 class="terms-heading">2.REGISTRATION</h4>
                <ol type="a">
                  <li>
                    Registration is mandatory for accessing any or all
                    information on the Platform. Users shall register themselves
                    on the Platform by providing information including but not
                    limited to personal information such as Name, Gender, Date
                    of Birth, Photo, Location, mobile number, etc.
                  </li>

                  <li>
                    The Users may also link their Facebook/Google accounts with
                    the Platform at the time of their registration.
                  </li>
                  <li>
                    The Registration and use of the Platform are free of cost
                    and no charges are applicable for the same.
                  </li>

                  <li>
                    Registration for this Platform is available only to those
                    above the age of eighteen (18) years, barring those
                    “Incompetent to Contract” which inter alia include
                    insolvents. If you are a minor and wish to use the Platform
                    as a User, You may do so through your legal guardian and the
                    Company reserves the right to terminate your account on
                    knowledge of you being a minor and having registered on the
                    Platform or availing any of its Services.
                  </li>

                  <li>
                    Further, at any time during Your use of this Platform,
                    including but not limited to the time of registration, You
                    are solely responsible for protecting the confidentiality of
                    Your Username and password, and any activity under the
                    account shall be deemed to have been done by You. In the
                    case that you provide us with false and/or inaccurate
                    details or we have reason to believe you have done so, we
                    hold the right to permanently suspend your account. You
                    agree that you will not disclose your password to any third
                    party and that you will take sole responsibility for any
                    activities or actions under your account, whether or not you
                    have authorized such activities or actions. You will
                    immediately notify us of any unauthorized use of your
                    account
                  </li>
                </ol>

                <br />

                <h4 class="terms-heading">3. SERVICE OVERVIEW</h4>

                <p class="terms-para">
                  The Company shall act as a provider for digital communication
                  platform where people can connect with new people, brands,
                  businesses without sharing their personal mobile number where
                  the User will be given a specific "calvary". Services provided
                  by the platform with the usage of the calvary, User's can make
                  free voice calls, send messages, and send disappearing
                  messages that are meant to disappear. The mission of the
                  development of this Application is to protect the privacy of
                  peoples.
                </p>

                <h4 class="terms-heading">4. ELIGIBILITY</h4>
                <p class="terms-para">
                  The User represents and warrants that they are competent and
                  eligible to enter into legally binding agreements and of 18
                  years of age and that they have the requisite authority to
                  bind themselves to these Terms in accordance with the Law. The
                  User further represents that they will comply with this
                  Agreement and all applicable local, state, national and
                  international laws, rules and regulations. The User may not
                  use the Platform if they are not competent to contract or are
                  disqualified from doing so by any other applicable law, rule
                  or regulation currently in force.
                </p>

                <br />

                <h4 class="terms-heading">5.CONTENT</h4>

                <p class="terms-para">
                  All text, graphics, User interfaces, visual interfaces,
                  photographs, trademarks, logos, brand names, descriptions,
                  sounds, music and artwork (collectively, ‘Content’), is
                  generated/provided or based on information provided by third
                  parties and We have no control and make no guarantees
                  regarding the quality, the accuracy, integrity or genuineness
                  of such Content. All the Content displayed on the Platform is
                  subject to copyright and shall not be reused by any party (or
                  a third party) without the prior written consent of the
                  Company and the copyright owner. The User is solely
                  responsible for the integrity, authenticity, quality and
                  genuineness of the content provided on the Platforms and
                  whilst feedback and comments by Users can be made via the
                  Platform, the Platform bears no liability whatsoever for any
                  feedback or comments made by the Users or made in respect of
                  any of the content on the Platform. Further, the Platform
                  reserves its right to suspend the account of any User for an
                  indefinite period to be decided at the discretion of the
                  Platform, or to terminate the account of any User who is found
                  to have created or shared or submitted any Content or part
                  thereof that is found to be untrue/inaccurate/misleading or
                  offensive/vulgar. The User shall be solely responsible for
                  making good any financial or legal losses incurred through the
                  creation/sharing/submission of Content or part thereof that is
                  deemed to be untrue/inaccurate/misleading. The Users have a
                  personal, non-exclusive, non-transferable, revocable, limited
                  privilege to access the Content on the Platform. Users shall
                  not copy, adapt, and modify any content without written
                  permission of the Company.
                </p>
                <br />

                <h4 class="terms-heading">6.TERM</h4>

                <ol type="a">
                  <li>
                    These Terms shall continue to form a valid and binding
                    contract between the Parties and shall continue to be in
                    full force and effect until the User continues to access and
                    use the Services and Platforms.
                  </li>
                  <li>
                    A User may terminate their use of the Services and the
                    Platform at any time.
                  </li>
                  <li>
                    The Company may terminate these Terms and close a User’s
                    account at any time without notice and/or suspend or
                    terminate a User’s access to the Platform at any time and
                    for any reason, if any discrepancy or legal issue arises.
                  </li>
                  <li>
                    Such suspension or termination shall not limit our right to
                    take any other action against you that the Company considers
                    appropriate.
                  </li>
                  <li>
                    It is also hereby declared that the Company may discontinue
                    the Services and Platforms without any prior notice.
                  </li>
                </ol>

                <br />
                <h4 class="terms-heading">7.TERMINATION</h4>
                <ol type="a">
                  <li>
                    The Company reserves the right, in its sole discretion, to
                    unilaterally terminate the User’s access to the Services, or
                    any portion thereof, at any time, with notice and may be
                    without notice or cause during emergencey
                  </li>
                  <li>
                    The Platform also reserves the universal right to deny
                    access to particular Users, to any/all of its Services with
                    or without any prior notice/explanation in order to protect
                    the interests of the Platform and/or other visitors to the
                    Platform.
                  </li>
                  <li>
                    The Platform reserves the right to limit, deny or create
                    different access to the Platform and its features with
                    respect to different Users, or to change any of the features
                    or introduce new features without prior notice.
                  </li>
                  <li>
                    The User shall continue to be bound by these Terms, and it
                    is expressly agreed to by the Parties that the User shall
                    not have the right to terminate these Terms till the expiry
                    of the same.
                  </li>
                </ol>

                <br />

                <h4 class="terms-heading">8. COMMUNICATION</h4>
                <p class="terms-para">
                  By using this Services and Platforms, and providing his/her
                  identity and contact information to the Company through the
                  Platform, the User hereby agrees and consents to receive
                  calls, e-mails or SMS from the Company and/or any of its
                  representatives at any time. Users can report to
                  Support@calvary.co if they find any discrepancy with regard to
                  Service related information and the Company will take
                  necessary action after an investigation. The response with
                  resolution (if any issues found) shall be dependent on the
                  time is taken for investigation. The User expressly agrees
                  that notwithstanding anything contained hereinabove, it may be
                  contacted by the Company or any representatives relating to
                  any service availed of by the User on the Platform or anything
                  pursuant thereto and the User agrees to indemnify the Company
                  from any and all harassment claims. It is expressly agreed to
                  by the Parties that any information shared by the User with
                  the Company shall be governed by the Privacy Policy.
                </p>
                <br />

                <h4 class="terms-heading">9. PAYMENT</h4>
                <u>Charges</u>

                <p class="terms-para">
                  Registration and subscription to the Services on the Platform
                  are free of cost. Further, the Platform does not bear any
                  liability for any activities, breaches, discrepancies or
                  technical problems experienced by the Users whilst using any
                  third-party payment gateway to make payments if any made for
                  any other user. The relationship between the Users and
                  third-party payment gateway shall be governed by the Terms of
                  Use and Privacy Policy of the said payment gateway, and the
                  Platform shall not be associated with any dispute arising
                  therefrom.
                </p>

                <br />

                <h4 class="terms-heading">
                  10.USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT
                </h4>
                <p>
                  The User agrees and acknowledges that they are a restricted
                  User of this Platform and that they:
                </p>

                <ol type="a">
                  <li>
                    Agree to provide genuine credentials during the process of
                    registration on the Platform. You shall not use a fictitious
                    identity to register. We are not liable if the User has
                    provided incorrect information.
                  </li>
                  <li>
                    Agree to ensure the Name, Email address, Photo, Address and
                    Mobile Number provided during account registration are valid
                    at all times and shall keep your information accurate and
                    up-to-date. The User can update their details anytime.
                  </li>

                  <li>
                    Agree that they are solely responsible for maintaining the
                    confidentiality of your account password. You agree to
                    notify us immediately of any unauthorized use of your
                    account. We reserve the right to close your account at any
                    time for any or no reason.
                  </li>

                  <li>
                    Understand and acknowledge that the data submitted is
                    manually entered into the database of the Platform. The User
                    also acknowledges the fact that data so entered into the
                    database is for the purpose of easy and ready reference for
                    the User, and to streamline the Services through the
                    Platform.
                  </li>

                  <li>
                    Authorize the Platform to use, store or otherwise process
                    certain personal information and all published Content, User
                    Purchases, Offer redemption's, User locations, User
                    comments, reviews and ratings for personalization of
                    Services, marketing and promotional purposes and for
                    optimisation of User-related options and Services
                  </li>

                  <li>
                    Understand and agree that, to the fullest extent permissible
                    by law, the Platform/Company and their successors and
                    assigns, or any of their affiliates or their respective
                    officers, directors, employees, agents, licensors,
                    representatives, operational service providers, advertisers
                    or suppliers shall not be liable for any loss or damage, of
                    any kind, direct or indirect, in connection with or arising
                    from use of the Platform or from this terms of use,
                    including, but not limited to, compensatory, consequential,
                    incidental, indirect, special or punitive damages.
                  </li>

                  <li>
                    Are bound not to cut, copy, modify, recreate, reverse
                    engineer, distribute, disseminate, post, publish or create
                    derivative works from, transfer, or sell any information or
                    obtained from the Platform. Any such use/limited use of the
                    Platform will only be allowed with the prior express written
                    permission of the Company.
                  </li>

                  <li>
                    Agree not to access (or attempt to access) the Platform
                    and/or the materials or Services by any means other than
                    through the interface provided by the Platform. The use of
                    deep-link, robot, spider or any other automatic device,
                    program, algorithm or methodology, or any similar or
                    equivalent manual process, to access, acquire, copy or
                    monitor any portion of the Platform or its content, or in
                    any way reproduce or circumvent the navigational structure
                    or presentation of the Platform, materials or any content,
                    or to obtain or attempt to obtain any materials, documents
                    or information through any means not specifically made
                    available through the Platform will lead to suspension or
                    termination of the User’s access to the Platform. The User
                    acknowledges and agrees that by accessing or using the
                    Platform or any of the Services provided therein, it may be
                    exposed to content that it may consider offensive, indecent
                    or otherwise objectionable. The Company disclaims any and
                    all liabilities arising in relation to such offensive
                    content on the Platform.
                  </li>

                  <p class="terms-para">
                    i.Expressly agree and acknowledge that the Content displayed
                    on the Platform is not owned by the Company and that the
                    Company is in no way responsible for the content of the
                    same. The User may, however, report any offensive or
                    objectionable content, which the Company may then remove
                    from the Platform, at its sole discretion.
                  </p>

                  <p class="terms-para">
                    Expressly consents to follow the terms and conditions, and
                    policies of the Sellers affiliated with the Company from
                    whom the Users are making purchases.
                  </p>

                  <p>The User further undertakes not to:</p>

                  <ol type="a">
                    <li>
                      Engage in any activity that interferes with or disrupts
                      access to the Platform or the Services provided therein
                      (or the servers and networks which are connected to the
                      Platform);
                    </li>
                    <li>
                      Impersonate any person or entity, or falsely state or
                      otherwise misrepresent his/her affiliation with a person
                      or entity;
                    </li>
                    <li>
                      Probe, scan or test the vulnerability of the Platform or
                      any network connected to the Platform, nor breach the
                      security or authentication measures on the Platform or any
                      network connected to the Platform. The User may not
                      reverse look-up, trace or seek to trace any information
                      relating to any other User of, or visitor to, the
                      Platform, or any other viewer of the Platform, including
                      any User account maintained on the Platform not
                      operated/managed by the User, or exploit the Platform or
                      information made available or offered by or through the
                      Platform, in any manner;
                    </li>

                    <li>
                      Disrupt or interfere with the security of, or otherwise
                      cause harm to, the Platform, systems resources, accounts,
                      passwords, servers or networks connected to or accessible
                      through the Platform or any affiliated or linked
                      Platforms;
                    </li>

                    <li>
                      Use the Platform or any material or content therein for
                      any purpose that is unlawful or prohibited by these Terms,
                      or to solicit the performance of any illegal activity or
                      other activity which infringes the rights of this Platform
                      or any other third party(s);
                    </li>

                    <li>
                      Violate any code of conduct or guideline which may be
                      applicable for or to any particular service offered on the
                      Platform;
                    </li>
                    <li>
                      Violate any applicable laws, rules or regulations
                      currently in force within or outside India;
                    </li>

                    <li>
                      Violate any portion of these Terms or the Privacy Policy,
                      including but not limited to any applicable additional
                      terms of the Platform contained herein or elsewhere,
                      whether made by amendment, modification, or otherwise;
                    </li>
                  </ol>

                  <p class="terms-para">
                    i.Commit any act that causes the Company to lose (in whole
                    or in part) the Services of its Internet Establishment
                    ("ISP") or in any manner disrupts the Services or any other
                    supplier/service provider of the Company/Platform;
                  </p>

                  <p class="terms-para">Further:</p>
                  <p class="terms-para">
                    j. The User hereby expressly authorizes the Company/Platform
                    to disclose any and all information relating to the User in
                    the possession of the Company/Platform to law enforcement or
                    other government officials, as the Company may in its sole
                    discretion, believe necessary or appropriate in connection
                    with the investigation and/or resolution of possible crimes,
                    especially those involve personal injury and
                    theft/infringement of intellectual property. The User
                    further understands that the Company/Platform might be
                    directed to disclose any information (including the identity
                    of persons providing information or materials on the
                    Platform) as necessary to satisfy any judicial Order, law,
                    regulation or valid governmental request.
                  </p>

                  <p class="terms-para">
                    k. The User agrees to use the services provided by the
                    Company, its affiliates, consultants and contracted
                    companies, for lawful purposes only.
                  </p>

                  <p class="terms-para">
                    l. The User agrees to provide authentic and true
                    information. The Company reserves the right to confirm and
                    validate the information and other details provided by the
                    User at any point of time. If upon confirmation such User
                    details are found to be false, not to be true (wholly or
                    partly), the Company shall in its sole discretion reject the
                    registration and debar the User from using the Services
                    available on its Website, and/or other affiliated websites
                    without prior intimation whatsoever.
                  </p>

                  <p class="terms-para">
                    m. The User agrees not to post any material on the platform
                    that is defamatory, offensive, obscene, indecent, abusive,
                    or needlessly distressful or advertising any goods or
                    services. More specifically, the User agrees not to host,
                    display, upload, update, publish, modify, transmit, or in
                    any manner share any information that:
                  </p>

                  <ol type="i">
                    <li>
                      belongs to another person and to which the User has no
                      right to;
                    </li>
                    <li>
                      is grossly harmful, harassing, blasphemous, defamatory,
                      obscene, pornographic, paedophilic, libellous, invasive of
                      another's privacy, hateful, or racially, ethnically
                      objectionable, disparaging, relating or encouraging money
                      laundering or gambling, or otherwise unlawful in any
                      manner whatever;
                    </li>

                    <li>is in any way harmful to minors;</li>

                    <li>
                      infringes any patent, trademark, copyright or other
                      proprietary rights;
                    </li>
                    <li>violates any law for the time being in force;</li>
                    <li>
                      deceives or misleads the addressee about the origin of
                      such messages or communicates any information which is
                      grossly offensive or menacing in nature;
                    </li>

                    <li>
                      Abuse, harass, threaten, defame, disillusion, erode,
                      abrogate, demean or otherwise violate the legal rights of
                      others;
                    </li>
                    <li>
                      Impersonate any person or entity, or falsely state or
                      otherwise misrepresent Your affiliation with a person or
                      entity;
                    </li>

                    <li>
                      Publish, post, disseminate, any information which is
                      grossly harmful, harassing, blasphemous, defamatory,
                      obscene, pornographic, paedophilic, libellous, invasive of
                      another's privacy, hateful, or racially, ethnically
                      objectionable, disparaging, relating or encouraging money
                      laundering or gambling, or otherwise unlawful in any
                      manner whatever; or unlawfully threatening or unlawfully
                      harassing including but not limited to "indecent
                      representation of women" within the meaning of the
                      Indecent Representation of Women (Prohibition) Act, 1986;
                    </li>

                    <li>
                      threatens the unity, integrity, defence, security or
                      sovereignty of India, friendly relations with foreign
                      states, or public order or causes incitement to the
                      commission of any cognisable offence or prevents
                      investigation of any offence or is insulting any other
                      nation.
                    </li>
                  </ol>
                  <br />
                  <h4 class="terms-heading">
                    11 SUSPENSION OF USER ACCESS AND ACTIVITY
                  </h4>

                  <p class="terms-para">
                    Notwithstanding other legal remedies that may be available,
                    the Company may in its sole discretion, limit the User’s
                    access and/or activity by immediately removing the User’s
                    access credentials either temporarily or indefinitely, or
                    suspend/terminate the User’s association with the Platform,
                    and/or refuse to usage of the Platform to the User, without
                    being required to provide the User with notice or cause:
                  </p>

                  <ol type="a">
                    <li>
                      If the User is in breach any of these Terms or the Policy;
                    </li>
                    <li>
                      If the User has provided wrong, inaccurate, incomplete or
                      incorrect information;
                    </li>
                    <li>
                      If the User’s actions may cause any harm, damage or loss
                      to the other Users or to the Company, at the sole
                      discretion of the Company.
                    </li>
                  </ol>

                  <br />

                  <h4 class="terms-heading">12. INDEMNITY</h4>
                  <p class="terms-para">
                    You (the User) agree to indemnify, defend and hold harmless
                    the Company/Platform, its service providers, and their
                    respective directors, officers, employees and agents
                    (collectively, "Parties"), from and against any and all
                    losses, liabilities, claims, damages, demands, costs and
                    expenses (including legal fees and disbursements in
                    connection therewith and interest chargeable thereon)
                    asserted against or incurred by us that arise out of, result
                    from, or may be payable by virtue of, any breach or
                    non-performance of any representation, warranty, covenant or
                    agreement made or obligation to be performed by You pursuant
                    to these terms of use. Further, you agree to hold us
                    harmless against any claims made by any third party due to,
                    or arising out of, or in connection with:
                  </p>

                  <ol type="1">
                    <li>Your use of the Platform,</li>
                    <li>Your violation of these Terms and Conditions;</li>
                    <li>Your violation of any rights of another;</li>
                    <li>
                      Your alleged improper conduct pursuant to these Services;
                    </li>
                    <li>Your conduct in connection with the Platform;</li>
                  </ol>

                  <p class="terms-para">
                    You agree to fully cooperate in indemnifying us at your
                    expense.&nbsp;You also agree not to reach a settlement with
                    any party without our consent. In no event shall the
                    Company/Platform be liable to compensate the User or any
                    third party for any special, incidental, indirect,
                    consequential or punitive damages whatsoever, including
                    those resulting from loss of use, data or profits, whether
                    or not foreseeable, and whether or not the Company/ Platform
                    had been advised of the possibility of such damages, or
                    based on any theory of liability, including breach of
                    contract or warranty, negligence or other tortuous action,
                    or any other claim arising out of or in connection with the
                    User’s use of or access to the Platform and/or the Services
                    or materials contained therein.
                  </p>

                  <br />

                  <h4 class="terms-heading">13. LIMITATION OF LIABILITY</h4>
                  <p class="terms-para">
                    a. The Founders/ Promoters/ Associated people of the
                    Platform/Platform are not responsible for any consequences
                    arising out of the following events:
                  </p>

                  <ol type="i">
                    <li>
                      If the Platform is inoperative/non-responsive due to any
                      connectivity errors associated with the internet
                      connection such as but not limited to slow connectivity,
                      no connectivity, server failure;
                    </li>

                    <li>
                      If the User has fed incorrect information or data or for
                      any deletion of data;
                    </li>
                    <li>
                      If there is an undue delay or inability to communicate
                      through email or message
                    </li>
                    <li>
                      If there is any deficiency or defect in the Services
                      managed by Us;
                    </li>
                    <li>
                      If there is a failure in the functioning of any other
                      service provided by the Platform.
                    </li>
                  </ol>

                  <p class="terms-para">
                    b. The Platform accepts no liability for any errors or
                    omissions, on behalf of itself, or for any damage caused to
                    the User, the User’s belongings, or to any third party,
                    resulting from the use or misuse of the Platform or any
                    service availed of by the User through the Platform. The
                    service and any Content or material displayed on the service
                    is provided without any guarantees, conditions or warranties
                    as to its accuracy, suitability, completeness or
                    reliability. The Platform will not be liable to you for the
                    unavailability or failure of the Platform.
                  </p>

                  <p class="terms-para">
                    c. Users are to comply with all laws applicable to them or
                    to their activities, and with all Policies, which are hereby
                    incorporated into this Agreement by reference.
                  </p>

                  <p class="terms-para">
                    d. The Platform expressly excludes any liability for any
                    loss or damage that was not reasonably foreseeable by the
                    Platform and which is incurred by you in connection with the
                    Platform, including loss of profits; and any loss or damage
                    incurred by you as a result of your breach of these
                    terms.&nbsp;
                  </p>

                  <p class="terms-para">
                    To the fullest extent permitted by law, the Platform shall
                    not be liable to you or any other party for any loss or
                    damage, regardless of the form of action or basis of any
                    claim. You acknowledge and agree that your sole and
                    exclusive remedy for any dispute with us is to terminate
                    your use of the Platform.
                  </p>

                  <br />

                  <h4 class="terms-heading">14.INTELLECTUAL PROPERTY RIGHTS</h4>
                  <p>
                    Unless expressly agreed to in writing, nothing contained
                    herein shall give the User a right to use any of the
                    Platform’s trade names, trademarks, service marks, logos,
                    domain names, information, questions, answers, solutions,
                    reports and other distinctive brand features, save according
                    to the provisions of these Terms. All logos, trademarks,
                    brand names, service marks, domain names, including
                    material, designs, and graphics created by and developed by
                    the Platform and other distinctive brand features of the
                    Platform are the property of the Company or the respective
                    copyright or trademark owner. Furthermore, with respect to
                    the Platform created by the Company, the Company shall be
                    the exclusive owner of all the designs, graphics and the
                    like, related to the Platform. The User may not use any of
                    the intellectual property displayed on the Platform in any
                    manner that is likely to cause confusion among existing or
                    prospective Users of the Platform, or that in any manner
                    disparages or discredits the Company/Platform, to be
                    determined in the sole discretion of the Company. The User
                    is aware all intellectual property, including but not
                    limited to copyrights, relating to said Services resides
                    with the owners, and that at no point does any such
                    intellectual property stand transferred from the
                    aforementioned creators to the Company, or to any other
                    User. The User is aware that the Company merely provides a
                    Platform through which the Users can communicate and
                    schedule meetings, and the Company/the Platform does not own
                    any of the intellectual property relating to the independent
                    content displayed on the Platform, apart from originally
                    created graphics and specified content The User is further
                    aware that any reproduction or infringement of the
                    intellectual property of the aforementioned owners by the
                    User will result in legal action being initiated against the
                    User by the respective owners of the intellectual property
                    so reproduced/infringed upon. It is agreed to by the Parties
                    that the contents of this Section shall survive even after
                    the termination or expiry of the Terms and/or Policy.
                  </p>

                  <br />
                  <h4 class="terms-heading">
                    15. DISCLAIMER OF WARRANTIES AND LIABILITIES
                  </h4>

                  <ol type="a">
                    <li>
                      The User agrees and undertakes that they are accessing the
                      Platform at their sole risk and are that they are using
                      their best and prudent judgment before availing any
                      service listed on the Platform or accessing/using any
                      information displayed thereon.
                    </li>
                    <li>
                      The User agrees that any kind of information, resources,
                      activities, recommendations obtained/availed from
                      Platform, written or oral, will not create any warranty
                      and the Platform disclaims all liabilities resulting from
                      these.
                    </li>
                    <li>
                      The Company/Platform does not guarantee that the Services
                      contained in the Platform will be uninterrupted or
                      error-free, or that the Platform or its server will be
                      free of viruses or other harmful components, and the User
                      hereby expressly accepts any and all associated risks
                      involved with the User’s use of the Platform.
                    </li>
                    <li>
                      It is further agreed to by the Parties that the contents
                      of this Section shall survive even after the termination
                      or expiry of the Terms and/or Policy
                    </li>
                  </ol>
                  <br />

                  <h4 class="terms-heading">FORCE MAJEURE</h4>
                  <p>
                    Neither the Company nor the Platform shall be liable for
                    damages for any delay or failure to perform its obligations
                    hereunder if such delay or failure is due to cause beyond
                    its control or without its fault or negligence, due to Force
                    Majeure events including but not limited to acts of war,
                    acts of God, earthquake, riot, fire, festive activities
                    sabotage, labor shortage or dispute, internet interruption,
                    technical failure, break
                  </p>
                  <br />

                  <h4 class="terms-heading">
                    16. DISPUTE RESOLUTION AND JURISDICTION
                  </h4>
                  <p class="terms-para">
                    It is expressly agreed to by the Parties hereto that the
                    formation, interpretation and performance of these Terms and
                    any disputes arising therefrom will be resolved through a
                    two-step secondary Dispute Resolution
                    <b>(“ADR”)</b> mechanism. It is further agreed to by the
                    Parties that the contents of this Section shall survive even
                    after the termination or expiry of the Terms and/or Policy.
                  </p>

                  <p class="terms-para">
                    <b>Mediation: </b> case of any dispute between the parties,
                    the Parties will attempt to resolve the same amicably
                    amongst themselves, to the mutual satisfaction of all
                    Parties. In the event that the Parties are unable to reach
                    such an amicable solution within thirty (30) days of one
                    Party communicating the existence of a dispute to any other
                    Party, the dispute will be resolved by arbitration, as
                    detailed hereinbelow;
                  </p>

                  <p class="terms-para">
                    <b> Arbitration:</b> In the event that the Parties are
                    unable to amicably resolve a dispute by mediation, said
                    dispute will be referred to arbitration by a sole arbitrator
                    to be appointed by the Company, and the award passed by such
                    sole arbitrator will be valid and binding on all Parties.
                    The Parties shall bear their own costs for the proceedings,
                    although the sole arbitrator may, in his/her sole
                    discretion, direct either Party to bear the entire cost of
                    the proceedings. The arbitration shall be conducted in
                    English, and the seat of Arbitration shall be the city of
                    Hyderabad.
                  </p>

                  <p>
                    The Parties expressly agree that the Terms, Policy and any
                    other agreements entered into between the Parties are
                    governed by the laws, rules and regulations of India.
                  </p>

                  <h4 class="terms-heading">17.NOTICES</h4>

                  <p class="terms-para">
                    Any and all communication relating to any dispute or
                    grievance experienced by the User may be communicated to the
                    Company by the User by emailing to support@calvary.co
                  </p>

                  <br />

                  <h4 class="terms-heading">18. MISCELLANEOUS PROVISIONS</h4>

                  <p class="terms-para">
                    <b>a. Entire Agreement: </b>These Terms, read with the
                    Policy, form the complete and final contract between the
                    User and the Company with respect to the subject matter
                    hereof and supersedes all other communications,
                    representations and agreements (whether oral, written or
                    otherwise) relating thereto.
                  </p>

                  <p class="terms-para">
                    <b>b.Waiver:</b> The failure of either Party at any time to
                    require performance of any provision of these Terms shall in
                    no manner affect such Party's right at a later time to
                    enforce the same. No waiver by either Party of any breach of
                    these Terms, whether by conduct or otherwise, in any one or
                    more instances, shall be deemed to be or construed as a
                    further or continuing waiver of any such breach, or a waiver
                    of any other breach of these Terms.
                  </p>

                  <p class="terms-para">
                    <b>c.Severability:</b> If any provision/clause of these
                    Terms is held to be invalid, illegal or unenforceable by any
                    court or authority of competent jurisdiction, the validity,
                    legality and enforceability of the remaining
                    provisions/clauses of these Terms shall in no way be
                    affected or impaired thereby, and each such provision/clause
                    of these Terms shall be valid and enforceable to the fullest
                    extent permitted by law. In such case, these Terms shall be
                    reformed to the minimum extent necessary to correct any
                    invalidity, illegality or unenforceability, while preserving
                    to the maximum extent the original rights, intentions and
                    commercial expectations of the Parties hereto, as expressed
                    herein.
                  </p>

                  <p class="terms-para mb-5">
                    <b>d. Contact Us:</b> If you have any questions about this
                    Agreement, the practices of the Platform, or your experience
                    with the Service, you can contact us at support@calvary.co
                  </p>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
