import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { even } from '@rxweb/reactive-form-validators';
import { WhiteSpaceValidator } from 'src/app/_helpers/whitespace.validator';
import { DataService } from 'src/app/_services/data.service';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';
import { PrayerRequestSuccessDialogComponent } from './prayer-request-success-dialog/prayer-request-success-dialog.component';
import { MixpanelService } from 'src/app/_services/mixpanel.service';
declare let notAllzeros: any;

@Component({
  selector: 'app-prayer-request-details',
  templateUrl: './prayer-request-details.component.html',
  styleUrls: ['./prayer-request-details.component.css'],
})
export class PrayerRequestDetailsComponent implements OnInit {
  queryParamsDetails: any;
  titleName: any;
  prayerRequestForm: FormGroup;
  submitted: boolean = false;
  prayerid: any;
  prayerForWhom: any;
  zerosAll: any;
  public getScreenWidth: any;
  public getScreenHeight: any;
  errorMessage: any;
  prayerRequestObj:any;
  languages = [
    'Assamese',
    'Bangla',
    'Bodo',
    'Dogri',
    'Gujarati',
    'Hindi',
    'Kashmiri',
    'Kannada',
    'Konkani',
    ,
    'Malayalam',
    'Manipuri',
    ,
    'Nepali',
    'Oriya',
    'Punjabi',
    'Tamil',
    'Telugu',
    'English',
  ];
  inputModel: any;
  Languages = [
    { key: 'Telugu', value: 'Telugu' },
    { key: 'English', value: 'English' },
    { key: 'Hindi', value: 'Hindi' },
    { key: 'Tamil', value: 'Tamil' },
    { key: 'Kannada', value: 'Kannada' },
    { key: 'Marathi', value: 'Marathi' },
    { key: 'Bengali', value: 'Bengali' },
    { key: 'Oriya', value: 'Oriya' },
    { key: 'Urdu', value: 'Urdu' },
    { key: 'Gujarati', value: 'Gujarati' },
    { key: 'Assamese', value: 'Assamese' },
    { key: 'Punjabi', value: 'Punjabi' },
    { key: 'Malayalam', value: 'Malayalam' },
    { key: 'Bhojpuri', value: 'Bhojpuri' },
    { key: 'Sinhalese', value: 'Sinhalese' },
    { key: 'Nepali', value: 'Nepali' },
    { key: 'Konkani', value: 'Konkani' },
  ];
  issue = [
    { key: 'Website', value: 'Website' },
    { key: 'Andriod', value: 'Andriod' },
    { key: 'iOS', value: 'iOS' },
    { key: 'General', value: 'General' },
  ];
  typeOfBug: any;
  errorMessageTextArea: any;
  errorTextArea: any;
  errorLength: boolean = false;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private Service: PrayerRequestService,
    private formBuilder: FormBuilder,
    private mixpanelService: MixpanelService
  ) {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.prayerid = this.queryParamsDetails.params._id;
      this.titleName = this.queryParamsDetails.params.title;
    });

    this.prayerRequestForm = this.formBuilder.group({
      prayerId: [this.prayerid],
      userId: [''],
      phone: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      name: ['', [Validators.required]],
      place: ['', [Validators.required]],
      description: ['', [Validators.required]],
      prayerForWhomId: ['', [Validators.required]],
      language: ['', [Validators.required]],
      file: [''],
      origin: ['Website', [Validators.required]],
      email: [
        '',
        [
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    // console.log(this.getScreenWidth, this.getScreenHeight);
  }
  ngOnInit(): void {
    this.trackEvent()
    notAllzeros();
    

    this.Service.prayerForWhom().subscribe((res) => {
      console.log(res.data);
      this.prayerForWhom = res.data;
    });
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }

  trackEvent() {
    this.mixpanelService.track('Prayer Request User Details', { 'Prayer Name': this.titleName });
  }
  typeOfBugIssue(event: any) {
    console.log(event.value);
    this.typeOfBug = event.value;
  }
  submit() {
    // if (this.getScreenWidth >= 480) {
    //   this.dialog.open(PrayerRequestSuccessDialogComponent, {
    //     data: this.prayerid,
    //     disableClose: true,
    //   });
    // } else {
    //   this.router.navigate(['/prayer-request/success'], {
    //     queryParams: { _id: this.prayerid },
    //   });
    // }
    console.log(this.prayerRequestForm);
    if (
      this.prayerRequestForm.invalid ||
      this.prayerRequestForm.value.phone === '0000000000' ||
      this.prayerRequestForm.value.phone === '0000000' ||
      this.prayerRequestForm.value.phone === '00000' ||
      this.prayerRequestForm.value.phone === '00000000'
    ) {
      this.submitted = true;
      if (
        this.prayerRequestForm.value.phone === '0000000000' ||
        this.prayerRequestForm.value.phone === '0000000' ||
        this.prayerRequestForm.value.phone === '00000' ||
        this.prayerRequestForm.value.phone === '00000000'
      ) {
        this.zerosAll = '';
      }
    } else {
      this.submitted = false;
      console.log(this.prayerRequestForm.value);
      this.prayerRequestFormDetails();
    }
  }

  prayerRequestFormDetails() {
    this.prayerRequestObj = {
      prayerId: this.queryParamsDetails.params._id,
      userId: '',
      phone: this.prayerRequestForm.value.phone,
      name: this.prayerRequestForm.value.name.trim(),
      place: this.prayerRequestForm.value.place,
      description: this.prayerRequestForm.value.description.trim(),
      prayerForWhomId: this.prayerRequestForm.value.prayerForWhomId,
      language: this.prayerRequestForm.value.language,
      file: '',
      email: this.prayerRequestForm.value.email,
      origin: this.prayerRequestForm.value.origin,
    };
    // console.log(prayerRequestObj);
    this.Service.prayerRequestDetails(this.prayerRequestObj).subscribe(
      (res) => {
        this.prayerRequestForm.get('email').reset();
        this.prayerRequestForm.get('phone').reset();
        this.prayerRequestForm.get('language').reset();
        this.prayerRequestForm.get('prayerForWhomId').reset();
        this.prayerRequestForm.get('description').reset();
        this.prayerRequestForm.get('place').reset();
        this.prayerRequestForm.get('name').reset();
        // console.log(res);      
          this.mixpanelService.track('Prayer Request Submit', { /* optional event properties */ });
        if (this.getScreenWidth >= 480) {
          this.prayerRequestObj.titleN = this.titleName; 
          this.dialog.open(PrayerRequestSuccessDialogComponent, {
            data: this.prayerRequestObj,
            disableClose: true,
          });
        } else {
          this.router.navigate(['/submit-a-prayer-request/success'], {
            queryParams: { _id: this.prayerid },
          });
        }
      },
      (error) => {
        //Error callback
        this.errorMessage = error.name;

        console.log('error caught', error.name);
        throw error.name;
      }
    );
  }

  // return to prayer-request page
  back() {
    this.router.navigate(['/submit-a-prayer-request']);
  }

  // errors message controls
  get f(): { [key: string]: AbstractControl } {
    return this.prayerRequestForm.controls;
  }

  // validations for numberOnly
  // numberOnly(event: any): boolean {
  //   let patt = /^([0-9])$/;
  //   let result = patt.test(event.key);
  //   return result;
  // }

  // validations for AlphabetsOnly
  nameValidations(event: any) {
    let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
    console.log('alphabets', newValue);
    this.prayerRequestForm.controls['name'].setValue(newValue);
  }
  placeValidations(event: any) {
    let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
    // console.log('alphabets', newValue);
    this.prayerRequestForm.controls['place'].setValue(newValue);
  }
  prayerRequestValidation(event: any) {
    let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
    // console.log('alphabets', newValue);
    this.prayerRequestForm.controls['description'].setValue(newValue);
    console.log(event.target.value.length);

    let wordLength = event.target.value.split(' ');

    if (wordLength.length > 601) {
      this.errorLength = true;
      wordLength.length = 600;
      event.target.value = wordLength.join(' ');
    } else {
      this.errorLength = false;
    }

    // if (event.target.value.length === 3632) {
    //   console.log('error');
    //   this.errorTextArea = event.target.value.length;
    //   this.errorMessageTextArea =
    //     'Maximum word limit for the description is 600 words.';
    //   console.log(this.errorMessageTextArea);
    // }
    // if (event.target.value.length > 3622) {
    //   this.errorMessageTextArea = '';
    // }
  }

  numberOnly(event: any) {
    let newValue = event.target.value.replace(/[^0-9]/g, '');
    // console.log('alphabets', newValue);
    this.prayerRequestForm.controls['phone'].setValue(newValue);
  }
}
