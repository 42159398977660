import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.css'],
})
export class ShareDialogComponent implements OnInit {
  data: any;
  youtubelink: any;
  constructor(@Inject(MAT_DIALOG_DATA) data: any, private dialog: MatDialog) {
    this.data = data;
  }

  ngOnInit(): void {
    console.log(this.data.link);
    this.youtubelink = this.data.link.replace('embed/', 'watch?v=');
    console.log(this.youtubelink);
  }
  close() {
    this.dialog.closeAll();
  }
}
