<!-- =====================================
       ==== Start Loading -->
<!-- <div class="loading" id="scroll_top">
  <div class="gooey">
    <span class="dot"></span>
    <div class="dots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</div> -->
<!-- End Loading ====
       ======================================= -->
<!-- =====================================
       ==== Start Navbar -->
<!-- <app-navbar></app-navbar> -->
<!-- End Navbar ====
       ======================================= -->
<!-- End Header ====
       ======================================= -->
<!-- End Hero ====
       ======================================= -->
<!-- <section class="hero section-padding pb-0 bg-gray" id="driving" data-scroll-index="driving">
  <div class="bg-gray text-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="about mb-50">
            <h4>Privacy Policy</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="hero pb-0 section-padding padd90 section-space40" id="driving" data-scroll-index="driving">
  <div class=" ">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row mt-5 vertical-content">
            <div class="col-lg-12">
              <div class="terms-div">
                <p class="terms-para text-center"> WELCOME TO WWW.CALVARYTEMPLE.IN PRIVACY POLICY </p>
                <p class="terms-para"> The creator of this Platform ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across this Platform. This Privacy Policy contains information about the Website www.calvarytemple.in (hereinafter referred to as the “Platform”). </p>
                <p class="terms-para"> For the purpose of this Privacy Policy (“Privacy Policy”), wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the Foundation or the Platform. </p>
                <p> “You”, “Your”, “Yourself”, or “User” shall mean and refer to natural and legal individuals who shall be users of this Platform provided by us and who is competent to enter into binding contracts, as per law. </p>
                <p> “Third Parties'' refer to any Mobile Application, platform or legal entities apart from the Users and the Foundation. The Platform provides You with Our uninterrupted use of the platform. We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used. </p>
                <h4 class="terms-heading text-left mt-3">Index:</h4>
                <ol class="sub p-0 pl-3">
                  <li> Personal Information </li>
                  <li> Information We Collect </li>
                  <li> Our Use of Your Information </li>
                  <li> How Information Is Collected </li>
                  <li> External Links on the Platform </li>
                  <li> Cookies </li>
                  <li> Google & MixPanel Analytics </li>
                  <li> Google AdSense </li>
                  <li> Your Rights </li>
                  <li> Compliances </li>
                  <li> Confidentiality </li>
                  <li> Other Information Collectors </li>
                  <li> Our Disclosure of Your Information </li>
                  <li> Security </li>
                  <li> Severability </li>
                  <li> Amendment </li>
                  <li> Consent Withdrawal, Data Download & Data Removal Requests </li>
                  <li> Contact Us </li>
                </ol>
                <br />
                <br />
                <h3 class="terms-heading"> INFORMATION WE COLLECT</h3>
                <p class="term-para">We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:</p>
                <ol type="a">
                  <li> Personal data includes but is not limited to Phone Number, Email id, DOB, name, address, location, payment information, etc. </li>
                  <li> Information is collected through permission derived by the Platform for internet connection, equipment used to access services, location access and usage details. </li>
                  <li> Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from, which URL you next go to, your computer or device browser information, and other information (connection, speed, connection type etc.), device type, device's unique device identifier, mobile network information and the device's telephone number associated with your interaction with the Platform. </li>
                  <li> Details of Platform usage for analytics. </li>
                  <li> Bank information, for payment. </li>
                  <li> Search History on the Platform. </li>
                </ol>
                <p class="terms-para"> This privacy policy also collects information automatically, about the computer or devices (including mobile devices) you use to access the Services, and about your use of the Services, even if you use the Services without logging and also certain information from users who are not registered as members of this Platform, including, but not limited to: </p>
                <ol type="a">
                  <li> Usage information: Details of your use of our Services, including traffic data, location data, logs and other communication data and the resources that you access and use on or through our Services.</li>
                  <li>Stored information and files: Our applications also may access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts, reviews, comments, current and prior material ordering details and history, favorite Stores, special store requests, contact information of people you add to, or notify of and other information in your account profile and address book information.</li>
                  <li>Mobile device IDs: Unique mobile device identifier (e.g. IDFA or other device IDs on Apple devices like the iPhone and iPad), if you're using our Services on a mobile device, we may use mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize you. We may do this to store your preferences and track your use of our applications. Unlike cookies, mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your use of our applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to you. Analytics companies may use mobile device IDs to track your usage of our applications.</li>
                  <li>Your preferences: Your preferences and settings such as time zone and language.</li>
                  <li>Your activity on the Services: Information about your activity on the Services, such as your search queries, comments, domain names, search results selected, number of clicks, pages viewed and the order of those pages, how long you visited our Services, the date and time you used the Services, error logs, and other similar information.</li>
                  <li>Your transactional information: If you make purchases of services through our Platform, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information and credit or payment card information. This information may be shared with third-parties which assist in processing and fulfilling your requests, including PCI compliant payment gateway processors. When you submit credit or payment card information, we encrypt the information using industry standard technologies. If you write reviews about businesses with which you conduct transactions through our Services, we may publicly display information that you transacted with those businesses. </li>
                </ol>
                <p class="term-para"> We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including: </p>
                <ol>
                  <li> To enable the provision of services opted for by you </li>
                  <li> To enable the viewing of content in your interest </li>
                  <li> To communicate the necessary account and service-related information from time to time </li>
                  <li> To allow you to receive quality customer care services and data Collection </li>
                  <li> To comply with applicable laws, rules and regulations </li>
                </ol>
                <br />
                <p class="terms-para"> Where any service requested by You involves a third party, such information as is reasonably necessary by the Foundation to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Foundation may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the ‘unsubscribe’ button or through an email to be sent to “____________”. </p>
                <br />
                <p class="terms-para"> To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the Platform. Further, transacting over the internet has inherent risks which can only be avoided by following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised. </p>
                <br />
                <h3 class="terms-heading">OUR USE OF YOUR INFORMATION</h3>
                <p class="terms-para"> The information provided by you shall be used to provide and improve the service for you and all users. </p>
                <ol type="a">
                  <li>To provide you with services on your request..</li>
                  <li>For maintaining an internal record.</li>
                  <li> For enhancing the Services provided </li>
                  <li> For maintaining record under the legal and statutory provisions. </li>
                </ol>
                <p class="terms-para"> For more details about the nature of such communications, please refer to our Terms of Service. Further, your personal data and Sensitive Personal data may be collected and stored by Us for internal record. We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you. We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law. Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences. </p>
                <br />
                <h3 class="terms-heading">HOW INFORMATION IS COLLECTED</h3>
                <p class="terms-para"> Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Company to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever. </p>
                <p class="terms-para"> We will collect and use your personal information solely with the objective of fulfilling those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned. </p>
                <p class="terms-para"> Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date. </p>
                <br />
                <h3 class="terms-heading"> EXTERNAL LINKS ON THE PLATFORM </h3>
                <p class="terms-para"> The Platform may include advertisements, hyperlinks to other Platforms. We have no control over any other Platforms or resources or contents available on these other Platforms, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services or other materials on or available from such Platforms or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Platforms. These external third-party Platforms and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the Platform and review its Privacy Policy. </p>
                <br />
                <h3 class="terms-heading"> COOKIES </h3> 
                <p> A Cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. By continuing to browse the site, you are agreeing to our use of cookies. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser cookies, or to alert you when the Platform sets or accesses cookies. If you disable or refuse cookies, please note that some parts of this Platform may become inaccessible or not function properly. A list of the type of cookies we use is as follows; </p>
                <ol class="type=a">
                  <li> Strictly necessary cookies. These are cookies that are required for the operation of our Platform. They include, for example, cookies that enable you to log into secure areas of our Platform, use a shopping cart or make use of e-billing services. </li>
                  <li> Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our Platform when they are using it. This helps us to improve the way our Platform works, for example, by ensuring that users are finding what they are looking for easily. </li>
                  <li> Functionality cookies. These are used to recognize you when you return to our Platform. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region). </li>
                  <li> Targeting cookies. These cookies record your visit to our Platform, the pages you have visited and the links you have followed. We will use this information to make our Platform and the advertising displayed on it more relevant to your interests. We may also share this information with third-parties for this purpose. </li>
                </ol>
                <p class="terms-para"> Please note that third-parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Platform. </p>
                <br />
                <h3 class="terms-heading"> GOOGLE & MIXPANEL ANALYTICS </h3>
                <p class="terms-para"> We are using the Google & MixPanel Analytics platform to help us to understand how you make use of our content and work out how we can make things better. These cookies follow your progress through us, collecting anonymous data on where you have come from, which pages you visit, and how long you spend on the site. This data is then stored by Google & MixPanel Analytics to create reports. These cookies do not store your personal data. </p>
                <p class="terms-para"> The information generated by the cookie about your use of the Website, including your IP address, may be transmitted to and stored by Google on their servers. Google & MixPanel Analytics may use this information for the purpose of evaluating your use of the website, compiling reports on website activity for us and providing other services relating to website activity and internet usage. Google & MixPanel Analytics may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's and MixPanel’s behalf. Google & MixPanel Analytics will not associate your IP address with any other data held by Google & MixPanel Analytics. By using this website, you consent to the processing of data about you by Google & MixPanel Analytics in the manner and for the purposes set out above. </p>
                <p class="terms-para"> The Google and Mixpanel websites contain further information about its analytics and a copy of Google's and MixPanel’s privacy policy pages. </p>
                <br />
                <h3 class="terms-heading"> GOOGLE ADSENSE </h3>
                <p class="term-para"> Google AdSense is a tool that allows the Platforms publishers to deliver advertisements to site visitors in exchange for revenue calculated on a per-click or per-impression basis. To do this, Google uses cookies and tracking technology to deliver ads personalized to a Platform user/visitor. In this regard the following terms are specified to the Users: </p>
                <ol>
                  <li> Third-party vendors, including Google, use cookies to serve ads based on your prior visits to our Platform or other Platforms. </li>
                  <li> Google's use of advertising cookies enables us and our partners to serve advertisements to you based on their visit to our Platform and/or other Platforms on the Internet. </li>
                  <li> You may opt-out of personalized advertising by visiting Ads Settings. </li>
                  <li> All advertisements of third parties on our Platform are for informative purposes only and neither the Platform nor the Foundation guarantees or bears liability for the authenticity of the advertisements. </li>
                  <li> At no point will the Foundation permit its competitors to advertise on the Platform. </li>
                  <li> You may visit the links in the advertisements at your own risk or choose to not accept the cookies permitting third parties to display their advertisements. </li>
                </ol>
                <br />
                <h3 class="terms-heading"> YOUR RIGHTS </h3>
                <p class="term-para"> Unless subject to an exemption, you have the following rights concerning your data: </p>
                <ol>
                  <li> The right to request a copy of your data which we hold about you. </li>
                  <li> The right to request for any correction to any personal data if it is found to be inaccurate or out of date </li>
                  <li> The right to withdraw Your consent to the processing at any time. </li>
                  <li> The right to object to the processing of personal data.</li>
                  <li> The right to complain about a supervisory authority. </li>
                  <li> The right to obtain information as to whether personal data are transferred to a third country or an international organization. </li>
                </ol>
                <p class="term-para"> Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in. </p>
                <br />
                <h3 class="terms-heading"> COMPLIANCES </h3>
                <p class="terms-heading text-left">a. Indian Compliances</p>
                <ol type="i">
                  <li> This legal agreement is an electronic record in terms of the Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures. </li>
                  <li> This legal document is published in accordance with the provisions of Rule 3 (1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through Information Technology Amendment Act, 2008 that require publishing the Terms of Use and practices for access and usage of any functional Website or Mobile Application. </li>
                </ol>
                <p class="terms-heading text-left">b.GDPR Privacy Rights</p>
                <p class="terms-para"> The General Data Protection Regulation or GDPR gives certain rights to individuals in relation to their personal data. Accordingly, we are happy to offer transparency and access controls to help Users take advantage of those rights. As available, except as limited under applicable law, the rights afforded to individuals are: </p>
                <ol type="i">
                  <li> Right of access- the right to be informed of, and request access to, the data we process about you, </li>
                  <li> Right to rectification – the right to request that we amend or update your data where it is inaccurate or incomplete, </li>
                  <li> Right to erasure- the right to request that we delete your data, </li>
                  <li> Right to restrict – the right to request that we temporarily or permanently stop processing all or some of your personal data, </li>
                  <li> Right to object – the right to object to us processing your personal data on grounds relating to your particular situation. Along with the right to object to your personal data being processed for direct marketing purposes, </li>
                  <li> Right to data portability – the right to request a copy of your personal data in electronic format and the right to transmit that personal data for use in another part’s service, </li>
                  <li> Right not to be subject to automated decision-making, the right to not be subject to a decision based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect. </li>
                </ol>
                <br />
                <h3 class="terms-heading">CONFIDENTIALITY</h3>
                <p class="terms-para"> You further acknowledge that the Platform may contain information that is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your email address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time. </p>
                <br />
                <h3 class="terms-heading"> OTHER INFORMATION COLLECTORS </h3>
                <p class="terms-para"> Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of such third parties, you are subject to ask questions before you disclose your personal information to others. </p>
                <br />
                <h3 class="terms-heading">OUR DISCLOSURE OF YOUR INFORMATION</h3>
                <p class="terms-para"> We may host surveys for survey creators for our Platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses. </p>
                <p class="terms-para"> Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force. </p>
                <p class="terms-para"> Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your registered email address. </p>
                <p class="terms-para"> As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed: </p>
                <ol type="a">
                  <li>External Service Providers: There may be several services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy. </li>
                  <li>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability. </li>
                </ol>
                <br />
                <h3 class="terms-heading"> SECURITY </h3>
                <p class="terms-para"> We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Foundation. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed. </p>
                <p class="terms-para"> However, as effective as encryption technology is, no security system is impenetrable. Our Foundation cannot guarantee the security of our database, nor can we guarantee that information you provide won’t be intercepted while being transmitted to the Foundation over the Internet. </p>
                <br />
                <h3 class="terms-heading"> SEVERABILITY </h3>
                <p class="terms-para"> Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy. </p>
                <br />
                <h3 class="terms-heading"> AMENDMENT </h3>
                <p class="terms-para mb-5"> Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform. </p>
                <h3 class="terms-heading"> CONSENT WITHDRAWAL, DATA DOWNLOAD & DATA REMOVAL REQUESTS </h3>
                <p class="terms-para mb-5"> To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to developer@calvarytemple.in</p>
                <h3 class="terms-heading"> CONTACT US </h3>
                <p class="terms-para mb-5"> If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to developer@calvarytemple.in or by writing to us at:developer@calvarytemple.in</p>
                <p class="terms-para text-center mt-3">M/s CALVARY TEMPLE FOUNDATION </p>
                <p class="terms-para text-center mb-5"> Calvary Temple Road, Hyder Nagar, Janapriya Nagar, Hafeezpet, Hyderabad, Telangana 500049. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</section> -->


<app-navbar></app-navbar>
<div class="container mt-4">
  <p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><u><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>PRIVACY POLICY</span></u></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>WELCOME TO&nbsp;</span></strong><a routerLink="/Home"><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>WWW.</span></strong><span style="color:blue;text-decoration:underline;"><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>CALVARYTEMPLE.IN&nbsp;</span></strong></span><span style="color:blue;text-decoration:underline;"><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></span></a><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>PRIVACY POLICY</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The creator of this Platform ensures a steady commitment to Your privacy with regard to the protection of your invaluable information that you may share across this Platform. This Privacy Policy contains information about the Website&nbsp;</span><a routerLink="/Home"><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>www.calvarytemple.in</span></a><span style="color:blue;text-decoration:underline;"><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;(hereinafter referred to as the &ldquo;<strong>Platform&rdquo;).</strong></span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>For the purpose of this Privacy Policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;), wherever the context so requires, &ldquo;We&rdquo;, &ldquo;Our&rdquo;, and &ldquo;Us&rdquo; shall mean and refer to the Foundation or the Platform.&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&ldquo;You&rdquo;, &ldquo;Your&rdquo;, &ldquo;Yourself&rdquo;, or &ldquo;User&rdquo; shall mean and refer to natural and legal individuals who shall be users of this Platform provided by us and who is competent to enter into binding contracts, as per law.&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&ldquo;Third Parties&apos;&apos; refer to any Mobile Application, platform or legal entities apart from the Users and the Foundation. The Platform provides You with Our uninterrupted use of the platform. We may collect and, in some circumstances, disclose information about you with your permission. To ensure better protection of Your privacy, We provide this notice explaining Our information collection and disclosure policies, and the choices You make about the way Your information is collected and used. &nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Index:</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Personal Information</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Information We Collect</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Our Use of Your Information</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; How Information Is Collected</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; External Links on the Platform</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Cookies</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Google &amp; MixPanel Analytics</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Google AdSense</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Your Rights</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Compliances</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Confidentiality</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Other Information Collectors</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Our Disclosure of Your Information</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Security</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Severability</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Amendment</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Consent Withdrawal, Data Download &amp; Data Removal Requests</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&bull; Contact Us</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>INFORMATION WE COLLECT</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We are committed to respecting Your online privacy. We further recognize Your need for appropriate protection and management of any Personal Information You share with us. We may collect the following information:</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Personal data includes but is not limited to Phone Number, Email id, DOB, name, address, location, payment information, etc.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Information is collected through permission derived by the Platform for internet connection, equipment used to access services, location access and usage details.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from, which URL you next go to, your computer or device browser information, and other information (connection, speed, connection type etc.), device type, device&apos;s unique device identifier, mobile network information and the device&apos;s telephone number associated with your interaction with the Platform.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Details of Platform usage for analytics.</span></li>
</ol>
<ol start="5" style="list-style-type: lower-alpha;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Bank information, for payment.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Search History on the Platform.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>This privacy policy also collects</span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;information&nbsp;</span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>automatically, about the computer or devices (including mobile devices) you use to access the Services, and about your use of the Services, even if you use the Services without logging and also certain information&nbsp;</span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>from users who are not registered as members of this Platform, including, but not limited to:</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Usage information: Details of your use of our Services, including traffic data, location data, logs and other communication data and the resources that you access and use on or through our Services.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Stored information and files: Our applications also may access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts, reviews, comments, current and prior material ordering details and history, favorite Stores, special store requests, contact information of people you add to, or notify of and other information in your account profile and address book information.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Mobile device IDs: Unique mobile device identifier (e.g. IDFA or other device IDs on Apple devices like the iPhone and iPad), if you&apos;re using our Services on a mobile device, we may use mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize you. We may do this to store your preferences and track your use of our applications. Unlike cookies, mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your use of our applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to you. Analytics companies may use mobile device IDs to track your usage of our applications.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your preferences: Your preferences and settings such as time zone and language.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your activity on the Services: Information about your activity on the Services, such as your search queries, comments, domain names, search results selected, number of clicks, pages viewed and the order of those pages, how long you visited our Services, the date and time you used the Services, error logs, and other similar information.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your transactional information: If you make purchases of services through our Platform, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information and credit or payment card information. This information may be shared with third-parties which assist in processing and fulfilling your requests, including PCI compliant payment gateway processors. When you submit credit or payment card information, we encrypt the information using industry standard technologies. If you write reviews about businesses with which you conduct transactions through our Services, we may publicly display information that you transacted with those businesses.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To enable the provision of services opted for by you;</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To enable the viewing of content in your interest;</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To communicate the necessary account and service-related information from time to time;</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To allow you to receive quality customer care services and data Collection;</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To comply with applicable laws, rules and regulations;</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Where any service requested by You involves a third party, such information as is reasonably necessary by the Foundation to carry out Your service request may be shared with such third party. We also do use your contact information to send you offers based on your interests and prior activity and also to view the content preferred by you. The Foundation may also use contact information internally to direct its efforts for service improvement but shall immediately delete all such information upon withdrawal of your consent for the same through the <strong>&lsquo;unsubscribe&rsquo;</strong> button or through an email to be sent to <span style="background:yellow;">&ldquo;developer@calvarytemple.in&rdquo;.</span></span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the Platform and opt-out of any non-essential communications from the Platform.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Further, transacting over the internet has inherent risks which can only be avoided by following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>OUR USE OF YOUR INFORMATION</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The information provided by you shall be used to provide and improve the service for you and all users.</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To provide you with services on your request.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>For maintaining an internal record.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>For enhancing the Services provided.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>For maintaining record under the legal and statutory provisions.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>For more details about the nature of such communications, please refer to our Terms of Service. Further, your data and Sensitive Personal data may be collected and stored by Us for internal record.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We will not sell, license or trade Your personal information. We will not share your personal information with others unless they are acting under our instructions or we are required to do so by law.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Information collected via Our server logs includes users&apos; IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party analytics, tracking, optimization and targeting tools to understand how users engage with our Platform so that we can improve it and cater personalized content/ads according to their preferences.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>HOW INFORMATION IS COLLECTED</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Foundation to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We will collect and use your personal information solely to fulfil those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>EXTERNAL LINKS ON THE PLATFORM</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The Platform may include advertisements, hyperlinks to other Platforms. We have no control over any other Platforms or resources or contents available on these other Platforms, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services/services or other materials on or available from such Platforms or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Platforms. These external third-party Platforms and resource providers may have their privacy policies governing the collection, storage, retention and disclosure of Your Personal Information that You may be subject to. We recommend that you enter the Platform and review its Privacy Policy.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>COOKIES</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>A Cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. By continuing to browse the site, you are agreeing to our use of cookies. Cookies contain information that is transferred to your computer&rsquo;s hard drive. You can set your browser to refuse all or some browser cookies, or to alert you when the Platform sets or accesses cookies. If you disable or refuse cookies, please note that some parts of this Platform may become inaccessible or not function properly. A list of the type of cookies we use is as follows;</span></p>
<ol style="list-style-type: lower-alpha;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Strictly necessary cookies. These are cookies that are required for the operation of our Platform. They include, for example, cookies that enable you to log into secure areas of our Platform, use a shopping cart or make use of e-billing services.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Analytical/performance cookies. They allow us to recognize and count the number of visitors and to see how visitors move around our Platform when they are using it. This helps us to improve the way our Platform works, for example, by ensuring that users are finding what they are looking for easily.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Functionality cookies. These are used to recognize you when you return to our Platform. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Targeting cookies. These cookies record your visit to our Platform, the pages you have visited and the links you have followed. We will use this information to make our Platform and the advertising displayed on it more relevant to your interests. We may also share this information with third-parties for this purpose.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Please note that third-parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Platform.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>GOOGLE &amp; MIXPANEL ANALYTICS</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We are using the Google &amp; MixPanel Analytics platform to help us to understand how you make use of our content and work out how we can make things better. These cookies follow your progress through us, collecting anonymous data on where you have come from, which pages you visit, and how long you spend on the site. This data is then stored by Google &amp; MixPanel Analytics to create reports. These cookies do not store your personal data.&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The information generated by the cookie about your use of the Website, including your IP address, may be transmitted to and stored by Google on their servers. Google &amp; MixPanel Analytics may use this information for the purpose of evaluating your use of the website, compiling reports on website activity for us and providing other services relating to website activity and internet usage. Google &amp; MixPanel Analytics may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google&apos;s and MixPanel&rsquo;s behalf. Google &amp; MixPanel Analytics will not associate your IP address with any other data held by Google &amp; MixPanel Analytics. By using this website, you consent to the processing of data about you by Google &amp; MixPanel Analytics in the manner and for the purposes set out above.&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The Google and Mixpanel websites contain further information about its analytics and a copy of Google&apos;s and MixPanel&rsquo;s privacy policy pages.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>GOOGLE ADSENSE</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Google AdSense is a tool that allows the Platforms publishers to deliver advertisements to site visitors in exchange for revenue calculated on a per-click or per-impression basis. To do this, Google <strong>uses cookies and tracking technology</strong> to deliver ads personalized to a Platform user/visitor. In this regard the following terms are specified to the Users:</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Third-party vendors, including Google, use cookies to serve ads based on your prior visits to our Platform or other Platforms.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Google&apos;s use of advertising cookies enables us and our partners to serve advertisements to you based on their visit to our Platform and/or other Platforms on the Internet.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You may opt-out of personalized advertising by visiting Ads Settings.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>All advertisements of third parties on our Platform are for informative purposes only and neither the Platform nor the Foundation guarantees or bears liability for the authenticity of the advertisements.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>At no point will the Foundation permit its competitors to advertise on the Platform.</span></li>
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You may visit the links in the advertisements at your own risk or choose to not accept the cookies permitting third parties to display their advertisements.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>YOUR RIGHTS</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Unless subject to an exemption, you have the following rights concerning your data:</span></p>
<ol start="1" style="list-style-type: lower-alpha;margin-left:25.450000000000003px;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The right to request a copy of your data which we hold about you;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The right to request for any correction to any personal data if it is found to be inaccurate or out of date;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The right to withdraw Your consent to the processing at any time.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The right to object to the processing of personal data.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The right to complain about a supervisory authority.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>The right to obtain information as to whether personal data are transferred to a third country or an international organization.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>COMPLIANCES</span></strong></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Indian Compliances</span></strong></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>i. This legal agreement is an electronic record in terms of the Indian <strong>Information Technology Act, 2000</strong> and rules there under as applicable and the amended provisions about electronic records in various statutes as amended by the <strong>Indian Information Technology Act, 2000</strong>. This electronic record is generated by a computer system and does not require any physical or digital signatures.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>ii. This legal document is published in accordance with the provisions of <strong>Rule 3 (1)</strong> of the <strong>Indian Information Technology (Intermediaries guidelines) Rules, 2011</strong> and <strong>Rule 4</strong> of the <strong>Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011</strong> of <strong>Information Technology Act, 2000</strong> amended through <strong>Information Technology Amendment Act, 2008&nbsp;</strong>that require publishing the Terms of Use and practices for access and usage of any functional Website or Mobile Application.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol start="2" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>GDPR Privacy Rights</span></strong></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The General Data Protection Regulation or GDPR gives certain rights to individuals in relation to their personal data. Accordingly, we are happy to offer transparency and access controls to help Users take advantage of those rights. As available, except as limited under applicable law, the rights afforded to individuals are:</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-size:16px;'>Right of access- the right to be informed of, and request access to, the data we process about you,</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-size:16px;'>Right to rectification &ndash; the right to request that we amend or update your data where it is inaccurate or incomplete,</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-size:16px;'>Right to erasure- the right to request that we delete your data,</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-size:16px;'>Right to restrict &ndash; the right to request that we temporarily or permanently stop processing all or some of your personal data,</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-size:16px;'>Right to object &ndash; the right to object to us processing your personal data on grounds relating to your particular situation. Along with the right to object to your personal data being processed for direct marketing purposes,</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-size:16px;'>Right to data portability &ndash; the right to request a copy of your personal data in electronic format and the right to transmit that personal data for use in another part&rsquo;s service,</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-size:16px;'>Right not to be subject to automated decision-making, the right to not be subject to a decision based solely on automated decision making, including profiling, where the decision would have a legal effect on you or produce a similarly significant effect.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>CONFIDENTIALITY</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;margin-top:12.0pt;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You further acknowledge that the Platform may contain information that is designated confidential by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third party, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your email address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>OTHER INFORMATION COLLECTORS</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your information to other parties, whether they are on our Platform or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of such third parties, you are subject to ask questions before you disclose your personal information to others.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>OUR DISCLOSURE OF YOUR INFORMATION</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We may host surveys for survey creators for our Platform who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the <strong><em>Right to Information Act, 2005,&nbsp;</em></strong>any rules made thereunder or any other law for the time being in force.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Due to the existing regulatory environment, we cannot ensure that all of your private communications and other personally identifiable information will never be disclosed in ways not otherwise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third parties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your registered email address.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifiable information may be disclosed:</span></p>
<ol start="1" style="list-style-type: lower-alpha;margin-left:26px;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>External Service Providers: There may be several services offered by external service providers that help you use our Platform. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their privacy policy.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Law and Order: We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other government officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is illegal or may expose us or you to legal liability.</span></li>
</ol>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>SECURITY</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Foundation. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>However, as effective as encryption technology is, no security system is impenetrable. Our Foundation cannot guarantee the security of our database, nor can we guarantee that information you provide won&rsquo;t be intercepted while being transmitted to the Foundation over the Internet.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>SEVERABILITY</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>AMENDMENT</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Our Privacy Policy may change or Amend from time to time without any prior intimation or information. The most current version of the policy will govern our use of your information and will always be on the Platform. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Platform.</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>JURISDICTION</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>All matter subject to the territorial Jurisdiction of Ranga Reddy District Courts, Telangana, India</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA REMOVAL REQUESTS</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to <span style="background:yellow;">&ldquo;developer@calvarytemple.in&rdquo;.</span></span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>CONTACT US</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>If you have any questions or concerns regarding this privacy policy, you should contact us by sending an e-mail to <span style="background:yellow;">&ldquo;developer@calvarytemple.in&rdquo;</span> or by writing to us at:</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;border:none;'>M/s CALVARY TEMPLE FOUNDATION</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;border:none;'>Calvary Temple Road, Hyder Nagar, Janapriya Nagar, Hafeezpet, Hyderabad, Telangana 500049.</span></strong></p>
<p style='margin:0cm;line-height:150%;font-size:15px;font-family:"Arial",sans-serif;text-align:center;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
</div>

<app-footer></app-footer>