import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { PrayerRequestService } from '../_services/prayer-request.service';
import { environment } from '../../environments/environment';
import { MixpanelService } from '../_services/mixpanel.service';
@Component({
  selector: 'app-prayersrequest',
  templateUrl: './prayersrequest.component.html',
  styleUrls: ['./prayersrequest.component.css'],
})
export class PrayersrequestComponent implements OnInit {
  prayerRequestDetails: any;
  prayerRequestDetailsImage: any;
  url: any = `${environment.API_URL}image`;
  data: any;
  imagesData: any;
  constructor(
    private route: Router,
    private router: ActivatedRoute,
    private Service: PrayerRequestService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.trackEvent()

    this.Service.prayerRequest().subscribe((response) => {
      console.log(response.data);

      this.data = response.data;
      this.prayerRequestDetails = response.data;
      this.prayerRequestDetailsImage = response.data[0].image;
      console.log(this.prayerRequestDetailsImage);
    });
  }
trackEvent() {
    this.mixpanelService.track('Prayer Request Screen', { /* optional event properties */ });
  }
  prayRequest() {
    this.route.navigate(['details/'], { relativeTo: this.router });
  }
}
