import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { PrayerRequestService } from '../_services/prayer-request.service';
import { MixpanelService } from '../_services/mixpanel.service';

@Component({
  selector: 'app-watch',
  templateUrl: './watch.component.html',
  styleUrls: ['./watch.component.css'],
})
export class WatchComponent implements OnInit {
  WatchVideos: any;
  TopicList: any;
  speakerName: any = 'Dr. P. Satish Kumar';
  fliteringData: any = [];
  dropDownSelection: any;
  DatePickerData: any;
  myplaceHolder: any = 'Select';
  queryParams: any;
  Date: any;
  TopicNames: any = '';
  calendraDate: any = '';
  Speaker: any = 'Dr. P. Satish Kumar';
  videosLInk: any;
  youtubeVideoLink: any;
  youtubeVideoLinks: any = [];
  today = new Date();
  model: any = {};
  WatchGroup: FormGroup;
  resetBtn = false;
  noYoutubeVideo: any;

  // @Input() max: D | null;
  constructor(
    private Service: PrayerRequestService,
    private formBuilder: FormBuilder,
    private mixpanelService: MixpanelService
  ) {
    this.WatchGroup = this.formBuilder.group({
      select: 0,
    });
  }

  ngOnInit(): void {
    this.trackEvent()
    this.Service.WatchVideoRequest().subscribe((res) => {
      console.log(res);
      if (res.data != null) {
        this.WatchVideos = res.data;
        this.fliteringData = res.data;
        this.noYoutubeVideo = res.data;
      } else {
        this.WatchVideos = '';
        this.fliteringData = '';
      }

      console.log(this.WatchVideos[0].youtubeVideo.substring(32));
      // this.videosLInk = this.WatchVideos.forEach((item: any) => {
      // console.log(item.youtubeVideo);
      //   this.youtubeVideoLink = item.youtubeVideo.replace('watch?v=', 'embed/');
      //   console.log(this.youtubeVideoLink);
      //   this.youtubeVideoLinks.push(this.youtubeVideoLink);
      //   console.log(this.youtubeVideoLinks);
      // });
    });
    // this.Service.WatchSpeakerName().subscribe((res) => {
    //   this.speakerName = res.data;
    // });
    this.Service.WatchTopicList().subscribe((res) => {
      this.TopicList = res.data;
    });
  }
  trackEvent() {
    this.mixpanelService.track('This Week Video List Screen', { /* optional event properties */ });
  }
  // watch-fliter-slick-slider-configuration
  beforeRedirect(v:any){
    this.mixpanelService.track('This Week Video Play List', { "Video Name": v });
  }
  slideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow:
      "<img class='a-left control-c prev slick-prev' src='../../assets/images/left-1.svg'>",
    nextArrow:
      "<img class='a-right control-c next slick-next' src='../../assets/images/w-v-1.svg'>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  // watch-slick-slider-configuration
  WatchSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow:
      "<img class='a-left control-c prev slick-prev-1' src='../../assets/images/left-1.svg'>",
    nextArrow:
      "<img class='a-right control-c next slick-next-1 mt-1' src='../../assets/images/w-v-1.svg'>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  onChange(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);

    if (this.dropDownSelection == '') {
      this.Speaker = '';
    } else {
      this.Speaker = this.dropDownSelection;
      this.resetBtn = true;
      this.queryParams = {
        speaker: this.Speaker ? this.Speaker : '',
        topic: this.TopicNames ? this.TopicNames : '',
        date: this.calendraDate ? this.calendraDate : '',
      };
      this.Service.WatchVideoRequestFliter(this.queryParams).subscribe(
        (response) => {
          console.log(response);
          this.fliteringData = response.data;
          console.log('filter-data', this.fliteringData);
        }
      );
    }
  }
  onChangeTopic(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    if (this.dropDownSelection == '') {
      this.TopicNames = '';
    } else {
      this.resetBtn = true;
      this.TopicNames = this.dropDownSelection;
      this.queryParams = {
        speaker: this.Speaker ? this.Speaker : '',
        topic: this.TopicNames ? this.TopicNames : '',
        date: this.calendraDate ? this.calendraDate : '',
      };
      this.Service.WatchVideoRequestFliter(this.queryParams).subscribe(
        (response) => {
          console.log(response);
          this.fliteringData = response.data;
          console.log('filter-data', this.fliteringData);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  onChangeDate(e: any) {
    this.dropDownSelection = e.target.value;
    console.log(this.dropDownSelection);
    this.Date = this.dropDownSelection;
    let ds = this.dropDownSelection;
    let date = moment(new Date(ds.toString().substr(0, 16)));
    this.calendraDate = date.format('YYYY-MM-DD');
    console.log(this.calendraDate);
    if (e.target.value === '') {
      this.Date = '';
    } else {
      this.resetBtn = true;
      this.queryParams = {
        speaker: this.Speaker ? this.Speaker : '',
        topic: this.TopicNames ? this.TopicNames : '',
        date: this.calendraDate ? this.calendraDate : '',
      };
      this.Service.WatchVideoRequestFliter(this.queryParams).subscribe(
        (response) => {
          console.log(response);
          this.fliteringData = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }
  reset() {
    this.resetBtn = false;
    this.WatchGroup.reset({
      select: 0,
    });
    this.dropDownSelection = '';
    this.TopicNames = '';
    this.calendraDate = '';
    this.queryParams = {
      topic: '',
      date: '',
    };
    this.Service.WatchVideoRequestFliter(this.queryParams).subscribe(
      (response) => {
        console.log(response);
        this.fliteringData = response.data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
