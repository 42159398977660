<!-- <app-navbar></app-navbar>
<div class="main" id="top_scroll">
  <section class="hero section-padding pb-0 bg-gray" id="driving" data-scroll-index="driving">
    <div class="bg-gray text-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about mb-50">
              <h4>PAYMENT POLICY</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="hero pb-0 section-padding padd90 section-space40" id="driving" data-scroll-index="driving">
    <div class=" ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row mt-5 vertical-content">
              <div class="col-lg-12">
                <div class="terms-div"></div>
                <h4 class="terms-heading"></h4>
                <br />
                <p class="terms-para"> We thank you and appreciate your choice of pursuing a membership with us. Please read the policy, conditions and process carefully as they will give you important information and guidelines about your rights and obligations as our User, concerning any service you avail from us. The Payment policy of www.calvarytemple.in , shall be following the clauses as set forth:</p>
                <br />
                <h4 class="terms-heading">DONATIONS:</h4>
                <p class="term-para">TThe Platform accepts donations and payments from its users and members. (Via third party payment gateway “Razorpay”) 
</p>
                <p class="term-para">However, the following modes of payments and donations shall not be accepted or refunded by the Platform:
</p>
                <ol class="sub">
                  
                  <li> The donations made through our online platforms such as website and Android application shall not be refunded </li>
                  <li> The platform does not allow our members to donate via iOS application </li>
                  <li> The platform does not allow foreign nationals to make any kind of donations via online platforms such as Website and Android application and iOS mobile application </li>
                </ol>
                <br />
                <h4 class="terms-heading">USER CANCELLATION AND REFUND OF DONATIONS</h4>
                <p class="terms-para"> All members and Users of the Platform may opt to cancel their membership and termination their association with the Platform, by simply deleting their account with the Platform or sending an email to developer@calvarytemple.in. </p>
                <p class="terms-para mb-5"> However, in the event of any User having made a donation towards to the Church and/or the Platform, the requests for the refund of such donations shall not be entertained at any costs. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div> -->

<app-navbar></app-navbar>

<div class="container">
  <p><br></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;color:black;border:none;text-align:center;background:white;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>PAYMENT POLICY</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;color:black;border:none;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:windowtext;'>We thank you and appreciate your choice of pursuing a membership with us. Please read the policy, conditions and process carefully as they will give you important information and guidelines about your rights and obligations as our User, concerning any service you avail from us. The Payment policy of&nbsp;</span><a routerLink="/Home" href="http://www.calvarytemple.in"><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>www.calvarytemple.in</span></a><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:windowtext;'>&nbsp;, shall be following the clauses as set forth:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;color:black;border:none;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:windowtext;'>&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:center;line-height:150%;'><strong><span style="border:none;"><span style="border:none;">DONATIONS:</span></span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:justify;line-height:150%;'><span style="border:none;"><span style="border:none;">The Platform accepts donations and payments from its users and members. <span style="border:none;">(Via third party payment gateway &ldquo;Razorpay&rdquo;)&nbsp;</span></span></span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:justify;line-height:150%;'><span style="border:none;"><span style="border:none;">However, the following modes of payments and donations shall not be accepted or refunded by the Platform:</span></span></p>
<ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
    <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:justify;line-height:150%;'><span style="border:none;"><span style="border:none;">The donations made through our online platforms such as website and Android application shall not be ref<span style="border:none;">unded</span></span></span></li>
    <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:justify;line-height:150%;'><span style="border:none;"><span style="border:none;">The platform</span></span><span style="border:none;"><span style="border:none;">&nbsp;do</span></span><span style="border:none;"><span style="border:none;">es</span></span><span style="border:none;"><span style="border:none;">&nbsp;not allow our members to donate via iOS application</span></span></li>
    <li style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:justify;line-height:150%;'><span style="border:none;"><span style="border:none;">The platform</span></span><span style="border:none;"><span style="border:none;">&nbsp;do</span></span><span style="border:none;"><span style="border:none;">es</span></span><span style="border:none;"><span style="border:none;">&nbsp;not allow foreign nationals to make any kind of donations via online platforms such as Website and Android application and iOS mobile application</span></span></li>
</ul>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:center;line-height:150%;'><span style="border:none;">&nbsp;</span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;text-align:center;line-height:150%;'><strong><span style="border:none;"><span style="border:none;">USER</span></span></strong><strong><span style="border:none;"><span style="border:none;">&nbsp;CANCELLATI<span style="border:none;">ON</span></span></span></strong><strong><span style="border:none;"><span style="border:none;">&nbsp;AND <span style="border:none;">RE<span style="border:none;">FUND<span style="border:none;">&nbsp;OF DONATIONS</span></span></span></span></span></strong></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;line-height:150%;'><span style="border:none;"><span style="border:none;">All members and Users of the Platform may opt to cancel their membership and termination their association with the Platform, by simply deleting their account with the Platform or sending an email to &ldquo;developer@calvarytemple.in&rdquo;.&nbsp;</span></span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;border:none;line-height:150%;'><span style="border:none;"><span style="border:none;">However, i<span style="border:none;">n the event of any User having made a donation towards to the Church and/or the Platform, the <span style="border:none;">requests for the refund of such donations shall not be entertained at any costs.&nbsp;</span></span></span></span></p>
<p style='margin:0cm;font-size:16px;font-family:"Times New Roman",serif;color:black;border:none;margin-top:12.0pt;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;text-align:justify;line-height:150%;'>&nbsp;</p>
</div>

<app-footer></app-footer>