import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';

@Component({
  selector: 'app-mobile-success-view',
  templateUrl: './mobile-success-view.component.html',
  styleUrls: ['./mobile-success-view.component.css'],
})
export class MobileSuccessViewComponent implements OnInit {
  dataId: any;
  Services: any = 'Service';
  GreetingCardName: any;
  details: any;
  title: any;
  prayerid: any;
  queryParamsDetails: any;
  constructor(
    public dialog: MatDialog,
    public Service: PrayerRequestService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.prayerid = this.queryParamsDetails.params._id;
    });
    this.Service.prayerRequestBanner(this.Services).subscribe(
      (response: any) => {
        console.log(response);
        this.GreetingCardName = response.data.buttonName;
        this.details = response.data.details;
        this.title = response.data.title;
      }
    );
  }
}
