<div class="container">
  <div class="share-btns d-flex">
    <div class="share-1 mr-auto pr-2">
      <h4>Share</h4>
    </div>
    <div class="image-close cursor" (click)="close()">
      <img src="../../assets/images/close.svg" />
    </div>
  </div>
  <share-buttons
    [include]="[
      'facebook',
      'twitter',
      'linkedin',
      'telegram',
      'messenger',
      'whatsapp',
      'email'
    ]"
    [theme]="'circles-dark'"
    show:any="7"
    [url]="this.youtubelink"
  ></share-buttons>
</div>

<!-- <share-buttons
  title="Title works!"
  description="Description works!"
  [theme]="'circles-light'"
  show:any="11"
></share-buttons> -->
