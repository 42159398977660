<!-- <app-navbar></app-navbar>
<div class="main" id="scroll_top">
  <section class="hero section-padding pb-0 bg-gray" id="driving" data-scroll-index="driving">
    <div class="bg-gray text-center">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="about mb-50">
              <h4>TERMS OF SERVICE </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="hero pb-0 section-padding padd90 section-space40" id="driving" data-scroll-index="driving">
    <div class=" ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row mt-5 vertical-content">
              <div class="col-lg-12">
                <div class="terms-div"></div>
                <h4 class="terms-heading">1.GENERAL</h4>
                <br />
                <p class="terms-para">
                  
                 www.calvarytemple.in is the Website (hereinafter referred to as the “Platform”) made available by M/s CALVARY TEMPLE FOUNDATION (“Foundation”, “we”, “us” and “our”), a religious non-profit Christian organization established under the Laws Of India, having its registered office at Calvary Temple Road, Hyder Nagar, Miyapur, Hafeezpet, Hyderabad, Telangana 500049.  </p>
                <p class="mt-2"> The Foundation ensures steady commitment to your usage of the Platform and privacy with regard to the protection of your invaluable information. This document contains information about the Website www.calvarytemple.in (hereinafter referred to as the “Platform”).  </p>
                <br />
                <h4 class="terms-heading">DEFINITION</h4>
                <p class="terms-para"> For the purpose of these Terms of Service (“Terms”), wherever the context so requires, </p>
                <ol type="i">
                  <li> The terms “you” and “your” refer to the user of the Platform. </li>
                  <li> The term “Services” refers to any services offered by Us on the Platform. </li>
                  <li> “Third Parties” refer to any Application, Foundation or individual apart from the User, Vendor and the creator of this Application. </li>
                  <li> “Personal Information” shall mean and refer to any personally identifiable information that We may collect from You such as Name, Email Id, Mobile number, Password, Photo, etc. but not limited to it. </li>
                  <li> “Users” shall mean persons who use the Foundation’s Platform. </li>
                </ol>
                <br />
                <h4 class="terms-heading">GENERAL TERMS</h4>
                <ol type="i">
                  <li> The headings here in are only for organizing the provisions in an orderly manner. They hold no legal or contractual value for the parties. </li>
                  <li>These Terms, read with the Disclaimer Policy and Privacy Policy, form the complete and final contract between the User and the platform with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto. </li>
                  <li> While accessing and using this platform, you agree to consent the terms of this platform and thereby proceed with it. </li>
                  <li> The platform has the Right to change, amend, add or delete any of the provisions or terms and conditions as and when it deems fit to do so. It would be binding upon the users to adhere to the updated terms and conditions. Any such amendments made on the platform would be at the sole discretion of the platform. Therefore, you are strongly recommended to refer to the terms and privacy policy before using the platform. </li>
                  <li> Your continued use of services on this platform is an indicator to the platform that you are agreeing to comply with our services on this platform with your consent. If the users do not adhere to the terms and conditions of the platform, they may stop using the services at once. </li>
                  <li> The user grants us permission to use their information for legitimate purposes mentioned here in and the privacy policy Along with. The use of data would be as per the laws applicable in a proper and moral way. </li>
                  <li>The user is required to provide Accurate, updated and true information to the platform for its functioning. </li>
                  <li>Any discovery gathering of data of the platform or its users by you employing the means of mining, hacking, robots or other data extraction tools will hold you severely liable. </li>
                  <li> You should not publish any information that is false and misleading in any way or harms a minor or is abusive or offensive or affect public peace </li>
                </ol>
                <h4 class="terms-heading">SERVICE OVERVIEW</h4>
                <p class="terms-para"> The Platform is enables its registered members to access their weekly church attendance, access free services offered by the Calvary church (hereinafter referred as the Church). The platform facilitates its registered members to donate money to the church, or donate for a specific cause such as building a new church, or provide weekly or monthly offering to the church. The platform also facilitates its registered members to sponsor TV programs for which they can make an online donation/payment via the platform. </p>
                <br />
                <h4 class="terms-heading">REGISTRATION</h4>
                <p class="terms-para">Registration on the Platform is mandatory. To avail any service offered by the Platform, registration is mandatory for all users of the Platform. The Clients can register by providing the following information: </p>
                <ol type="a">
                  <li>Mobile Number</li>
                  <li>Email id</li>
                  <li>Name</li>
                  <li> Date of Birth</li>
                  <li>Gender</li>
                  <li>Address & Location</li>
                  <li>Payment information</li>
                </ol>
                <br />
                <h4 class="terms-heading">ELIGIBILITY</h4>
                <ol type="i">
                  <li>You represent and warrant that you are competent and eligible to enter into legally binding agreements and of age and that you have the requisite authority to bind yourself to these Terms of Service following the Law. However, if you are a minor using this Platform, you may do so with the consent of your legal guardian. All Acts of minor shall be deemed to be considered as the acts undertaken under the supervision of their legal guardian. Foundation is not responsible for any improper usage of platform by any minors in whatsoever in nature or manner </li>
                  <li>You further represent that you will comply with these Terms of Service and all applicable local, state, national and international laws, rules and regulations. </li>
                  <li>You shall not use the platform if you are not competent to contract or are disqualified from doing so by any other applicable law, rule or regulation currently in force. </li>
                  <li> The platform may not permit you to contract with it if at a later point it discovers that you are ineligible to contract as per the terms or have breached or violated our terms or privacy policy.</li>
                  <li> The platform may or may not use specific algorithms to determine the activities of the user and to verify if they are genuine or not for which it is deemed that user consent is approved. </li>
                  <li>vi.	The Platform does not accept and/or refund any donations or payments from any foreign nationals, or any members from the iOS application of the platform.  </li>
                </ol>
                <br />
                <h4 class="terms-heading">PAYMENT GATEWAY </h4>
                <p class="terms-para">For making all payments and donations for services on the Platform, you will be redirected to a Razorpay, third-party payment gateway that we may have an agreement with. Razorpay may additionally seek information from you to process the payment for your purchase and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under Razorpay’s Terms and Conditions and other Policies for the purpose of all payment-related aspects and so as norms and guidelines of Central, State Government rules and regulations as applicable.</p>
                <br />
                <h4 class="terms-heading">CONTENT</h4>
                <ol type="i">
                  <li>All context, text, graphics, write-ups, videos, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the users or third parties and We have no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the platform.</li>
                  <li>All the Content displayed on the platform is subject to copyright and shall not be reused by You (or a third party) without the prior written consent from Us and the copyright owner.</li>
                  <li>iii.	You are solely responsible for the integrity, authenticity, quality and genuineness of the content provided on the platform and whilst feedback and comments by You can be made via the platform, We bear no liability whatsoever for any feedback or comments made by other Users or made in respect of any of the content on the platform. Further, the platform reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the platform or to terminate the account of any User who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading.  </li>
                  <li> You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the platform. You shall not copy, adapt, and modify any content without written permission from Us. </li>
                </ol>
                <br />
                <h4 class="terms-heading">INDEMNITY</h4>
                <p class="terms-para"> You agree to indemnify, defend and hold Us harmless, and our respective directors, officers, employees and agents (collectively, "Parties"), from and against any losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these Terms of Service. Further, You agree to hold Us harmless against any claims made by any third party due to, or arising out of, or in connection with: </p>
                <ol type="a">
                  <li>Your use of the platform.</li>
                  <li>Your violation of these Terms of Service.</li>
                  <li>Your violation of any rights of another.</li>
                  <li>Your alleged improper conduct according to these Terms of Service.</li>
                  <li>Gender</li>
                  <li>Your conduct in connection with the platform.</li>
                  <li>You agree to fully cooperate in indemnifying Us at your expense. You also agree not to settle with any party without consent from Us.</li>
                </ol>
                <p class="terms-para"> In no event shall we be liable to compensate You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not You had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with Your use of or access to the Platform and/or the Services or materials contained therein. </p>
                <br />
                <h4 class="terms-heading">LIMITATION OF LIABILITY</h4>
                <ol type="i">
                  <li> We are not responsible for any consequences arising out of the following events: <ol class="sub">
                      <li>If the Platform is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure</li>
                      <li>If you have fed incorrect information or data or for any deletion of data </li>
                      <li>If there is an undue delay or inability to communicate through email </li>
                      <li>If there is any deficiency or defect in the Services managed by Us </li>
                      <li>If there is a failure in the functioning of any other service provided by Us. </li>
                    </ol>
                  </li>
                  <li> The platform accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to You, Your belongings, or any third party, resulting from the use or misuse of the platform or any service availed from Us through the platform. The service and any Content or material displayed on the service is provided without any guarantees, conditions or warranties as to its accuracy, suitability, completeness or reliability. We will not be liable to you for the unavailability or failure of the platform. </li>
                  <li>You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of Service by reference. </li>
                  <li> The platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the platform and which is incurred by you in connection with the platform, including loss of profits and any loss or damage incurred by you as a result of your breach of these Terms of Service.</li>
                  <li> To the fullest extent permitted by law, we shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the platform.</li>
                   </ol>
                  <br />
                  <h4 class="terms-heading">TERM</h4>
                  <ol type="i">
                    <li>These Terms of Service shall continue to form a valid and binding contract between us and shall continue to be in full force and effect until you continue to access and use the platform.</li>
                    <li>You may terminate your use of the platform at any time by deleting your account with us. </li>
                    <li>We may terminate these Terms of Service and close your account at any time without notice and/or suspend or terminate Your access to the platform at any time and for any reason, if any discrepancy or legal issue arises. </li>
                    <li>Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate. </li>
                    <li>It is also hereby declared that we may discontinue the Services and Platform without any prior notice. </li>
                  </ol>
                  <br />
                  <h4 class="terms-heading"> TERMINATION</h4>
                  <ol type="i">
                    <li> We reserve the right, in its sole discretion, to unilaterally terminate Your access to the platform, or any portion thereof, at any time, without notice or cause. </li>
                    <li> We also reserve the universal right to deny access to You, to any/all of our Services, on its platform without any prior notice/explanation to protect the interests of the platform and/or other Users to the platform </li>
                    <li> We reserve the right to limit, deny or create different access to the platform and its features concerning different Users, or to change any of the features or introduce new features without prior notice. </li>
                    <li> You shall continue to be bound by these Terms of Service, and it is expressly agreed to by You that You shall not have the right to terminate these Terms of Service till the expiry of the same. </li>
                  </ol>
                  <br />
                  <h4 class="terms-heading">COMMUNICATION</h4>
                  <ol type="i">
                    <li> By using this platform and providing Your identity and contact information to Us through the platform, You agree and consent to receive emails or SMS from Us and/or any of its representatives at any time.</li>
                    <li>You can report to developer@calvarytemple.in if you find any discrepancy with regard to platform or content-related information and we will take necessary action after an investigation. The response with resolution (if any issues found) shall be dependent on the time taken for investigation. </li>
                    <li>You expressly agree that notwithstanding anything contained hereinabove, you may be contacted by Us relating to any services availed by You on the platform or anything pursuant thereto and You agree to indemnify Us from any harassment claims. It is expressly agreed to by Us that any information shared by Us shall be governed by the Privacy Policy. </li>
                  </ol>
                  <br />
                  <h4 class="terms-heading"> USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT </h4>
                  <p>You agree and acknowledges that you are a restricted user of this platform and you:</p>
                  <ol type="i">
                    <li> Agree to provide genuine credentials during the process whenever required on the platform. You shall not use a fictitious identity. We are not liable if you have provided incorrect information. </li>
                    <li> Agree to ensure the Phone Number, name, address, location and any such other information that may be provided and is valid at all times and shall keep your information accurate and up-to-date. </li>
                    <li>You agree that You are solely responsible for maintaining the confidentiality of your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.</li>
                    <li> Understand and acknowledge that the data submitted is manually entered into the database of the platform. You also acknowledge the fact that data so entered into the database is for easy and ready reference for You, and to streamline the Services through the platform. </li>
                    <li>Understand and agree that, to the fullest extent permissible by law, the platform or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the platform or this Terms of Service, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages. </li>
                    <li> Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the platform. Any such use/limited use of the platform will only be allowed with the prior express written permission. </li>
                    <li>Authorize the platform to use, store or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews and ratings for personalization of Services, marketing and promotional purposes and for optimization of User-related options and Services. </li>
                    <li> Agree not to access (or attempt to access) the platform and/or the materials or Services by any means other than through the interface provided by the platform. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the platform or its content, or in any way reproduce or circumvent the navigational structure or presentation of the platform, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of your access to the platform. We disclaim any liabilities arising concerning such offensive content on the platform. </li>
                    <li> Expressly agree and acknowledge that the Content generated by the Users and displayed on the platform is not owned by Us and that We are in no way responsible for the content of the same. You may, however, report any offensive or objectionable content, which We may then remove from the platform at our sole discretion. </li>
                    <li> The Users shall upload valid medical prescription while ordering any medicines or pharmaceutical products. If the User fails to upload such prescription or determines that the prescription so uploaded is fake or forged, the Foundation shall reserve the right to take any legal action available to it under the Court of Law. </li>
                  </ol>
                  <br />
                  <h4 class=" text-left terms-heading">You further undertake not to:</h4>
                  <ol type="i">
                    <li>Engage in any activity that interferes with or disrupts access to the Platform or the services provided therein (or the servers and networks which are connected to the platform) </li>
                    <li> Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity </li>
                    <li>You agree that You are solely responsible for maintaining the confidentiality of your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.</li>
                    <li> Disrupt or interfere with the security of, or otherwise cause harm to, the platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the platform or any affiliated or linked Platform. </li>
                    <li>Understand and agree that, to the fullest extent permissible by law, the platform or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the platform or this Terms of Service, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages. </li>
                    <li>Use the platform or any material or content therein for any purpose that is unlawful or prohibited by these Terms of Service, or to solicit the performance of any illegal activity or other activity which infringes the rights of this platform or any other third party (s)</li>
                    <li> Violate any code of conduct or guideline which may apply for or to any particular service offered on the platform </li>
                    <li> Violate any portion of these Terms of Service or the Privacy Policy, including but not limited to any applicable additional terms of the platform contained herein or elsewhere, whether made by amendment, modification, or otherwise </li>
                    <li> You hereby expressly authorize Us to disclose any and all information relating to You in our possession to law enforcement or other government officials, as We may in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. You further understand that the platform might be directed to disclose any information (including the identity of persons providing information or materials on the platform) as necessary to satisfy any judicial Order, law, regulation or valid governmental request. </li>
                    <li> By indicating Your acceptance to use any services offered on the platform. You are obligated to complete such transactions after making payment. You shall be prohibited from indicating their acceptance to avail services where the transactions have remained incomplete. </li>
                    <li> You agree to use the services provided by Us, our affiliates, consultants and contracted companies, for lawful purposes only. </li>
                    <li> You agree to provide authentic and true information. We reserve the right to confirm and validate the information and other details provided by You at any point in time. If upon confirmation such details are found to be false, not to be true (wholly or partly), We shall in our sole discretion reject the order and debar You from using the platform without prior intimation whatsoever. </li>
                    <li> You agree not to post any material on the platform that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful or advertising any goods or services. More specifically, You agree not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that: <ol type="a">
                        <li>belongs to another person and to which you have no right to</li>
                        <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever </li>
                        <li>violates any law for the time being in force </li>
                        <li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing </li>
                        <li>Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others</li>
                        <li> Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity</li>
                        <li>is in any way harmful to minors</li>
                        <li>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity </li>
                        <li> Publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986 </li>
                        <li> Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation. </li>
                      </ol>
                    </li>
                  </ol>
                  <br />
                  <h4 class="terms-heading"> SUSPENSION OF USER ACCESS AND ACTIVITY</h4>
                  <p class="terms-para">Not with standing other legal remedies that may be available, we may in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminate your association with the platform. and/or refuse to the usage of the platform. without being required to provide you with notice or cause: </p>
                  <ol type="a">
                    <li> If you are in breach of any of these Terms of Service or the Privacy Policy. </li>
                    <li> If you have provided wrong, inaccurate, incomplete or incorrect information. </li>
                    <li> If your actions may cause any harm, damage or loss to the other Users or Us, at our sole discretion. </li>
                  </ol>
                  <br />
                  <h4 class="terms-heading"> INTELLECTUAL PROPERTY RIGHTS</h4>
                  <ol type="i">
                    <li> Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms of Service that shall be available on the platform. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the platform or such other third party and other distinctive brand features of the platform are the property of the Platform or the respective copyright or trademark owner. Furthermore, concerning the platform. we shall be the exclusive owner of all the designs, graphics and the like, related to the platform. </li>
                    <li> You shall not use any of the intellectual property displayed on the platform in any manner that is likely to cause confusion among existing or prospective users of the platform. or that in any manner disparages or discredits the platform. to be determined in the sole discretion.</li>
                    <li> You are aware of all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators. You are aware that we merely provide the platform through which You can communicate with other users and the Platform does not own any of the intellectual property relating to the independent content displayed on the platform. apart from created graphics and specified content</li>
                    <li> You are further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by You will result in legal action being initiated against You by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by You that the contents of this section shall survive even after the termination or expiry of these Terms of Service and/or Privacy Policy. </li>
                  </ol>
                  <br/>
                  <h4 class="terms-heading"> DISCLAIMER OF WARRANTIES AND LIABILITIES </h4>
                  <ol type="i">
                    <li> You further agree and undertake that you are accessing the platform at your sole risk and are using the best and prudent judgment before availing of any features on the platform or accessing/using any information displayed thereon. </li>
                    <li> You agree that any kind of information, resources, activities, recommendations obtained/availed from the platform. written or oral, will not create any warranty and we disclaim all liabilities resulting from these. </li>
                    <li> We do not guarantee that the features and content contained in the platform will be uninterrupted or error-free, or that the Platform or its server will be free of viruses or other harmful components, and You hereby expressly accept any associated risks involved with your use of the platform. </li>
                    <li> It is further agreed to by You that the contents of this section shall survive even after the termination or expiry of the Terms of Service, and/or Privacy Policy. </li>
                  </ol>
                  <br />
                  <h4 class="terms-heading"> FORCE MAJEURE</h4>
                  <p class="terms-para">The Platform shall not be liable for damages for any delay or failure to perform its obligations hereunder if such delay or failure is due to cause beyond its control or without its fault or negligence, due to Force Majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized. </p>
                  <br>
                  <h4 class="terms-heading"> DISPUTE RESOLUTION AND JURISDICTION</h4>
                  <p class="terms-para">In the event of any dispute arising out of or in connection with this agreement, including any dispute relating to the validity of this agreement, the parties shall, at first instance, attempt to resolve the dispute by mediation administered by the Centre for Online Resolution of Disputes (“CORD”) (www.resolveoncord.com) and conducted in accordance with the CORD Rules of Mediation, by a sole mediator to be appointed by CORD. </p>
                  <p class="terms-para">In the event the parties are unable to resolve any such dispute through mediation within 45 days of the initiation of the dispute, subject to a contrary agreement between the parties, the dispute shall be finally resolved by arbitration administered by CORD and conducted in accordance with the Rules of Arbitration of CORD, by a sole arbitrator to be appointed by CORD. The language of arbitration shall be English. The seat of arbitration shall be _______________, India. </p>
                  <p class="terms-para">You expressly agree that the Terms of Service, Privacy Policy and any other agreements entered into between the Parties are governed by the laws, rules, and regulations of India. </p>
                  <br>

                  <h4 class="terms-heading"> GRIEVANCE AND OTHER PLATFORM SECURITY</h4>
                  <p class="terms-para">If you have any queries relating to the terms here, or if you notice any abuse or violation of this privacy policy, please bring it to a notice on the following email address developer@calvarytemple.in or contact us on the following address for grievances. </p>
                  <p class="terms-para mt-2"> Details of Grievance Redressal Officer: </p>
                  <p class="terms-para"> Name: </p>
                  <p class="terms-para"> Email Address: </p>
                  <p class="terms-para"> Office Address: </p>
                  <br>

                  <h4 class="terms-heading"> MISCELLANEOUS PROVISIONS </h4>
                  <ol type="i">
                    <li> Entire Agreement: These Terms of Service read with the Disclaimer Policy, Cookie Policy and Privacy Policy, form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto. </li>
                    <li> Waiver: The failure at any time to require performance of any provision of these Terms of Service shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of Service, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of Service. </li>
                    <li> Severability: If any provision/clause of these Terms of Service is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of Service shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of Service shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms of Service shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein. </li>
                    <li> Contact Us: If you have any questions about these Terms of Service, the practices of the platform. or your experience, you can contact us by emailing us at developer@calvarytemple.in or by writing to us at : developer@calvarytemple.in</li>
                  </ol>
                  <p class="terms-para text-center mt-3">M/s CALVARY TEMPLE FOUNDATION </p>
                  <p class="terms-para text-center mb-5"> Calvary Temple Road, Hyder Nagar, Janapriya Nagar, Hafeezpet, Hyderabad, Telangana 500049. </p>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div> -->
  <app-navbar></app-navbar>
<div class="main container" id="scroll_top">  

<p><br></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:19px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp; &nbsp;<u>TERMS OF SERVICE&nbsp;</u></span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>GENERAL</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style="text-decoration:underline;"><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>www.calvarytemple.in&nbsp;</span></span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>is the<strong>&nbsp;</strong>Website (hereinafter referred to as the &ldquo;Platform&rdquo;)&nbsp;</span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;'>made available by M/s&nbsp;</span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;'>CALVARY TEMPLE FOUNDATION</span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;'>&nbsp;(&ldquo;Foundation&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; and &ldquo;our&rdquo;), a religious non-profit Christian organization established under the Laws Of India, having its registered office at&nbsp;</span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;background:white;'>Calvary Temple Road, Hyder Nagar, Miyapur, Hafeezpet, Hyderabad, Telangana 500049.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The Foundation ensures steady commitment to your usage of the Platform and privacy with regard to the protection of your invaluable information. This document contains information about the Website&nbsp;</span><span style="text-decoration:underline;"><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>www.calvarytemple.in&nbsp;</span></span><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>(hereinafter referred to as the &ldquo;Platform&rdquo;).&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>DEFINITION</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>For the purpose of these Terms of Service (&ldquo;Terms&rdquo;), wherever the context so requires,</span></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;color:black;'>The terms &ldquo;you&rdquo; and &ldquo;your&rdquo; refer to the user of the Platform.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;color:black;'>The term &ldquo;Services&rdquo; refers to any services offered by Us on the Platform.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;color:black;'>&ldquo;Third Parties&rdquo; refer to any Application, Foundation or individual apart from the User, Vendor and the creator of this Application.<u>&nbsp;</u></span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;color:black;'>&ldquo;Personal Information&rdquo; shall mean and refer to any personally identifiable information that We may collect from You such as Name, Email Id, Mobile number, Password, Photo, etc. but not limited to it.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>&ldquo;Users&rdquo; shall mean persons who use the Foundation&rsquo;s Platform.&nbsp;</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>GENERAL TERMS</span></strong></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>The headings herein are only for organizing the provisions in an orderly manner. They hold no legal or contractual value for the parties.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>These Terms, read with the Disclaimer Policy and Privacy Policy, form the complete and final contract between the User and the platform with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>While accessing and using this platform, you agree to consent the terms of this platform and thereby proceed with it.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>The platform has the Right to change, amend, add or delete any of the provisions or terms and conditions as and when it deems fit to do so. It would be binding upon the users to adhere to the updated terms and conditions. Any such amendments made on the platform would be at the sole discretion of the platform and the same can be done at sole discretion of platform without any prior intimation or information and will done as and when required by the Platform. Therefore, you are strongly recommended to refer to the terms and privacy policy before using the platform.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Your continued use of services on this platform is an indicator to the platform that you are agreeing to comply with our services on this platform with your consent. If the users do not adhere to the terms and conditions of the platform, they may stop using the services at once.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>The user grants us permission to use their information for legitimate purposes mentioned here in and the privacy policy Along with. The use of data would be as per the laws applicable in a proper and moral way.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>The user is required to provide Accurate, updated and true information to the platform for its functioning.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Any discovery gathering of data of the platform or its users by you employing the means of mining, hacking, robots or other data extraction tools will hold you severely liable.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You should not publish any information that is false and misleading in any way or harms a minor or is abusive or offensive or affect public peace.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>SERVICE OVERVIEW</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The Platform is enables its registered members to access their weekly church attendance, access free services offered by the Calvary church (here in after referred as the <strong>Church</strong>). The platform facilitates its registered members to donate money to the church, or donate for a specific cause such as building a new church, or provide weekly or monthly offering to the church. The platform also facilitates its registered members to sponsor TV programs for which they can make an online donation/payment via the platform.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-family:"Times New Roman",serif;'>REGISTRATION</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Registration on the Platform is mandatory. To avail any service offered by the Platform, registration is mandatory for all users of the Platform. The Clients can register by providing the following information:</span></p>
<ol start="1" style="list-style-type: lower-alpha;margin-left:89.25px;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Name</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Date of Birth</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Gender</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Address &amp; Location</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Mobile Number&nbsp;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Email id</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Payment information</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>ELIGIBILITY</span></strong></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You represent and warrant that you are competent and eligible to enter into legally binding agreements and of age and that you have the requisite authority to bind yourself to these Terms of Service following the Law. However, if you are a minor using this Platform, you may do so with the consent of your legal guardian. All Acts of minor shall be deemed to be considered as the acts undertaken under the supervision of their legal guardian. Foundation is not responsible for any improper usage of platform by any minors in whatsoever in nature or manner</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You further represent that you will comply with these Terms of Service and all applicable local, state, national and international laws, rules and regulations.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You shall not use the platform if you are not competent to contract or are disqualified from doing so by any other applicable law, rule or regulation currently in force.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;color:black;'>The platform may not permit you to contract with it if at a later point it discovers that you are ineligible to contract as per the terms or have breached or violated our terms or privacy policy.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;color:black;'>The platform may or may not use specific algorithms to determine the activities of the user and to verify if they are genuine or not for which it is deemed that user consent is approved.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;color:black;'>The Platform does not accept and/or refund any donations or payments from any foreign nationals, or any members from the iOS application of the platform.&nbsp;</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>PAYMENT GATEWAY</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>For making all payments and donations for services on the Platform, you will be redirected to a Razorpay, third-party payment gateway that we may have an agreement with. Razorpay may additionally seek information from you to process the payment for your purchase and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under Razorpay&rsquo;s Terms and Conditions and other Policies for the purpose of all payment-related aspects and so as norms and guidelines of Central, State Government rules and regulations as applicable.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>CONTENT</span></strong></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="i">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>All context, text, graphics, write-ups, videos, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music and artwork (collectively, <strong>&lsquo;Content&rsquo;</strong>), is generated/provided or based on information provided by the users or third parties and We have no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the platform.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>All the Content displayed on the platform is subject to copyright and shall not be reused by You (or a third party) without the prior written consent from Us and the copyright owner.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You are solely responsible for the integrity, authenticity, quality and genuineness of the content provided on the platform and whilst feedback and comments by You can be made via the platform, We bear no liability whatsoever for any feedback or comments made by other Users or made in respect of any of the content on the platform. Further, the platform reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the platform or to terminate the account of any User who is found to have created or shared or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading.&nbsp;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the platform. You shall not copy, adapt, and modify any content without written permission from Us.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:-7.1pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>INDEMNITY</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You agree to indemnify, defend and hold Us harmless, and our respective directors, officers, employees and agents (collectively, &quot;<strong>Parties</strong>&quot;), from and against any losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these Terms of Service. Further, You agree to hold Us harmless against any claims made by any third party due to, or arising out of, or in connection with:</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your use of the platform.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your violation of these Terms of Service;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your violation of any rights of another;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your alleged improper conduct according to these Terms of Service;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Your conduct in connection with the platform.&nbsp;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You agree to fully cooperate in indemnifying Us at your expense. You also agree not to settle with any party without consent from Us.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>In no event shall we be liable to compensate You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not You had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with Your use of or access to the Platform and/or the Services or materials contained therein.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>LIMITATION OF LIABILITY</span></strong></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>We are not responsible for any consequences arising out of the following events:</span>
        <ul style="list-style-type: disc;">
            <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>If the Platform is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;</span></li>
            <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>If you have fed incorrect information or data or for any deletion of data;</span></li>
            <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>If there is an undue delay or inability to communicate through email;</span></li>
            <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>If there is any deficiency or defect in the Services managed by Us;</span></li>
            <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>If there is a failure in the functioning of any other service provided by Us.</span></li>
        </ul>
    </li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>The platform accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to You, Your belongings, or any third party, resulting from the use or misuse of the platform or any service availed from Us through the platform. The service and any Content or material displayed on the service is provided without any guarantees, conditions or warranties as to its accuracy, suitability, completeness or reliability. We will not be liable to you for the unavailability or failure of the platform.</span></li>
</ol>
<ol style="list-style-type: undefined;margin-left:26px;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of Service by reference.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>The platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the platform and which is incurred by you in connection with the platform, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of Service.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>To the fullest extent permitted by law, we shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the platform.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>TERM</span></strong></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="i">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>These Terms of Service shall continue to form a valid and binding contract between us and shall continue to be in full force and effect until you continue to access and use the platform.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You may terminate your use of the platform at any time by deleting your account with us.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We may terminate these Terms of Service and close your account at any time without any prior notice and/or suspend or terminate Your access to the platform at any time and for any reason, if any discrepancy or legal issue arises.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>It is also hereby declared that we may discontinue the Services and Platform without any prior notice.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>TERMINATION</span></strong></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="i">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We reserve the right, in its sole discretion, to unilaterally terminate Your access to the platform, or any portion thereof, at any time, without notice or cause.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We also reserve the universal right to deny access to You, to any/all of our Services, on its platform without any prior notice/explanation to protect the interests of the platform and/or other Users to the platform.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>We reserve the right to limit, deny or create different access to the platform and its features concerning different Users, or to change any of the features or introduce new features without prior notice.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You shall continue to be bound by these Terms of Service, and it is expressly agreed to by You that You shall not have the right to terminate these Terms of Service till the expiry of the same.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>COMMUNICATION</span></strong></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>By using this platform and providing Your identity and contact information to Us through the platform, You agree and consent to receive emails or SMS from Us and/or any of its representatives at any time.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You can report to &ldquo;developer@calvarytemple.in&rdquo; if you find any discrepancy with regard to platform or content-related information and we wdill take necessary action after an investigation. &nbsp; The response with resolution (if any issues found) shall be dependent on the time taken for investigation.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You expressly agree that notwithstanding anything contained hereinabove, you may be contacted by Us relating to any services availed by You on the platform or anything pursuant thereto and You agree to indemnify Us from any harassment claims. It is expressly agreed to by Us that any information shared by Us shall be governed by the Privacy Policy.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>USER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO CONDUCT</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You agree and acknowledges that you are a restricted user of this platform and you:</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="i">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Agree to provide genuine credentials during the process whenever required on the platform. You shall not use a fictitious identity. We are not liable if you have provided incorrect information.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Agree to ensure the Phone Number, name, address, location and any such other information that may be provided and is valid at all times and shall keep your information accurate and up-to-date.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You agree that You are solely responsible for maintaining the confidentiality of your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Understand and acknowledge that the data submitted is manually entered into the database of the platform. You also acknowledge the fact that data so entered into the database is for easy and ready reference for You, and to streamline the Services through the platform.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Authorize the platform &nbsp; &nbsp; &nbsp; &nbsp;to use, store or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews and ratings for personalization of Services, marketing and promotional purposes and for optimization of User-related options and Services.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Understand and agree that, to the fullest extent permissible by law, the platform &nbsp;or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the platform &nbsp;or this Terms of Service, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information or obtained from the platform. Any such use/limited use of the platform will only be allowed with the prior express written permission.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Agree not to access (or attempt to access) the platform and/or the materials or Services by any means other than through the interface provided by the platform. The use of deep-link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the platform or its content, or in any way reproduce or circumvent the navigational structure or presentation of the platform, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of your access to the platform. We disclaim any liabilities arising concerning such offensive content on the platform.&nbsp;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Expressly agree and acknowledge that the Content generated by the Users and displayed on the platform is not owned by Us and that We are in no way responsible for the content of the same. You may, however, report any offensive or objectionable content, which We may then remove from the platform at our sole discretion.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The Users shall upload valid medical prescription while ordering any medicines or pharmaceutical products. If the User fails to upload such prescription or determines that the prescription so uploaded is fake or forged, the Foundation shall reserve the right to take any legal action available to it under the Court of Law.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Any litigation shall be subject to the Jurisdiction at Ranga Reddy District courts, Telangana, India.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><strong><u><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You further undertake not to:</span></u></strong></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="i">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Engage in any activity that interferes with or disrupts access to the Platform or the services provided therein (or the servers and networks which are connected to the platform);</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Impersonate any person or entity, or falsely state or otherwise misrepresent his/her affiliation with a person or entity;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Probe, scan or test the vulnerability of the platform or any network connected to the platform. nor breach the security or authentication measures on the platform &nbsp; &nbsp; &nbsp; &nbsp;or any network connected to the platform. The User may not reverse look-up, trace or seek to trace any information relating to any other User of, or visitor to, the platform. or any other viewer of the platform, including any User account maintained on the platform &nbsp;not operated/managed by the User, or exploit the platform &nbsp;or information made available or offered by or through the platform. in any manner;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Disrupt or interfere with the security of, or otherwise cause harm to, the platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the platform &nbsp;or any affiliated or linked Platform.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Use the platform &nbsp; &nbsp; &nbsp; &nbsp;or any material or content therein for any purpose that is unlawful or prohibited by these Terms of Service, or to solicit the performance of any illegal activity or other activity which infringes the rights of this platform &nbsp;or any other third party (s);</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Violate any code of conduct or guideline which may apply for or to any particular service offered on the platform;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Violate any applicable laws, rules or regulations currently in force within or outside India;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Violate any portion of these Terms of Service or the Privacy Policy, including but not limited to any applicable additional terms of the platform &nbsp;contained herein or elsewhere, whether made by amendment, modification, or otherwise;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You hereby expressly authorize Us to disclose any and all information relating to You in our possession to law enforcement or other government officials, as We may in our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those involve personal injury and theft/infringement of intellectual property. You further understand that the platform might be directed to disclose any information (including the identity of persons providing information or materials on the platform) as necessary to satisfy any judicial Order, law, regulation or valid governmental request.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>By indicating Your acceptance to use any services offered on the platform. You are obligated to complete such transactions after making payment. You shall be prohibited from indicating their acceptance to avail services where the transactions have remained incomplete.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You agree to use the services provided by Us, our affiliates, consultants and contracted companies, for lawful purposes only.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You agree to provide authentic and true information. We reserve the right to confirm and validate the information and other details provided by You at any point in time. If upon confirmation such details are found to be false, not to be true (wholly or partly), We shall in our sole discretion reject the order and debar You from using the platform without prior intimation whatsoever.</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You agree not to post any material on the platform that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful or advertising any goods or services. More specifically, You agree not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:</span></li>
</ol>
<ol style="list-style-type: lower-alpha;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>belongs to another person and to which you have no right to;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another&apos;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>is in any way harmful to minors;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>infringes any patent, trademark, copyright or other proprietary rights;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>violates any law for the time being in force;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libellous, invasive of another&apos;s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to &quot;indecent representation of women&quot; within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:72.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>SUSPENSION OF USER ACCESS AND ACTIVITY</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>Notwithstanding other legal remedies that may be available, we may in our sole discretion, limit your access and/or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend/terminate your association with the platform. and/or refuse to the usage of the platform. without being required to provide you with notice or cause:</span></p>
<ol start="1" style="margin-bottom:0cm;margin-top:0cm;" type="a">
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>If you are in breach of any of these Terms of Service or the Privacy Policy;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>If you have provided wrong, inaccurate, incomplete or incorrect information;</span></li>
    <li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:     150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>If your actions may cause any harm, damage or loss to the other Users or Us, at our sole discretion.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>INTELLECTUAL PROPERTY RIGHTS</span></strong></p>
<ol style="list-style-type: lower-roman;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms of Service that shall be available on the platform. All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the platform &nbsp;or such other third party and other distinctive brand features of the platform &nbsp; are the property of the Platform or the respective copyright or trademark owner. Furthermore, concerning the platform. we shall be the exclusive owner of all the designs, graphics and the like, related to the platform.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You shall not use any of the intellectual property displayed on the platform in any manner that is likely to cause confusion among existing or prospective users of the platform. or that in any manner disparages or discredits the platform. to be determined in the sole discretion.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You are aware of all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators. You are aware that we merely provide the platform through which You can communicate with other users and the Platform does not own any of the intellectual property relating to the independent content displayed on the platform. apart from created graphics and specified content.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You are further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by You will result in legal action being initiated against You by the respective owners of the intellectual property so reproduced/infringed upon. It is agreed to by You that the contents of this section shall survive even after the termination or expiry of these Terms of Service and/or Privacy Policy.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>DISCLAIMER OF WARRANTIES AND LIABILITIES</span></strong></p>
<ol style="list-style-type: lower-roman;margin-left:32.55px;">
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You further agree and undertake that you are accessing the platform at your sole risk and are using the best and prudent judgment before availing of any features on the platform or accessing/using any information displayed thereon.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>You agree that any kind of information, resources, activities, recommendations obtained/availed from the platform. written or oral, will not create any warranty and we disclaim all liabilities resulting from these.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>We do not guarantee that the features and content contained in the platform will be uninterrupted or error-free, or that the Platform or its server will be free of viruses or other harmful components, and You hereby expressly accept any associated risks involved with your use of the platform.</span></li>
    <li><span style='line-height:150%;font-family:"Times New Roman",serif;'>It is further agreed to by You that the contents of this section shall survive even after the termination or expiry of the Terms of Service, and/or Privacy Policy.</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>FORCE MAJEURE</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>The Platform shall not be liable for damages for any delay or failure to perform its obligations hereunder if such delay or failure is due to cause beyond its control or without its fault or negligence, due to Force Majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>DISPUTE RESOLUTION AND JURISDICTION</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>In the event of any dispute arising out of or in connection with this agreement, including any dispute relating to the validity of this agreement, the parties shall, at first instance, attempt to resolve the dispute by mediation administered by the Centre for Online Resolution of Disputes (&ldquo;CORD&rdquo;) (www.resolveoncord.com) and conducted in accordance with the CORD Rules of Mediation, by a sole mediator to be appointed by CORD.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>In the event the parties are unable to resolve any such dispute through mediation within 45 days of the initiation of the dispute, subject to a contrary agreement between the parties, the dispute shall be finally resolved by arbitration administered by CORD and conducted in accordance with the Rules of Arbitration of CORD, by a sole arbitrator to be appointed by CORD. The language of arbitration shall be English. The seat of arbitration shall be at Territorial Jurisdiction of Ranga Reddy District Court, Telangana, <strong>India.</strong></span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>You expressly agree that the Terms of Service, Privacy Policy and any other agreements entered into between the Parties are governed by the laws, rules, and regulations of India.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;'>GRIEVANCE AND OTHER PLATFORM SECURITY</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;'>If you have any queries relating to the terms here, or if you notice any abuse or violation of this privacy policy, please bring it to a notice on the following email address developer@calvarytemple.in or contact us on the following address for grievances.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;'>Details of Grievance Redressal Officer:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Name:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Email Address:  developer@calvarytemple.in</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>Office Address:</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>MISCELLANEOUS PROVISIONS</span></strong></p>
<ol start="1" style="list-style-type: lower-alpha;margin-left:-24.2px;">
    <li><strong><span style='line-height:150%;font-family:"Times New Roman",serif;'>Entire Agreement:</span></strong><span style='line-height:150%;font-family:"Times New Roman",serif;font-family:"Times New Roman",serif;font-size:12.0pt;'>&nbsp;These Terms of Service read with the Disclaimer Policy, Cookie Policy and Privacy Policy, form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements (whether oral, written or otherwise) relating thereto.</span></li>
    <li><strong><span style='line-height:150%;font-family:"Times New Roman",serif;'>Waiver:</span></strong><span style='line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;The failure at any time to require performance of any provision of these Terms of Service shall in no manner affect our right at a later time to enforce the same. No waiver by us of any breach of these Terms of Service, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of Service.</span></li>
    <li><strong><span style='line-height:150%;font-family:"Times New Roman",serif;'>Severability:</span></strong><span style='line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;If any provision/clause of these Terms of Service is held to be invalid, illegal or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions/clauses of these Terms of Service shall in no way be affected or impaired thereby, and each such provision/clause of these Terms of Service shall be valid and enforceable to the fullest extent permitted by law.In such case, these Terms of Service shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.</span></li>
    <li><strong><span style='line-height:150%;font-family:"Times New Roman",serif;'>Contact Us:</span></strong><span style='line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;If you have any questions about these Terms of Service, the practices of the platform. or your experience, you can contact us by emailing us at developer@calvarytemple.in or by writing to us at : developer@calvarytemple.in</span></li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;font-family:"Times New Roman",serif;color:black;'>&nbsp;</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;border:none;'>M/s&nbsp;</span></strong><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;border:none;'>CALVARY TEMPLE FOUNDATION</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'><strong><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;color:black;border:none;'>Calvary Temple Road, Hyder Nagar, Hafeezpet, Miyapur, Hyderabad, Telangana 500049.</span></strong></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:150%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:150%;font-family:"Times New Roman",serif;'>&nbsp;</span></p>

</div> 
 <app-footer></app-footer>
