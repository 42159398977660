<div class="main">
  <div class="container">
    <div class="back-img mt-4" (click)="backToDonate()">
      <img
        src="../../assets/images/arrow-left.svg"
        class="arrow-left_img cursor"
      />
    </div>
    <div class="congrats">
      <img src="../../assets/images/thank.gif" class="success_Img" />
    </div>

    <div class="thank_you-section">
      <h4>Thankyou!</h4>
      <p>We have received your payment.</p>
      <h5 class="text-center">Payment Id: {{donatePaymentId}}</h5>
    </div>

    <div class="greeting_card_section">
      <!-- <img src="../../assets/images/card-image-1.png" class="greeting_card" /> -->

      <div
        class="greeting_card_details_main"
        style="background-image: url(../../assets/images/donate-baaner.webp)"
      >
        <!-- <div class="greeting_card_details">
          <h5>“{{ title }}”</h5>
          <p>{{ details }}.</p>
          <div class="greeting_card_btn">
            <button class="btn">{{ GreetingCardName }}</button>
          </div>
        </div> -->
      </div>
    </div>

    <div class="text-centre mb-3 request-prayer">
      <button class="btn back_to_btn" (click)="backToDonate()">
        Back to Donate
      </button>
    </div>
  </div>
</div>
