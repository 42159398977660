<div class="main" id="top_scroll">
  <app-navbar></app-navbar>
  <!-- largest-tv-section  -->
  <div class="">
    <!-- <img
      src="../../assets/images/l-1.png"
      class="c-1"
      alt="Snow"
      style="width: 100%"
    /> -->
    <div class="container mt-md-5">
      <div class="tv-programs-form-section">
        <div class="tv-programs-sponsor-section" *ngIf="Sponsor">
          <h4>Sponsor a TV Program and be blessed.</h4>
          <!-- <p>Lorem ipsum dolor sit amet, consectetur</p> -->
          <form [formGroup]="tvProgramRequestForm">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputPhone">Language</label>
                  <select required formControlName="language" [(ngModel)]="language"   placeholder="Select language" (change)="onChange($event)">
                    <option value="" selected disabled hidden>Select Language</option>
                    <option value="{{ title._id }}" *ngFor="let title of languageDropDownData">
                      {{ title.title }}
                    </option>
                  </select>
                  <div *ngIf="f.language.errors?.required && submitted" class="invalid-feedback d-block"> Please select language </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputPhone">No.of programs</label>
                  <select required formControlName="noOfProgram" placeholder="Enter no of programs" [(ngModel)]="numberOfPrograms" (change)="ProgramChange($event)">
                    <option value="" disabled hidden selected> No.of Programs </option>
                    <option value="{{ title }}" *ngFor="let title of noOFPrograms;let i = index">
                      {{ title }}
                    </option>
                  </select>
                  <div *ngIf="f.noOfProgram.errors?.required && submitted" class="invalid-feedback d-block"> Program is required </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputPhone">Channel</label>
                  <select id="inputState" class="" formControlName="channelId" [(ngModel)]="programName"  (change)="onChangeChannel($event)" placeholder="Select channel" required>
                    <option class="option" disabled hidden value=""> Select Channel </option>
                    <option value="{{ channel.channelName }}" *ngFor="let channel of channelNames;let i = index" 
                    
                    >
                      {{ channel.channelName }}
                    </option>
                  </select>
                  <div *ngIf="f.channelId.errors?.required && submitted" class="invalid-feedback d-block"> Please select channel </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputPhone">Amount</label>
                  <div class="input-wrapper-1 input-wrapper-amount">
                    <span class="prefix">₹</span>
                    <input type="text" class="form-control1 amount" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter total amount" autocomplete="off" formControlName="amount" [(ngModel)]="totalAmount" (keyup)="AmountValidations($event)" (keydown)="AmountValidations($event)" ngxTrim="focusout" />
                  </div>
                  <div *ngIf="f.amount.errors?.required && submitted" class="invalid-feedback d-block"> Amount is required </div>
                </div>
              </div>
            </div>
          </form>
          <div class="text-left request-prayer mt-3 mb-4">
            <button class="btn proceed" (click)="proceed()">Proceed</button>
          </div>
        </div>
        <div class="tv-programs-sponsor-section" *ngIf="SponsorDetails">
          <h4>Enter your personal details</h4>
          <!-- <p>Lorem ipsum dolor sit amet, consectetur</p> -->
          <form [formGroup]="tvProgramRequestDetailsForm" class="mt-md-4">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputPhone">Full name</label>
                  <input type="text" class="form-control1" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Full name" autocomplete="off" formControlName="name" (keyup)="AlphabetsOnly($event)" ngxTrim="focusout" />
                  <div *ngIf="e.name.errors?.required && submittedDetails" class="invalid-feedback d-block"> Name is required </div>
                  <!-- <div
                    *ngIf="e.name.errors?.pattern && submittedDetails"
                    class="invalid-feedback d-block"
                  >
                    whitespace is not allowed
                  </div> -->
                </div>
                <!-- <div class="form-group"><label for="exampleInputPhone">Email Address</label><input
                    type="email"
                    class="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Email address"
                    autocomplete="off"
                    formControlName="email"
                    ngxTrim="focusout"
                  /><div
                    *ngIf="e.email.errors?.pattern && submittedDetails"
                    class="invalid-feedback d-block"
                  >
                    Enter valid email
                  </div></div> -->
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputPhone">Phone Number</label>
                  <div class="input-wrapper-1">
                    <span class="prefix">+91</span>
                    <input type="tel" maxlength="10" class="form-control1" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone number" autocomplete="off" (keyup)="numberOnly($event)" formControlName="phone" [(ngModel)]="zerosAll" ngxTrim="focusout" />
                  </div>
                  <div *ngIf="e.phone.errors?.required && submittedDetails" class="invalid-feedback d-block"> Phone number is required </div>
                  <div *ngIf="e.phone.errors?.pattern && submittedDetails" class="invalid-feedback d-block"> Phone number is invalid </div>
                </div>
              </div>
            </div>
            <div class="text-left request-prayer mt-4 mb-4">
              <button class="btn proceed" (click)="ProceedToPayBtn()"> Proceed to Pay </button>
            </div>
          </form>
        </div>
      </div>
      <!-- table-section    -->
      <div class="top-table-wrapper pb-4">
        <div class="form-group-1 mb-3 s-1">
          <label for="">Language</label>
          <select id="inputState" class="table-language" (change)="onChangeTable($event)">
            <option *ngFor="let language of tableDataAfterFilter;let i = index;" [selected]="language == 'Telugu'" value="{{ language }}">
              {{ language }}
            </option>
           
          </select>
        </div>
        <div class="channels-programs-section">
          <div class="table-responsive">
            <table class="table ">
              <thead>
                <tr>
                  <th scope="col">channels</th>
                  <th scope="col">sunday</th>
                  <th scope="col">Monday</th>
                  <th scope="col">tuesday</th>
                  <th scope="col">wednesday</th>
                  <th scope="col">thursday</th>
                  <th scope="col">friday</th>
                  <th scope="col">saturday</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tabelTimingDetails">
                
                <tr>
                  
                  <td>
                    <div class="d-flex">
                      <!-- src="../../assets/images/maa-tv.svg" -->
                      <img [src]="table.tvChannelId.icon | safePipe" class="table-channles-icons_img rounded-circleks" />
                      <h3 [class.nomarginSinhalese]="table.tvChannelId.channelName  == 'Sinhalese'">{{ table.tvChannelId.channelName }}</h3>
                    </div>
                  </td>
                  <td>
                    {{ table.scheduled.sunday[0]?.time
                      ? table.scheduled.sunday[0]?.time
                      : ""
                  }}
                    <h5 *ngIf="table.scheduled.sunday[0]?.time == null">-</h5>
                  </td>
                  <td>
                    {{ table.scheduled.monday[0]?.time
                      ? table.scheduled.monday[0]?.time
                      : ""
                  }}
                    <h5 *ngIf="table.scheduled.monday[0]?.time == null">-</h5>
                  </td>
                  <td>
                    {{ table.scheduled.tuesday[0]?.time
                      ? table.scheduled.tuesday[0]?.time
                      : ""
                  }}
                    <h5 *ngIf="table.scheduled.tuesday[0]?.time == null">-</h5>
                  </td>
                  <td>
                    {{ table.scheduled.wednesday[0]?.time
                      ? table.scheduled.wednesday[0]?.time
                      : ""
                  }}
                    <h5 *ngIf="table.scheduled.wednesday[0]?.time == null">-</h5>
                  </td>
                  <td>
                    {{ table.scheduled.thursday[0]?.time
                      ? table.scheduled.thursday[0]?.time
                      : ""
                  }}
                    <h5 *ngIf="table.scheduled.thursday[0]?.time == null">-</h5>
                  </td>
                  <td>
                    {{ table.scheduled.friday[0]?.time
                      ? table.scheduled.friday[0]?.time
                      : ""
                  }}
                    <h5 *ngIf="table.scheduled.friday[0]?.time == null">-</h5>
                  </td>
                  <td>
                    {{ table.scheduled.saturday[0]?.time
                      ? table.scheduled.saturday[0]?.time
                      : ""
                  }}
                    <h5 *ngIf="table.scheduled.saturday[0]?.time == null">-</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- tv-programs-form-section -->
    </div>
  </div>
  <app-footer></app-footer>
</div>