import { Directive } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appNotallzeros]',
})
export class NotallzerosDirective {
  notAllZeros(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (/^0+$/.test(value)) {
      return { allZeros: true };
    }
    return null;
  }
}
