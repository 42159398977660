<div class="main">
  <div class="container-fluid">
    <div class="congrats">
      <img src="../../assets/images/thank.gif" class="success_Img" />
    </div>
     <div class="thank_you-section">
      <h4>Thankyou!</h4>
      <p>
        Your response has been submitted. Someone from Church team will reach out to you.
      </p>
      </div>
      <!-- <div class="message mt-3">
        <div class="d-flex">
            <img src="../../../assets/images/m-1.svg" class="mr-2"> 
            <h5>Here is a wonderful message from bro, Satish Kumar on “How to overcome weakness” go ahead and watch</h5>
        </div>
      </div> -->
      <!-- <div class="mt-2">
        <img src="../../assets/images/contact-us.png" class="message-img">
      </div> -->
      <div class="close-btn mt-5 mb-4">
        <button class="btn btn-close" (click)="close()">Close</button>
      </div>
    </div>
    </div>
