import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderService } from '../_services/loader.service';
import { PrayerRequestService } from '../_services/prayer-request.service';
import { SuccessdialogContactComponent } from './successdialog-contact/successdialog-contact.component';
import { MixpanelService } from '../_services/mixpanel.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css'],
})
export class ContactusComponent implements OnInit {
  fileName: any = 'null';
  attachmentFile: boolean = false;
  isclicked:boolean=false;
  uploadFlie: boolean = true;
  public getScreenWidth: any;
  public getScreenHeight: any;
  contactUsForm: FormGroup;
  raiseAnIssue: any;
  submitted: boolean = false;
  attachmentFilePdf: any;
  selectedFile: any = 'null';
  issue = [
    { key: 'Website', value: 'website' },
    { key: 'Android', value: 'android' },
    { key: 'iOS', value: 'iOS' },
    { key: 'General', value: 'general' },
  ];
  raiseAnIssueId: any;
  typeOfBug: any;
  url: string;
  loaderShow: boolean;
  errorLength: boolean = false;
  origin: any = 'web';

  constructor(
    public loader: LoaderService,
    private router: Router,
    public dialog: MatDialog,
    private services: PrayerRequestService,
    private fb: FormBuilder,
    private mixpanelService: MixpanelService
  ) {
    this.loader.loading.subscribe((res) => {
      this.loaderShow = res;
    });
    this.contactUsForm = this.fb.group({
      name: ['', Validators.required],
      email: [
        '',
        [Validators.required,
        Validators.pattern(
          '^[\\w]+(?:\\.[\\w])*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$'
        ),
        ],
      ],
      phone: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      reason: ['', Validators.required],
      typeOfBug: ['', Validators.required],
      description: ['', Validators.required],
      attachment: [''],
    });
  }

  ngOnInit(): void {
    this.trackEvent()
    this.services.contactUsRaiseAnIssue().subscribe((response) => {
      console.log(response);
      this.raiseAnIssue = response.data;
    });
  }
  trackEvent() {
    this.mixpanelService.track('Raise Issue Screen', { /* optional event properties */ });
  }
  raiseAnissue(event: any) {
    console.log(event.value);
    this.raiseAnIssueId = event.value;
  }
  typeOfBugIssue(event: any) {
    console.log(event.value);
    this.typeOfBug = event.value;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    console.log(this.getScreenWidth, this.getScreenHeight);
  }
  // errors message controls
  get f(): { [key: string]: AbstractControl } {
    return this.contactUsForm.controls;
  }
  back() {
    this.router.navigate(['/Home']);
  }
  onFileSelected(event: any): void {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      // this.msg = '';
      this.url = reader.result as string;
      // console.log(event.target.files[0].name);
      // console.log(this.url);
      this.selectedFile = <File>event.target.files[0];
    };
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.contactUsForm.get('attachment').setValue(file);
      console.log(this.contactUsForm.get('attachment').value);
    }
    // this.selectedFile = <File>event.target.files[0];
    this.fileName = event.target.files[0].name;
    if (event.target.files[0].name != null) {
      this.uploadFlie = false;
      this.attachmentFile = true;
    }
  }
  close() {
    this.uploadFlie = true;
    this.attachmentFile = false;
  }
  prayerRequestValidation(event: any) {
    let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
    console.log('alphabets', newValue);
    this.contactUsForm.controls['typeOfBug'].setValue(newValue);
  }
  contactUsDesc(event: any) {
    // let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
    // console.log('alphabets', newValue);
    // this.contactUsForm.controls['description'].setValue(newValue);
    let wordLength = event.target.value.split(' ');

    if (wordLength.length > 601) {
      this.errorLength = true;
      wordLength.length = 600;
      event.target.value = wordLength.join(' ');
    } else {
      this.errorLength = false;
    }
  }
  submit() {
    if (!this.isclicked) {
      this.isclicked = true;
      if (this.contactUsForm.value.typeOfBug == '') {
        this.contactUsForm.controls.typeOfBug.setValue('Website');
      }
      if (this.contactUsForm.invalid) {
        this.submitted = true;
      } else {
        console.log(this.contactUsForm.invalid);
        this.loaderShow = true;
        this.submitted = false;
        const formData = new FormData();
        formData.append('attachment', this.contactUsForm.get('attachment').value);
        formData.append('name', this.contactUsForm.value.name);
        formData.append('email', this.contactUsForm.value.email);
        formData.append('phone', this.contactUsForm.value.phone);
        formData.append('reason', this.raiseAnIssueId);
        formData.append('typeOfBug', this.contactUsForm.value.typeOfBug);
        formData.append('origin', 'web');
        formData.append(
          'description',
          this.contactUsForm.value.description.trim()
        );
        this.services.contactUs(formData).subscribe(
          (res) => {
            this.uploadFlie = true;
            this.attachmentFile = false;
            this.contactUsForm.get('attachment').reset();
            this.contactUsForm.get('email').reset();
            this.contactUsForm.get('phone').reset();
            this.contactUsForm.get('reason').reset();
            this.contactUsForm.reset({
              typeOfBug: 'website'
            });
            this.contactUsForm.get('description').reset();
            setTimeout(() => {
              this.loaderShow = false;
              this.dialog.open(SuccessdialogContactComponent, {
                disableClose: true,
              });
              this.mixpanelService.track('Raise Issue Success', { 'Bug Type': this.contactUsForm.value.typeOfBug });
            }, 1000);
          },
          (error) => {
            console.log('error', error);
          }
        );
      }
      setTimeout(() => {
        this.isclicked = false;
      }, 1500);
    }
  }
  numberOnly(event: any) {
    let newValue = event.target.value.replace(/[^0-9]/g, '');
    console.log('alphabets', newValue);
    this.contactUsForm.controls['phone'].setValue(newValue);
  }
  // uploadFile() {}
}
