import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare const aboutpage: any;
declare var $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  orderby: any;
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    //navbar-toggle-collapse
    $('.js-scroll-trigger').click(function () {
      $('.navbar-collapse').collapse('hide');
      console.log('navbar');
    });
  }

  about() {
    aboutpage();
  }
  watch() {
    window.scroll(0, 0);
  }
}
