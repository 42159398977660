import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learmore',
  templateUrl: './learmore.component.html',
  styleUrls: ['./learmore.component.css']
})
export class LearmoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
