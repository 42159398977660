import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-testimonties-slider',
  templateUrl: './testimonties-slider.component.html',
  styleUrls: ['./testimonties-slider.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TestimontiesSliderComponent implements OnInit {
  constructor() {}
  swiperConfig: any = {
    spaceBetween: 20,
    loop: 'true',
    autoplay: {
      delay: 1000,
    },
    breakpoints: {
      576: {
        autoplay: {
          enabled: true,
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
      },
      578: {
        slidesPerView: 2,
      },
      950: {
        slidesPerView: 3,
      },
    },
  };
  ngOnInit(): void {}
}
