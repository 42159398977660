<div class="main">
  <app-navbar class="navbar-request"></app-navbar>
  <div class="container">
    <div class="prayers-request mt-2">
      <div (click)="back()" class="back-section back-prayer ml-0">
        <img
          src="../../assets/images/arrow-left.svg"
          class="arrow-left_img cursor"
        />
        <button class="btn back-btn" type="button">Back</button>
        <hr class="back-span" />
      </div>
      <!-- <div class="back">
        <h3>Back</h3>
      </div> -->
      <!-- <div class="" *ngIf="errorMessage != null">
        <h4>{{ errorMessage }}</h4>
      </div> -->
      <div class="prayer-request-header">
        <h4>
          Pray Request: <span>{{ titleName }} </span>
        </h4>
        <p>
          Please tell us your problem & get a call back from our prayer team.
        </p>
      </div>
    </div>
    <div clas="form-section mb-4">
      <form [formGroup]="prayerRequestForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputName">Full Name</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputName"
                aria-describedby="emailHelp"
                placeholder="Full Name"
                autocomplete="off"
                formControlName="name"
                ngxTrim="focusout"
                (keyup)="nameValidations($event)"
                pattern="\S(.*\S)?"
              />
              <div
                *ngIf="f.name.errors?.required && submitted"
                class="invalid-feedback d-block"
              >
                Name is required
              </div>
              <div
                *ngIf="f.name.errors?.pattern && submitted"
                class="invalid-feedback d-block"
              >
                White Space is not allowed
              </div>
              <!-- <div
                *ngIf="f.name.errors?.pattern && submitted"
                class="invalid-feedback d-block"
              >
                Invalid 
              </div> -->
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group select-request">
              <label for="exampleInputLastName">Prayer For Whom</label>

              <select id="inputState" formControlName="prayerForWhomId" required>
                <option value="" selected disabled hidden>
                  Prayer for Whom
                </option>
                <option
                  *ngFor="let prayerforwhom of prayerForWhom"
                  value="{{ prayerforwhom._id }}"
                >
                  {{ prayerforwhom.name }}
                </option>
              </select>

              <div
                *ngIf="f.prayerForWhomId.errors?.required && submitted"
                class="invalid-feedback d-block"
              >
                Prayer for whom required
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputCity">City</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="City"
                autocomplete="off"
                (keyup)="placeValidations($event)"
                (keydown.space)="$event.preventDefault()"
                formControlName="place"
              />
              <div
                *ngIf="f.place.errors?.required && submitted"
                class="invalid-feedback d-block"
              >
                City is required
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="exampleInputEmail1">Email Address</label>
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Email Address"
                autocomplete="off"
                formControlName="email"
              />

              <div
                *ngIf="f.email.errors?.pattern && submitted"
                class="invalid-feedback d-block"
              >
                Enter valid email
              </div>
            </div> -->
          </div>
          <div class="col-md-6 mt-md-4">
            <div class="form-group phone">
              <label for="exampleInputPhone">Phone Number</label>
              <div class="input-wrapper-1">
 <span class="prefix">+91</span>
             
              <input
                type="tel"
                maxlength="10"
                class="form-control"
                id="Amount"
                aria-describedby="emailHelp"
                placeholder="Phone Number"
                autocomplete="off"
                [(ngModel)]="zerosAll"
                formControlName="phone"
                (keyup)="numberOnly($event)"
              />
              </div>
              <div
                *ngIf="f.phone.errors?.required && submitted"
                class="invalid-feedback d-block"
              >
                Phone number is required
              </div>
              <div
                *ngIf="f.phone.errors?.pattern && submitted"
                class="invalid-feedback d-block"
              >
                Phone number is invalid
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group select-request">
              <label for="exampleInputCity">Select Language</label>

              <select id="inputState" formControlName="language" required>
                <option value="" selected disabled hidden>
                  Select Language
                </option>
                <option *ngFor="let values of Languages" value="{{values.key}}">{{values.value}}</option>
              </select>

              <div
                *ngIf="f.language.errors?.required && submitted"
                class="invalid-feedback d-block"
              >
                Please select the language
              </div>
            </div>
            <!-- <div class="form-group">
              <label for="exampleInputCity">City</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="City"
                autocomplete="off"
                (keypress)="AlphabetsOnly($event)"
                (keydown.space)="$event.preventDefault()"
                formControlName="place"
              />
              <div
                *ngIf="f.place.errors?.required && submitted"
                class="invalid-feedback d-block"
              >
                City is required
              </div>
            </div> -->
          </div>
          <div class="col-md-6">
             <!-- <div class="radio-button-section  mt-1">
       <div class="issue_header">
        <h5>Where did you face this issue?</h5>
       </div>
       <mat-radio-group formControlName="origin" (change)="typeOfBugIssue($event)">
           <mat-radio-button *ngFor="let type of issue" [disableRipple]="true" [checked]="type.key === 'Website'"  value="{{type.value}}"  name="Website" class="mr-3">{{type.key}}</mat-radio-button>
            
         </mat-radio-group>
     </div> -->
            <!-- <div class="form-group select-request">
              <label for="exampleInputCity">Select Language</label>

              <select id="inputState" formControlName="language" required>
                <option value="" selected disabled hidden>
                  Select Language
                </option>
                <option value="Telugu">Telugu</option>
                <option value="English">English</option>
              </select>

              <div
                *ngIf="f.language.errors?.required && submitted"
                class="invalid-feedback d-block"
              >
                Please select the language
              </div>
            </div> -->
          </div>
        </div>
        <div class="form-group">
          <label for="exampleInputCity">Prayer Request</label>

          <textarea
            style="resize: none"
            class="text-area text-option"
            placeholder="Enter Prayer Request in (600 words)"
            id="exampleFormControlTextarea1"
            (keyup)="prayerRequestValidation($event)"
            formControlName="description"
            rows="6"
            ngxTrim="focusout"
          
            formControlName="description"
          ></textarea>
          <div
            *ngIf="f.description.errors?.required && submitted"
            class="invalid-feedback d-block"
          >
            Please enter Prayer request
          </div>
          <div
            *ngIf="errorLength"
            class="invalid-feedback d-block"
          >
          Maximum word limit for the description is 600 words.
          </div>
           
        </div>
        <div class="request-prayer mb-5 mt-4">
          <button type="submit" (click)="submit()" class="btn">Submit</button>
        </div>
      </form>
    </div>
  </div>

  <app-footer></app-footer>
</div>
