<div class="download-section">
  <div class="container">
    <div class="temple-app">
      <div class="row">
        <div class="col-md-6">
          <div class="download-app-section">
            <h4>Download the Calvary Temple App</h4>
            <p>
              With the app from Calvary Temple, you can view live services,
              catch up on recent messages and learn more about upcoming events.
              Plus, you’ll know who’s speaking next week, and can give without
              pulling out your wallet.
            </p>
          </div>
          <div class="play-store-section">
            <p>Get the App</p>
            <div class="app-store-images d-flex">
              <a href="https://play.google.com/store/apps/details?id=com.calvarytemple" target="_blank">
                <img src="../../assets/images/play-1.svg" class="app_store cursor" />
              </a>
              <a href="https://apps.apple.com/in/app/calvary-temple-india/id1661280048" target="_blank">
                <img src="../../assets/images/play-2.svg" class="app_store cursor" />
              </a>

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="image_moblie mt-5">
            <!-- src="../../assets/images/download-app-img.png" -->
            <img src="../../assets/images/Calvary Temple (9)/Group 1000004560@3x.png" class="app_download" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer class="footer_section">
  <div class="container">
    <div class="row py-4">
      <div class="col-md-4 mb-4 col-lg-4">
        <div class="d-flex">
          <a class="mr-0 w-100" href="#">
            <!-- src="../../assets/images/footer-logo.svg" -->
            <img src="../../assets/images/Calvary Temple (9)/Group 1000004517@3x.png" class="header-logo" width="50%" />
          </a>
        </div>
        <p class="thank_you mt-4 mb-md-1">
          Thankyou for your generosity. Having trouble giving?
        </p>
        <p class="click_here">
          <a class="cursor c" routerLink="/make-a-donation">Click here to give</a> or call 040 6645 4545
        </p>
      </div>
      <div class="col-md-3 mb-4 col col-lg-3">
        <div class="">
          <h6 class="mb-md-4">Watch</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-md-2">
              <a href="#" routerLink="/Watch" class="">Live Stream</a>
            </li>
          </ul>
        </div>
        <div class="">
          <!-- <h6 class="font-weight-bold mb-4 mt-5">Visit</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-3">
              <a href="#" class="">Calvary Temple Experience</a>
            </li>
            <li class="mb-3">
              <a href="#" class="">Location / Directions</a>
            </li>
            <li class="mb-3">
              <a href="#" class="">Time / Speaking Schedule</a>
            </li>
          </ul> -->
        </div>
        <div>
          <h6 class="font-weight-bold mb-md-4 mt-4">Connect</h6>
          <ul class="list-unstyled mb-0">
            <li class="mb-3">
              <a href="#" routerLink="/make-a-donation" class="">Give</a>
            </li>
            <!-- <li class="mb-3"><a href="#" class="">Missions</a></li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-3 mb-4 col col-lg-3">
        <h6 class="font-weight-bold mb-md-4">Download</h6>
        <ul class="list-unstyled mb-0">
          <li class="mb-2"><a href="https://apps.apple.com/in/app/calvary-temple-india/id1661280048" target="_blank" class="">iOS App</a></li>
          <li class="mb-2"><a href="https://play.google.com/store/apps/details?id=com.calvarytemple" target="_blank" class="">Android App</a></li>
        </ul>

        <!-- <h6 class="font-weight-bold mb-4 mt-5">Grow</h6>
        <ul class="list-unstyled mb-0">
          <li class="mb-3">
            <a href="#" class="">New Beginnings</a>
          </li>
          <li class="mb-3"><a href="#" class="">Kids Life</a></li>
          <li class="mb-3"><a href="#" class="">Marriage</a></li>
          <li class="mb-3"><a href="#" class="">Next Gen</a></li>
          <li class="mb-3">
            <a href="#" class="">Classes & Events</a>
          </li>
          <li class="mb-3"><a href="#" class="">Espanol</a></li>
        </ul> -->
      </div>
      <div class="col-md-2 col-lg-2">
        <h6 class="font-weight-bold mb-md-4">About</h6>
        <ul class="list-unstyled mb-0">
          <li class="mb-3">
            <a href="#" routerLink="/sponsor-a-tv-program" class="">Television Listings</a>
          </li>
          <li class="mb-3">
            <a href="#" class="" routerLink="/Privacy-Policy">Privacy Policy</a>
          </li>
          <li class="mb-3">
            <a href="#" class="" routerLink="/Term-Of-Service">Terms Of Service</a>
          </li>
          <li class="mb-3">
            <a href="#" class="" routerLink="/Payment-Policy">Payment Policy</a>
          </li>
        </ul>
        <!-- <h6 class="font-weight-bold mb-4">About</h6>
        <ul class="list-unstyled mb-0">
          <li class="mb-3"><a href="#" class="">Our History</a></li>
          <li class="mb-3"><a href="#" class="">Contact Us</a></li>
          <li class="mb-3">
            <a href="#" routerLink="/Tv-programs" class=""
              >Television Listings</a
            >
          </li>
          <li class="mb-3">
            <a href="#" class="" routerLink="/Privacy-policy"
              >Privacy Policy</a
            >
          </li>
          <li class="mb-3">
            <a href="#" class="" routerLink="/Term-of-use"
              >Terms of Use</a
            >
          </li>
          <li class="mb-3">
            <a href="#" class="">Careers</a>
          </li>
        </ul> -->
        <!-- <h6 class="font-weight-bold mb-4 mt-5">Store</h6>
        <ul class="list-unstyled mb-0">
          <li class="mb-3"><a href="#" class="">My Account</a></li>

          <li class="mb-3"><a href="#" class="">Shopping Cart</a></li>
        </ul> -->
      </div>
    </div>

    <div class="row flex-column-reverse flex-md-row border_row">
      <div class="col-sm-6 col-md-6 col-lg-9 col-xxl-8 box">
        <ul class="list-inline quick-links">
          <li class="list-inline-item text-center mb-2">
            <span class="copyright quick-links pr-md-3">
              ©Calvary Temple {{ Date }}
            </span>
          </li>

          <li class="list-inline-item pr-md-4 pr-lg-4 mt-sm-3 mt-md-3">
            <a href="#" routerLink="/Term-Of-Service">Terms
              Of Service </a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="#" routerLink="/Privacy-Policy">Privacy Policy</a>
          </li>
          <li class="list-inline-item pr-md-4 mb-sm-3">
            <a href="#" routerLink="/Disclaimer-Policy">Disclaimer Policy</a>
          </li>
        </ul>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3 col-xxl-4 box">
        <ul class="list-inline social-buttons">
          <li class="list-inline-item">
            <a href="https://twitter.com/CALVARYTEMPLE3" target="_blank">
              <img src="../../assets/images/t-11.svg" />
              <!-- <i class="fa fa-twitter" aria-hidden="true"></i> -->
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.youtube.com/channel/UCYaE-blRyiy300gLC-yDRUQ" target="blank"><i class=""><img
                  src="../../assets/images/tv-5.svg" /></i></a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.instagram.com/calvarytempleindia" target="_blank">
              <img src="../../assets/images/t-13.svg" />
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.facebook.com/DrSatishKumar.Facebook" target="_blank">
              <img src="../../assets/images/t-14.svg" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>