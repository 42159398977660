import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ObserversModule } from '@angular/cdk/observers';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class PrayerRequestService {
  url = `${environment.API_URL}v1/`;

  constructor(private http: HttpClient) {}

  prayerRequest(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    return this.http
      .get<any>(`${environment.API_URL}v1/prayer/`, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  prayerForWhom() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    return this.http
      .get<any>(
        `${environment.API_URL}v1/prayer-for-whom/
`,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  prayerRequestBanner(params: any) {
    const url = `${environment.API_URL}v1/banner/`;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('visibleOn', params);
    return this.http.get<any>(url, { params: queryParams }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      })
    );
  }

  prayerRequestDetails(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'multipart/form-data',
      }),
    };
    return this.http
      .post<any>(`${environment.API_URL}v1/prayer/request`, data, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  TempleTimings(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    https: return this.http
      .get<any>(
        `${environment.API_URL}v1/membership-program/temple/`,
        httpOptions
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  // Watch API
  WatchVideoRequest() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    return this.http
      .get<any>(`${environment.API_URL}v1/message/`, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  WatchVideoRequestFliter(params: any) {
    const url = `${environment.API_URL}v1/message/`;
    return this.http.get<any>(url, { params: params }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      })
    );
  }
  WatchSpeakerName() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    return this.http
      .get<any>(`${environment.API_URL}v1/message/speakerName`, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  WatchTopicList() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    return this.http
      .get<any>(`${environment.API_URL}v1/message/topic`, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  // Tv-Programs API

  tvProgramsRequest(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    return this.http
      .post<any>(`${environment.API_URL}v1/tv-program/`, data, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  tvProgramRequestTableData() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    return this.http
      .get<any>(`${environment.API_URL}v1/tv-program/`, httpOptions)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  tvProgramRequestChannel(params: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    const url = `${environment.API_URL}v1/tv-channel/`;
    return this.http.get<any>(url + params, httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  tvProgramScheduleRequest() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    const url = `${environment.API_URL}v1/tv-program/scheduled`;
    return this.http.get<any>(url, httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  // PAYMENT-FLOW
  donateQrCode() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    const url = `${environment.API_URL}v1/payment/QR`;
    return this.http.get<any>(url, httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  donateTitle() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    const url = `${environment.API_URL}v1/donate`;
    return this.http.get<any>(url, httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  contactUsRaiseAnIssue() {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    const url = `${environment.API_URL}v1/raise-an-issue`;
    return this.http.get<any>(url, httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  contactUs(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    const url = `${environment.API_URL}v1/raise-an-issue`;
    return this.http.post<any>(url, data, httpOptions).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  createOrder(order: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        contentType: 'application/json',
      }),
    };
    const url = `${environment.API_URL}v1/payment/order`;
    return this.http.post(
      url,
      {
        amount: order.amount,
        type: order.type,
        donationId: order.donationId,
        tvProgramId: order.tvProgramId,
        phoneNumber: order.phoneNumber,
        name: order.name,
        noOfProgram: order.noOfProgram,
        channelId: order.channelId,
        origin: 'website',
      },
      httpOptions
    );
  }
}
