import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { MixpanelService } from 'src/app/_services/mixpanel.service';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css'],
})
export class SuccessDialogComponent implements OnInit {
  GreetingCardName: any;
  details: any;
  Services: any = 'Service';
  title: any;
  Sponsor: any;
  SponsorDetails: any;
  data: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private Service: PrayerRequestService,
    private ngZone: NgZone,
    private mixpanelService: MixpanelService,
    @Inject(MAT_DIALOG_DATA) data: any,

  ) {
    this.data = data;
  }
  options: AnimationOptions = {
    path: '../../assets/images/congrats2.json',
  };
  ngOnInit(): void {
    this.trackEvent()
    this.Service.prayerRequestBanner(this.Services).subscribe(
      (response: any) => {
        console.log(response);
        this.GreetingCardName = response.data.buttonName;
        this.details = response.data.details;
        this.title = response.data.title;
      }
    );
  }
  trackEvent() {
    this.mixpanelService.track('TV Program Payment Success Web', { /* optional event properties */ });
  }
  backToPrograms() {
    // this.ngZone.run(() => this.router.navigateByUrl('/Tv-programs')).then();
    window.location.reload();
    this.dialog.closeAll();
  }
}
