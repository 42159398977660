import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material-module';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { FAQComponent } from './faq/faq.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { PrayersrequestComponent } from './prayersrequest/prayersrequest.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SwiperModule } from 'swiper/angular';
import { TvProgramsComponent } from './tv-programs/tv-programs.component';
import { TvProgramPaymentGatewayComponent } from './tv-programs/tv-program-payment-gateway/tv-program-payment-gateway.component';
import { SuccessDialogComponent } from './tv-programs/tv-program-payment-gateway/success-dialog/success-dialog.component';
import { DonateComponent } from './donate/donate.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PrayerRequestDetailsComponent } from './prayersrequest/prayer-request-details/prayer-request-details.component';
import { PrayerRequestSuccessDialogComponent } from './prayersrequest/prayer-request-details/prayer-request-success-dialog/prayer-request-success-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WatchComponent } from './watch/watch.component';
import { WatchVideoComponent } from './watch/watch-video/watch-video.component';
import { DonatePaymentGatewayComponent } from './donate/donate-payment-gateway/donate-payment-gateway.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { TestimontiesSliderComponent } from './home-page/testimonties-slider/testimonties-slider.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
export function playerFactory() {
  return player;
}

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { DatePipe } from '@angular/common';
import { SuccessDialogDonateComponent } from './donate/donate-payment-gateway/success-dialog-donate/success-dialog-donate.component';
import { SafePipePipe } from './_helpers/safe-pipe.pipe';
import { UniquePipe } from './_helpers/unquie.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LearmoreComponent } from './donate/learmore/learmore.component';
import { ShareDialogComponent } from './watch/watch-video/share-dialog/share-dialog.component';
import { DateFormat } from './_modal/date-formater';
import { DateAdapter } from '@angular/material/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MobileSuccessViewComponent } from './prayersrequest/prayer-request-details/mobile-success-view/mobile-success-view.component';
import { MobileSuccessViewDonateComponent } from './donate/donate-payment-gateway/mobile-success-view-donate/mobile-success-view-donate.component';
import { MobileSuccessViewTvMinistryComponent } from './tv-programs/tv-program-payment-gateway/mobile-success-view-tv-ministry/mobile-success-view-tv-ministry.component';
import { ShareVideoDialogComponent } from './home-page/share-video-dialog/share-video-dialog.component';
import { ShareLinkDialogComponent } from './home-page/share-link-dialog/share-link-dialog.component';
import { NgxTrimModule } from 'ngx-trim';
import { StrictNumberOnlyDirective } from './_helpers/strictNumberOnlyDirective';
// import { MatDialogRef, M } from '@angular/material/dialog';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { DisclamerPolicyComponent } from './disclamer-policy/disclamer-policy.component';
import { ContactusComponent } from './contactus/contactus.component';
import { SuccessdialogContactComponent } from './contactus/successdialog-contact/successdialog-contact.component';
import { SuccessdialogContactmobileComponent } from './contactus/successdialog-contactmobile/successdialog-contactmobile.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { NotallzerosDirective } from './_helpers/notallzeros.directive';
import { LoaderInterceptor } from './_services/loader.interceptor';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    FAQComponent,
    PrayersrequestComponent,
    AboutUsComponent,
    HomePageComponent,
    TvProgramsComponent,
    TvProgramPaymentGatewayComponent,
    SuccessDialogComponent,
    DonateComponent,
    PrayerRequestDetailsComponent,
    PrayerRequestSuccessDialogComponent,
    WatchComponent,
    WatchVideoComponent,
    DonatePaymentGatewayComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    CookiePolicyComponent,
    TestimontiesSliderComponent,
    SuccessDialogDonateComponent,
    SafePipePipe,
    UniquePipe,
    LearmoreComponent,
    ShareDialogComponent,
    MobileSuccessViewComponent,
    MobileSuccessViewDonateComponent,
    MobileSuccessViewTvMinistryComponent,
    ShareVideoDialogComponent,
    ShareLinkDialogComponent,
    StrictNumberOnlyDirective,
    DisclamerPolicyComponent,
    ContactusComponent,
    SuccessdialogContactComponent,
    SuccessdialogContactmobileComponent,
    PagenotfoundComponent,
    NotallzerosDirective,
  ],
  imports: [
    BrowserModule,
    RxReactiveFormsModule,
    NgxTrimModule,
    AppRoutingModule,
    FormsModule,
    MaterialModule,
    NoopAnimationsModule,
    CarouselModule,
    SwiperModule,
    HttpClientModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    Ng2TelInputModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: DateFormat },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    dateAdapter.setLocale('en-in'); // DD/MM/YYYY
  }
}
