<div class="main">
  <app-navbar class="navbar-request"></app-navbar>
  <!-- <button mat-button (click)="openDialog()">Open dialog</button> -->
  <div class="container">
    <div class="web-view-tv-ministry">
      <div class="back-section ml-0 mt-3" (click)="back()">
        <img
          src="../../assets/images/arrow-left.svg"
          class="arrow-left_img cursor"
        />
        <button class="btn back-btn" type="button">Back</button>
      </div>
    </div>
    <div class="mobile-view-tv-ministry">
      <div class="d-flex">
        <div class="back-section ml-0 mt-3 mr-auto p-2" (click)="back()">
          <img
            src="../../assets/images/arrow-left.svg"
            class="arrow-left_img cursor"
          />
          <button class="btn back-btn" type="button">Payment</button>
        </div>
        <div class="scan_qr-1 ml-auto p-2">
          <button type="button" class="scan_btn btn Scan-Code-moblie">
            <img
              src="../../assets/images/scan-1.svg"
              class="arrow-left_img cursor"
            />
            Scan QR Code
          </button>
        </div>
      </div>
      <hr class="back-span-1" />
    </div>

    <div class="payment_name_section d-flex">
      <img src="../../assets/images/sbi.svg" class="sbi_img" />
      <div class="payment_bank_name ml-3" id="tv-ministry-scan">
        <h5>Paying to:</h5>
        <h6>Calvary Temple Foundation</h6>
      </div>

      <div class="scan_qr ml-auto p-2">
        <button type="button" class="scan_btn btn Scan-Code">
          Scan QR Code
        </button>
      </div>
    </div>

    <div class="payment_selection_methods_section">
      <div class="payment_selection_name">
        <h5>Choose a payment method</h5>
      </div>
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header class="disable_ripple">
            <div class="net_banking d-flex">
              <i class="card-1" aria-hidden="true"
                ><img src="../../assets/images/card-2.svg"
              /></i>
              <div class="net_banking_selection">
                <h5>Netbanking</h5>
                <h6>All indian banks</h6>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header class="disable_ripple">
            <div class="net_banking d-flex">
              <i class="card-1" aria-hidden="true"
                ><img src="../../assets/images/card-1.svg"
              /></i>
              <div class="net_banking_selection">
                <h5>Card</h5>
                <h6>Visa, Mastercard, RuPay, and more</h6>
              </div>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header class="disable_ripple">
            <div class="net_banking d-flex">
              <img src="../../assets/images/upi-1.svg" class="upi" />
              <div class="net_banking_selection">
                <h5>UPI</h5>
                <h6>Google Pay, PhonePe, and more</h6>
              </div>
            </div>
          </mat-expansion-panel-header>
          <!-- <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
            > -->
          <div class="online_payment-section">
            <!-- <mat-radio-button
                  class="example-radio-button"
                  [checked]="false"
                > -->
            <input
              class="form-check-input mr-3"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
            />
            <div class="d-flex online_payment-btns ml-4">
              <img src="../../assets/images/p-11.svg" class="phone_pe_icons" />
              <label for="exampleRadios1"> <h4>PhonePe</h4></label>
            </div>
            <!-- </mat-radio-button> -->
          </div>
          <div class="online_payment-section">
            <!-- <mat-radio-button class="example-radio-button"> -->
            <input
              class="form-check-input mr-3"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option1"
            />
            <div class="d-flex online_payment-btns ml-4">
              <img src="../../assets/images/p-22.svg" class="phone_pe_icons" />

              <label for="exampleRadios2"> <h4>Google Pay</h4></label>
            </div>
            <!-- </mat-radio-button> -->
          </div>
          <div class="online_payment-section">
            <!-- <mat-radio-button
                  class="example-radio-button"
                  [checked]="false"
                > -->
            <input
              class="form-check-input mr-3"
              type="radio"
              name="exampleRadios"
              id="exampleRadios3"
              value="option1"
            />
            <div class="d-flex online_payment-btns ml-4">
              <img src="../../assets/images/p-33.svg" class="phone_pe_icons" />
              <label for="exampleRadios3"> <h4>PayTm</h4></label>
            </div>
            <!-- </mat-radio-button> -->
          </div>
          <p id="upi_id">Or Enter UPI ID</p>
          <div class="online_payment-section mb-4">
            <input placeholder="Enter UPI Id" />
          </div>
          <!-- </mat-radio-group> -->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="text-left request-prayer">
      <button class="btn proceed" (click)="procced()">Proceed</button>
    </div>
  </div>

  <app-footer></app-footer>
</div>
