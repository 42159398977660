<div class="main">
  <app-navbar></app-navbar>
  <div class="mt-5">
    <div class="video_tag_player">
      <!-- <video
        id="video"
        preload="metadata"
        poster="../../assets/images/v-11.svg"
        controls
      >
       
        <source src="{{ watchLink }}" type="video/mp4" />
      </video> -->
      <iframe width="100%" height="500" [src]="watchLink | safePipe"></iframe>
      <!-- <div class="play-button-wrapper">
          <div title="Play video" class="play-gif" id="circle-play-b">
            <img src="../../assets/images/c-b.svg" />
          </div>
        </div> -->
    </div>

    <div class="player_details container mt-5">
      <h5>{{ watchDetailsTitle }}</h5>

      <div class="d-flex">
        <div class="p-2">
          <img src="../../assets/images/th-1.webp" class="player-1" />
        </div>
        <div class="p-2 details_Section_watch">
          <p>{{ watchDetailsspeakerName }}</p>
          <h5>{{ watchDetailsDate | date: "MMMM d, y" }}</h5>
          <h2>{{watchDetailsDescription}}</h2>
        </div>
        <div class="ml-auto p-2 share_watch cursor" (click)="share()">
          <img src="../../assets/images/send-2.svg" />
          <p>Share</p>
        </div>
      </div>
    </div>
    <hr />
    <div class="container">
      <div class="videos_slider">
        <!-- testimonies_section  -->
        <div class="main_video_header">
          <div class="swiper mySwiperSliderVideo">
            <div class="testimonties_main_section">
              <div class="inspired_main d-flex">
                <div class="recent_messages">
                  <p>Recommended Messages</p>
                </div>
              </div>
            </div>
            <ngx-slick-carousel
              class="carousel"
              #slickModal="slick-carousel"
              [config]="WatchSlideConfig"
            >
              <div
                ngxSlickItem
                *ngFor="let video of watchDetails"
                class="slide"
              >
                <div class="card video_header_tag d-flex">
                  <div class="card-body main_video_slider">
                    <div class="testimonies_section">
                      <div class="video-container" id="video-container">
                        <iframe
                          poster="../../assets/images/v-tag.png"
                          [src]="
                            video.youtubeVideo.replace('watch?v=', 'embed/')
                              | safePipe
                          "
                        ></iframe>
                        <!-- <img
                          src="https://img.youtube.com/vi/oPM2_8xH9KM/hqdefault.webp"
                          class="images-sliders"
                        /> -->
                        <!-- <div class="play-button-wrapper">
                          <div
                            title="Play video"
                            class="play-gif"
                            id="circle-play-b"
                          >
                            <img src="../../assets/images/c-b.svg" />
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <h4 class="mt-3">{{ video.createdAt | date: "MMMM d, y" }}</h4>
                    <h5>{{ video.title }}</h5>
                    <h3 class="mb-2">{{ video.speakerName }}</h3>

                    <div class="d-flex">
                      <a
                        href=""
                        class="mx-0 my-0"
                        routerLink="/Watch/video"
                        [queryParams]="{
                          video: video.youtubeVideo.replace(
                            'watch?v=',
                            'embed/'
                          ),
                          _id: video._id
                        }"
                        >Watch
                      </a>
                      <img src="../../assets/images/ar-l-2.svg" class="pl-1" />
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
