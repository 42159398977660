import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { MixpanelService } from 'src/app/_services/mixpanel.service';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';

@Component({
  selector: 'app-mobile-success-view-donate',
  templateUrl: './mobile-success-view-donate.component.html',
  styleUrls: ['./mobile-success-view-donate.component.css'],
})
export class MobileSuccessViewDonateComponent implements OnInit {
  GreetingCardName: any;
  details: any;
  Services: any = 'Service';
  title: any;
  Amount: any;
  donatePaymentId: string;
  constructor(
    private router: Router,
    private Service: PrayerRequestService,
    private ngZone: NgZone,
    private mixpanelService : MixpanelService
  ) {}
  options: AnimationOptions = {
    path: '../../assets/images/congrats2.json',
  };

  ngOnInit(): void {
    // this.trackEvent()
    this.donatePaymentId = localStorage.getItem('donatePaymentId');
    this.Service.prayerRequestBanner(this.Services).subscribe(
      (response: any) => {
        console.log(response);
        this.GreetingCardName = response.data.buttonName;
        this.details = response.data.details;
        this.title = response.data.title;
      }
    );
    this.Amount = localStorage.getItem('amount');
  }
  // trackEvent() {
  //   this.mixpanelService.track('Give to God Payment Success Web', { /* optional event properties */ });
  // }
  backToDonate() {
    this.router.navigate(['/make-a-donation']);
    // this.ngZone.run(() => this.router.navigateByUrl('/Donate')).then();
    localStorage.removeItem('amount');
    localStorage.removeItem('Donate_id');
    localStorage.removeItem('donatePaymentId');
    // localStorage.removeItem('amount');
    // localStorage.removeItem('Donate_id');
  }
}
