import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';

@Component({
  selector: 'app-tv-program-payment-gateway',
  templateUrl: './tv-program-payment-gateway.component.html',
  styleUrls: ['./tv-program-payment-gateway.component.css'],
})
export class TvProgramPaymentGatewayComponent implements OnInit {
  panelOpenState = false;
  SponsorDetails: any;
  Sponsor: any;
  public getScreenWidth: any;
  public getScreenHeight: any;

  constructor(public dialog: MatDialog, public router: Router) {}

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    console.log(this.getScreenWidth, this.getScreenHeight);
  }
  procced() {
    if (this.getScreenWidth >= 480) {
      this.dialog.open(SuccessDialogComponent, {
        disableClose: true,
      });
    } else {
      this.router.navigate(['/Tv-programs/success']);
    }
  }
  back() {
    this.router.navigate(['/Tv-programs'], {
      queryParams: { SponsorDetails: true },
    });
    this.SponsorDetails = true;
    this.Sponsor = false;
  }
}
