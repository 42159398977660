<div class="main">
  <div class="">
    <div class="main_testimonties-header">
      <div class="container">
        <div class="testimonties_main_section">
          <div class="inspired_main">
            <div class="inspired_testimonties d-flex">
              <img src="../../assets/images/proflie.svg" class="mr-3 test-1" />
              <p>Get inspired from powerful testimonies</p>
            </div>
          </div>
        </div>
        <swiper
          [slidesPerView]="3"
          [spaceBetween]="30"
          [slidesPerGroup]="3"
          [loop]="true"
          [grabCursor]="true"
          [loopFillGroupWithBlank]="true"
          [config]="swiperConfig"
          [navigation]="true"
          class="mySwiperS mt-5"
        >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
          <ng-template swiperSlide>
            <div class="cards-wrapper">
              <div class="card testimonies_header">
                <div class="card-body testimonies_section_main">
                  <div
                    class="testimonies_section d-flex justify-content-center"
                  >
                    <img
                      src="../../assets/images/user-1.svg"
                      alt="test-1"
                      class="testimonies_img"
                    />
                    <h5 class="card-title">Jane Cooper</h5>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor.
                  </p>

                  <div class="read_more-btn">
                    <button type="button" class="btn more_btn">
                      Read more
                    </button>
                  </div>
                </div>
              </div>
            </div></ng-template
          >
        </swiper>
      </div>
    </div>
  </div>
</div>
