<div class="main">
  <div class="container">
    <div class="back-img mt-4" routerLink="/Home">
      <img
        src="../../assets/images/arrow-left.svg"
        class="arrow-left_img cursor"
      />
    </div>
    <div class="congrats">
      <img src="../../assets/images/thank.gif" class="success_Img" />
    </div>
     <div class="thank_you-section">
      <h4>Thankyou!</h4>
      <p>
        Your response has been submitted. Someone from Church team will reach out to you.
      </p>
      </div>
      <div class="message mt-5">
        <div class="d-flex">
            <img src="../../../assets/images/m-1.svg" class="mr-3"> 
            <h5>Here is a wonderful message from bro, Satish Kumar on “How to overcome weakness” go ahead and watch</h5>
        </div>
      </div>
      <div class="mt-2">
        <img src="../../assets/images/contact-us.png" style="max-width: 100%;">
      </div>
      <div class="close-btn mt-5 mb-4">
        <button class="btn btn-close" (click)="close()">Close</button>
      </div>
    </div>
    </div>
