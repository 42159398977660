import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-successdialog-contactmobile',
  templateUrl: './successdialog-contactmobile.component.html',
  styleUrls: ['./successdialog-contactmobile.component.css'],
})
export class SuccessdialogContactmobileComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
  close() {
    this.router.navigate(['/Contact-Us']);
  }
}
