import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PrayerRequestService } from '../_services/prayer-request.service';
import { ShareLinkDialogComponent } from './share-link-dialog/share-link-dialog.component';
import { ShareVideoDialogComponent } from './share-video-dialog/share-video-dialog.component';
//AOS -
declare var $: any;

declare const slider: any;
// import Swiper core and required modules
import SwiperCore, { Pagination } from 'swiper';
import * as Aos from 'aos';
import { MixpanelService } from '../_services/mixpanel.service';

// install Swiper modules
SwiperCore.use([Pagination]);
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  isCurrentWeek: any;
  queryParams: any = {};
  fliteringData: any;
  weekMessge: any;
  message: any;
  youtubeVideo: any;
  public _id: any;
  title: any;
  imagesSlider = [
    '../../assets/images/slider-55.png',
    '../../assets/images/slider-22.png',
    '../../assets/images/slider-33.png',
    '../../assets/images/slider-44.png',
    '../../assets/images/slider-55.png',
    '../../assets/images/slider-55.png',
    '../../assets/images/slider-22.png',
    '../../assets/images/slider-33.png',
    '../../assets/images/slider-44.png',
    '../../assets/images/slider-55.png',
  ];
  KoreaImagesSliders = [
    '../../assets/images/south-africa-1.webp',
    '../../assets/images/south-africa-2.webp',
    '../../assets/images/south-africa-3.webp',
    '../../assets/images/south-africa-4.webp',
    '../../assets/images/south-africa-5.webp',
    '../../assets/images/south-africa-6.webp',
    '../../assets/images/south-africa-7.webp',
    '../../assets/images/south-africa-8.webp',
    '../../assets/images/south-africa-1.webp',
    '../../assets/images/south-africa-2.webp',
    '../../assets/images/south-africa-3.webp',
    '../../assets/images/south-africa-4.webp',
    '../../assets/images/south-africa-5.webp',
    '../../assets/images/south-africa-6.webp',
    '../../assets/images/south-africa-7.webp',
    '../../assets/images/south-africa-8.webp',
  ];
  KoreaImagesSliders1 = [
    '../../assets/images/south-africa-5.webp',
    '../../assets/images/south-africa-4.webp',
    '../../assets/images/south-africa-5.webp',
    '../../assets/images/south-africa-6.webp',
    '../../assets/images/south-africa-7.webp',
    '../../assets/images/south-africa-8.webp',
    '../../assets/images/south-africa-1.webp',
    '../../assets/images/south-africa-2.webp',
    '../../assets/images/south-africa-3.webp',
    '../../assets/images/south-africa-4.webp',
    '../../assets/images/south-africa-6.webp',
    '../../assets/images/south-africa-7.webp',
    '../../assets/images/south-africa-8.webp',
    '../../assets/images/south-africa-1.webp',
    '../../assets/images/south-africa-2.webp',
    '../../assets/images/south-africa-3.webp',
  ];
  AsianLeaderImagesSliders = [
    '../../assets/images/asian-summit-14.webp',
    '../../assets/images/asian-summit-15.webp',
    '../../assets/images/asian-summit-16.webp',
    '../../assets/images/asian-summit-17.webp',
    '../../assets/images/asian-summit-18.webp',
    '../../assets/images/asian-summit-19.webp',
    '../../assets/images/asian-summit-20.webp',
    '../../assets/images/asian-summit-1.webp',
    '../../assets/images/asian-summit-2.webp',
    '../../assets/images/asian-summit-3.webp',
    '../../assets/images/asian-summit-4.webp',
    '../../assets/images/asian-summit-5.webp',
    '../../assets/images/asian-summit-6.webp',
    '../../assets/images/asian-summit-7.webp',
    '../../assets/images/asian-summit-8.webp',
    '../../assets/images/asian-summit-9.webp',
    '../../assets/images/asian-summit-10.webp',
    '../../assets/images/asian-summit-11.webp',
    '../../assets/images/asian-summit-12.webp',
    '../../assets/images/asian-summit-13.webp',
  ];
  AsianLeaderImagesSliders1 = [
    '../../assets/images/asian-summit-1.webp',
    '../../assets/images/asian-summit-2.webp',
    '../../assets/images/asian-summit-3.webp',
    '../../assets/images/asian-summit-4.webp',
    '../../assets/images/asian-summit-5.webp',
    '../../assets/images/asian-summit-6.webp',
    '../../assets/images/asian-summit-7.webp',
    '../../assets/images/asian-summit-8.webp',
    '../../assets/images/asian-summit-9.webp',
    '../../assets/images/asian-summit-10.webp',
    '../../assets/images/asian-summit-11.webp',
    '../../assets/images/asian-summit-12.webp',
    '../../assets/images/asian-summit-13.webp',
    '../../assets/images/asian-summit-14.webp',
    '../../assets/images/asian-summit-15.webp',
    '../../assets/images/asian-summit-16.webp',
    '../../assets/images/asian-summit-17.webp',
    '../../assets/images/asian-summit-18.webp',
    '../../assets/images/asian-summit-19.webp',
    '../../assets/images/asian-summit-20.webp',
  ];
  ConicomImagesSliders = [
    '../../assets/images/conicom-3.webp',
    '../../assets/images/conicom-4.webp',
    '../../assets/images/conicom-5.webp',
    '../../assets/images/conicom-6.webp',
    '../../assets/images/conicom-7.webp',
    '../../assets/images/conicom-1.webp',
    '../../assets/images/conicom-2.webp',
    '../../assets/images/conicom-3.webp',
    '../../assets/images/conicom-4.webp',
    '../../assets/images/conicom-5.webp',
    '../../assets/images/conicom-6.webp',
    '../../assets/images/conicom-7.webp',
    '../../assets/images/conicom-1.webp',
    '../../assets/images/conicom-2.webp',
  ];
  ConicomImagesSliders1 = [
    '../../assets/images/conicom-1.webp',
    '../../assets/images/conicom-2.webp',
    '../../assets/images/conicom-3.webp',
    '../../assets/images/conicom-4.webp',
    '../../assets/images/conicom-5.webp',
    '../../assets/images/conicom-6.webp',
    '../../assets/images/conicom-7.webp',
    '../../assets/images/conicom-1.webp',
    '../../assets/images/conicom-2.webp',
    '../../assets/images/conicom-3.webp',
    '../../assets/images/conicom-4.webp',
    '../../assets/images/conicom-5.webp',
    '../../assets/images/conicom-6.webp',
    '../../assets/images/conicom-7.webp',
  ];
  DavidImagesSliders = [
    '../../assets/images/david-chruch-1.webp',
    '../../assets/images/david-chruch-2.webp',
    '../../assets/images/david-chruch-3.webp',
    '../../assets/images/david-chruch-4.webp',
    '../../assets/images/david-chruch-6.webp',
    '../../assets/images/david-chruch-7.webp',
    '../../assets/images/david-chruch-8.webp',
    '../../assets/images/david-chruch-11.webp',
    '../../assets/images/david-chruch-1.webp',
    '../../assets/images/david-chruch-2.webp',
    '../../assets/images/david-chruch-3.webp',
    '../../assets/images/david-chruch-4.webp',
    '../../assets/images/david-chruch-6.webp',
    '../../assets/images/david-chruch-7.webp',
    '../../assets/images/david-chruch-8.webp',
    '../../assets/images/david-chruch-11.webp',
  ];
  DavidImagesSliders1 = [
    '../../assets/images/david-chruch-3.webp',
    '../../assets/images/david-chruch-4.webp',
    '../../assets/images/david-chruch-6.webp',
    '../../assets/images/david-chruch-7.webp',
    '../../assets/images/david-chruch-8.webp',
    '../../assets/images/david-chruch-11.webp',
    '../../assets/images/david-chruch-1.webp',
    '../../assets/images/david-chruch-2.webp',
    '../../assets/images/david-chruch-3.webp',
    '../../assets/images/david-chruch-4.webp',
    '../../assets/images/david-chruch-6.webp',
    '../../assets/images/david-chruch-7.webp',
    '../../assets/images/david-chruch-8.webp',
    '../../assets/images/david-chruch-11.webp',
    '../../assets/images/david-chruch-1.webp',
    '../../assets/images/david-chruch-2.webp',
  ];
  DoctorImagesSliders = [
    '../../assets/images/doc-img-1.webp',
    '../../assets/images/doc-img-2.webp',
    '../../assets/images/doc-img-3.webp',
    '../../assets/images/doc-img-4.webp',
    '../../assets/images/doc-img-5.webp',
    '../../assets/images/doc-img-7.webp',
    '../../assets/images/doc-img-2.webp',
    '../../assets/images/doc-img-3.webp',
    '../../assets/images/doc-img-4.webp',
    '../../assets/images/doc-img-5.webp',
  ];
  DoctorImagesSliders1 = [
    '../../assets/images/doc-img-3.webp',
    '../../assets/images/doc-img-4.webp',
    '../../assets/images/doc-img-5.webp',
    '../../assets/images/doc-img-7.webp',
    '../../assets/images/doc-img-2.webp',
    '../../assets/images/doc-img-3.webp',
    '../../assets/images/doc-img-4.webp',
    '../../assets/images/doc-img-5.webp',
    '../../assets/images/doc-img-1.webp',
    '../../assets/images/doc-img-2.webp',
  ];
  SouthImagesSliders = [
    '../../assets/images/south-korea-1.webp',
    '../../assets/images/south-korea-2.webp',
    '../../assets/images/south-korea-3.webp',
    '../../assets/images/south-korea-4.webp',
    '../../assets/images/south-korea-5.webp',
    '../../assets/images/south-korea-6.webp',
    '../../assets/images/south-korea-7.webp',
    '../../assets/images/south-korea-8.webp',
    '../../assets/images/south-korea-9.webp',
    '../../assets/images/south-korea-19.webp',
    '../../assets/images/south-korea-10.webp',
    '../../assets/images/south-korea-11.webp',
    '../../assets/images/south-korea-12.webp',
    '../../assets/images/south-korea-13.webp',
    '../../assets/images/south-korea-14.webp',
    '../../assets/images/south-korea-15.webp',
    '../../assets/images/south-korea-16.webp',
    '../../assets/images/south-korea-17.webp',
    '../../assets/images/south-korea-20.webp',
    '../../assets/images/south-korea-21.webp',
    '../../assets/images/south-korea-22.webp',
    '../../assets/images/south-korea-23.webp',
    '../../assets/images/south-korea-24.webp',
    '../../assets/images/south-korea-25.webp',
    '../../assets/images/south-korea-26.webp',
    '../../assets/images/south-korea-27.webp',
    '../../assets/images/south-korea-18.webp',
    '../../assets/images/south-korea-28.webp',
    '../../assets/images/south-korea-29.webp',
    '../../assets/images/south-korea-30.webp',
  ];
  SouthImagesSliders1 = [
    '../../assets/images/south-korea-16.webp',
    '../../assets/images/south-korea-20.webp',
    '../../assets/images/south-korea-21.webp',
    '../../assets/images/south-korea-24.webp',
    '../../assets/images/south-korea-25.webp',
    '../../assets/images/south-korea-26.webp',
    '../../assets/images/south-korea-27.webp',
    '../../assets/images/south-korea-28.webp',
    '../../assets/images/south-korea-29.webp',
    '../../assets/images/south-korea-30.webp',
    '../../assets/images/south-korea-1.webp',
    '../../assets/images/south-korea-2.webp',
    '../../assets/images/south-korea-17.webp',
    '../../assets/images/south-korea-3.webp',
    '../../assets/images/south-korea-4.webp',
    '../../assets/images/south-korea-5.webp',
    '../../assets/images/south-korea-6.webp',
    '../../assets/images/south-korea-7.webp',
    '../../assets/images/south-korea-18.webp',
    '../../assets/images/south-korea-8.webp',
    '../../assets/images/south-korea-9.webp',
    '../../assets/images/south-korea-10.webp',
    '../../assets/images/south-korea-11.webp',
    '../../assets/images/south-korea-12.webp',
    '../../assets/images/south-korea-19.webp',
    '../../assets/images/south-korea-13.webp',
    '../../assets/images/south-korea-14.webp',
    '../../assets/images/south-korea-15.webp',
  ];
  viceImagesSliders = [
    '../../assets/images/vice-2.webp',
    '../../assets/images/vice-3.webp',
    '../../assets/images/vice-4.webp',
    '../../assets/images/vice-5.webp',
    '../../assets/images/vice-6.webp',
    '../../assets/images/vice-7.webp',
    '../../assets/images/vice-8.webp',
    '../../assets/images/vice-9.webp',
    '../../assets/images/vice-10.webp',
    '../../assets/images/vice-11.webp',
    '../../assets/images/vice-11.webp',
    '../../assets/images/vice-12.webp',
    '../../assets/images/vice-13.webp',
    '../../assets/images/vice-14.webp',
    '../../assets/images/vice-15.webp',
    '../../assets/images/vice-16.webp',
    '../../assets/images/vice-17.webp',
    '../../assets/images/vice-20.webp',
    '../../assets/images/vice-21.webp',
  ];
  viceImagesSliders1 = [
    '../../assets/images/vice-15.webp',
    '../../assets/images/vice-16.webp',
    '../../assets/images/vice-17.webp',
    '../../assets/images/vice-20.webp',
    '../../assets/images/vice-21.webp',
    '../../assets/images/vice-2.webp',
    '../../assets/images/vice-3.webp',
    '../../assets/images/vice-4.webp',
    '../../assets/images/vice-5.webp',
    '../../assets/images/vice-6.webp',
    '../../assets/images/vice-7.webp',
    '../../assets/images/vice-8.webp',
    '../../assets/images/vice-9.webp',
    '../../assets/images/vice-10.webp',
    '../../assets/images/vice-11.webp',
    '../../assets/images/vice-11.webp',
    '../../assets/images/vice-12.webp',
    '../../assets/images/vice-13.webp',
    '../../assets/images/vice-14.webp',
  ];

  @ViewChild('link') link: any;
  @ViewChild('show') show: any;
  @ViewChild('show1') show1: any;
  @ViewChild('close') close: any;
  @ViewChild('close1') close1: any;
  @ViewChild('slider1') slider1: any;
  @ViewChild('slider2') slider2: any;
  @ViewChild('slider3') slider3: any;
  @ViewChild('slider4') slider4: any;
  @ViewChild('sliderImages') sliderImages: any;
  @ViewChild('sliderImagesMain') sliderImagesMain: any;
  @ViewChild('info')
  public info!: ElementRef<any>;
  loaded = 0;
  loading: boolean = true;
  loadingImage: boolean = false;
  tableTimings: any;
  tabletime: any = [];
  showtext: boolean = false;
  youtubeVideoStatic: string;

  constructor(
    private Service: PrayerRequestService,
    private router: Router,
    private render: Renderer2,
    private elem: ElementRef,
    public dialog: MatDialog,
    private mixpanelService : MixpanelService
  ) {}

  ngOnInit(): void {
    this.trackEvent()
    Aos.init(); //AOS - 2
    Aos.refresh();
    this.Service.TempleTimings().subscribe((res: any) => {
      console.log('table data', res);
      this.tableTimings = res.data;

      // this.tabletime.push(this.tableTimings);
      // console.log('table time', this.tabletime);
    });
    console.log(
      'video',
      'https://www.youtube.com/watch?v=GyrD9Ran9wg'.replace(
        'watch?v=',
        'embed/'
      )
    );
    slider();
    setTimeout(() => {
      this.loading = false;
      this.loadingImage = true;
    }, 15000);
    this.queryParams = {
      isCurrentWeek: 'true',
    };
    this.youtubeVideoStatic =
      'https://www.youtube.com/watch?v=JH_5UR4KpKo'.replace(
        'watch?v=',
        'embed/'
      );
    this.Service.WatchVideoRequestFliter(this.queryParams).subscribe(
      (response) => {
        console.log(response);
        this.fliteringData = response.data;
        console.log(this.fliteringData);
        if (this.fliteringData == 0) {
          this.Service.WatchVideoRequestFliter(
            this.queryParams == null
          ).subscribe((response) => {
            console.log(response);

            this.weekMessge = response.data[0];
            console.log(this.weekMessge);
            this._id = this.weekMessge._id;
            this.title = this.weekMessge.title;

            // this.youtubeVideo = this.weekMessge?.youtubeVideo.replace(
            //   'watch?v=',
            //   'embed/'
            // );
            this.youtubeVideo = null;

            console.log('yotubelink', this.youtubeVideo);
          });
        } else {
          // this.youtubeVideo = this.fliteringData.youtubeVideo?.replace(
          //   'watch?v=',
          //   'embed/'
          // );
          this.youtubeVideo = '';

          this.title = this.fliteringData.title;
          this._id = this.fliteringData._id;
          console.log(this.youtubeVideo);
        }
      }
    );
  }

  trackEvent() {
    this.mixpanelService.track('Home Screen', { /* optional event properties */ });
  }
  
  readMore: boolean = true;
  readLess: boolean = false;
  readLessBtn() {
    this.showtext = false;
    this.readLess = false;
    this.readMore = true;
  }
  readmore() {
    this.showtext = true;
    this.readLess = true;
    this.readMore = false;
  }
  public infoStruc(): void {
    this.info.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  shareHomePage() {
    this.dialog.open(ShareLinkDialogComponent, {
      data: {},
      disableClose: true,
    });
  }
  shareVideo() {
    this.dialog.open(ShareVideoDialogComponent, {
      data: {
        link: this.youtubeVideo,
      },
      disableClose: true,
    });
  }
  shareLocation() {
    console.log('logo');
    const url =
      'https://www.google.com/maps?ll=17.486658,78.3669&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=3633069812437557528';
    window.open(url, '_blank');
  }

  enlargeImg() {
    // this.render.setStyle(this.slider1.nativeElement, 'transform', 'scale(1.5)');
    // this.render.setStyle(this.close1.nativeElement, 'opacity', '1');
    // this.render.setStyle(
    //   this.slider1.nativeElement,
    //   'transition',
    //   'transform 0.25s ease'
    // );
    // // }
    // // this.render.setStyle(this.show.nativeElement, 'display', 'none');
    // // this.render.setStyle(this.show1.nativeElement, 'display', 'none');
  }
}
