import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-share-link-dialog',
  templateUrl: './share-link-dialog.component.html',
  styleUrls: ['./share-link-dialog.component.css'],
})
export class ShareLinkDialogComponent implements OnInit {
  link: any = 'https://www.calvarytemple.in/';
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  close() {
    this.dialog.closeAll();
  }
}
