import {
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { WhiteSpaceValidator } from 'src/app/_helpers/whitespace.validator';
// import { MixPanelService } from 'src/app/_services/mix-panel.service';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';
import { environment } from 'src/environments/environment';
import { SuccessDialogDonateComponent } from './success-dialog-donate/success-dialog-donate.component';
import { MixpanelService } from 'src/app/_services/mixpanel.service';
declare var Razorpay: any;
declare var notAllzeros: any;

@Component({
  selector: 'app-donate-payment-gateway',
  templateUrl: './donate-payment-gateway.component.html',
  styleUrls: ['./donate-payment-gateway.component.css'],
})
export class DonatePaymentGatewayComponent implements OnInit {
  DonateForm: FormGroup;
  submitted: any = false;
  phoneNumber: any;
  ZerosAll: any;
  public getScreenWidth: any;
  public getScreenHeight: any;
  rzp1: any;
  Amount: any;
  orderId: any;
  paymentId: any;
  error: any;
  DonateId: any;
  GreetingCardName: any;
  details: any;
  title: any;
  razorpay_payment_id: any;
  donate: boolean = false;
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private Services: PrayerRequestService,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private mixpanelService : MixpanelService
    // private mixpanelService: MixPanelService
  ) {
    this.DonateForm = this.formBuilder.group({
      amount: '',
      userId: '',
      donationId: '',
      tvProgramId: '',
      type: '',
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      name: ['', [Validators.required]],
    });
  }
  // noWhitespaceValidator(control: FormControl) {
  //   const isWhitespace =
  //     ((control && control.value && control.value.toString()) || '').trim()
  //       .length === 0;
  //   const isValid = !isWhitespace;
  //   return isValid ? null : { whitespace: true };
  // }
  moblieHandlers(e: any) {
    console.log(e.target.value);
  }

  ngOnInit(): void {
    // this.trackEvent()
    notAllzeros();
    // window.location.reload();
    this.Amount = localStorage.getItem('amount');
    console.log(this.Amount);
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.loading();
  }

  ProceedToPay() {
    if (
      this.DonateForm.invalid ||
      this.DonateForm.value.phoneNumber === '0000000000' ||
      this.DonateForm.value.phoneNumber === '0000000' ||
      this.DonateForm.value.phoneNumber === '00000' ||
      this.DonateForm.value.phoneNumber === '00000000'
    ) {
      this.submitted = true;
      if (
        this.DonateForm.value.phoneNumber === '0000000000' ||
        this.DonateForm.value.phoneNumber === '0000000' ||
        this.DonateForm.value.phoneNumber === '00000' ||
        this.DonateForm.value.phoneNumber === '00000000'
      ) {
        this.ZerosAll = '';
      }
    } else {
      this.submitted = false;
      // this.donate = true;
      // this.DonateForm.reset();
      this.Amount = localStorage.getItem('amount');
      this.DonateId = localStorage.getItem('Donate_id');
      let obj = {
        amount: this.Amount,
        userId: this.DonateForm.value.userId,
        donationId: this.DonateId,
        tvProgramId: '',
        noOfProgram: '',
        channelId: '',
        type: 'Donation',
        phoneNumber: this.DonateForm.value.phoneNumber,
        name: this.DonateForm.value.name,
        origin: 'website',
      };
      this.Services.createOrder(obj).subscribe(
        (data) => {
          console.log('1 st time ');
          console.log('2 st time ');
          document.body.removeAttribute('style');
          this.orderId = data.data.id;
          
            this.mixpanelService.track('Give to God User Details Web', { /* optional event properties */ });
          
          // this.mixpanelService.track('Donate-form-details');
          // this.mixpanelService.setPeople({
          //   Amount: this.Amount,
          //   type: 'Donation',
          //   phoneNumber: this.DonateForm.value.phoneNumber,
          //   name: this.DonateForm.value.name,
          // });

          let options = {
            key: `${environment.RAZOR_PAY_ID}`,
            currency: 'INR',
            amount: '',
            donationId: '',
            name: '',
            phoneNumber: '',
            description: '',
            contact: '',
            image: '',
            order_id: this.orderId,
            handler: function (response: any) {
              var event = new CustomEvent('payment.success', {
                detail: response,
                bubbles: true,
                cancelable: true,
              });
              window.dispatchEvent(event);
              // ref.handlePayment(response);
            },
            prefill: {
              name: '',
              email: '',
              contact: this.DonateForm.value.phoneNumber,
            },
            notes: {
              address: '',
            },
            theme: {
              color: '#3399cc',
            },
          };
          options['handler'] = this.razorPaySuccessHandler.bind(this);
          // options.amount = this.Amount; //paise
          // options.donationId = this.DonateId; //paise
          // options.contact = this.DonateForm.value.phoneNumber; //paise
          this.rzp1 = new Razorpay(options);
          this.rzp1.open();
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  loading() {
    this.Services.prayerRequestBanner(this.Services).subscribe(
      (response: any) => {
        console.log(response);
        this.GreetingCardName = response.data.buttonName;
        this.details = response.data.details;
        this.title = response.data.title;
      }
    );
  }
  public razorPaySuccessHandler(response: any) {
    console.log('payment-success', response);
    console.log('payment_id', response.razorpay_payment_id);
    this.razorpay_payment_id = response.razorpay_payment_id;
    localStorage.setItem('donatePaymentId', this.razorpay_payment_id);
    if (this.getScreenWidth >= 480) {
      this.ngZone.run(() =>
        this.dialog.open(SuccessDialogDonateComponent, {
          disableClose: true,
          data: {
            _id: this.razorpay_payment_id,
          },
        })
      );
    } else {
      this.ngZone
        .run(() => this.router.navigateByUrl('/make-a-donation/success'))
        .then();
    }
    this.cd.detectChanges();
    // document.getElementById('razorpay-response').style.display = 'block';
  }

  back() {
    this.router.navigate(['/make-a-donation']);
  }

  numberOnly(event: any) {
    let newValue = event.target.value.replace(/[^0-9]/g, '');
    console.log('alphabets', newValue);
    this.DonateForm.controls['phoneNumber'].setValue(newValue);
  }

  AlphabetsOnly(event: any) {
    let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
    console.log('alphabets', newValue);
    this.DonateForm.controls['name'].setValue(newValue);
  }
  get f(): { [key: string]: AbstractControl } {
    return this.DonateForm.controls;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    console.log(this.getScreenWidth, this.getScreenHeight);
  }
}
