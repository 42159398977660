<div class="main">
  <app-navbar></app-navbar>
    


   <div class="container">
       <div class="about-us-page mt-5">
           <h4>Calvary Temple Foundation Church is the second largest church in the world with a weekly congregation of 300,000 members from Hyderabad, India. It is being run and operated by the Man of God Dr P Satish Kumar</h4>

           <h4>Dr. Satish Kumar is an Indian and highly speaks of India on international platforms about Indian values, culture, and family structure. He emphasizes, “Service to mankind is service to God, and as Jesus said, “ Love your neighbor as you love yourself. He believes this – thus, he practices. Dr. Satish Kumar is Highly esteemed as a great Humanitarian. On behalf of Calvary temple, he has donated 800 tons of food to the poor and needy during the 2020 lockdown. During the Lockdown period, when everyone was afraid even to come out, Dr. Satish Kumar organized hundreds and thousands of volunteers to reach four corners of Andhra and Telangana states, and they delivered Grocery kits to the doorsteps of the needy and suffering; this can kit could feed an entire family of four people for more than one month. These Calvary Temple volunteers have reached close to 40,000 homes with food kits that contain 30 varieties of grocery items</h4>
           <h4>As a student, He began sharing this universal truth and hundreds and thousands of people were attracted to his teachings which transformed their lives. After completing his education He got job opportunities to work in Canada, Singapore, etc. As he was above to fly to Canada to pursue his career, again He had a divine visitation of Jesus Christ and said! You are chosen for a divine purpose to serve humanity through divinity. In 1991 He Surrendered His entire life to serve mankind. His powerful yet Simple life-changing teachings have attracted millions and transformed their lives from wickedness to Godliness.</h4>
           <h4>He is highly accepted and respected by all the regions and religious People because he Never complains, condemns, criticizes or compares other Gods or religions.</h4>

           <h2>You can visit Calvary Temple Church at the following address:
               Address: Calvary Temple Rd, Hyder Nagar, Janapriya Nagar, Hafeezpet, Hyderabad, Telangana 500049</h2>
<h2>Contact us :<span>040 6645 4545</span> </h2>
<h2>Email us: developer@calvarytemple.in</h2>



       </div>
   </div>
  <!-- <div class="about-us-header-section">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="about-us-header">
            <h3>Meet Paster</h3>
            <h4>Dr. P Satish Kumar</h4>
          </div>
        </div>
        <div class="col-md-5">
          <div class="about-header-image-section">
            <img
              src="../../assets/images/DhdMl1XUwAAawrM-removebg-preview.png"
              alt="about-us image"
              class="about-us-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="title mt-5">
      <div class="row">
        <div class="col-md-6">
          <div class="about-us-title mt-4">
            <h4>Our Values</h4>
            <h5>Title here</h5>
            <p>
              We help our customers to scale their business in digital
              transformation initiatives through consulting, innovative sales
              services, market opportunities, and productive lead generation
              methodologies.
            </p>
            <div class="submit-button">
              <button type="submit" class="btn submit">Submit</button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="about-us-title-image">
            <img
              src="../../assets/images/a-1.webp"
              alt="title-img"
              class="title-img"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="subscribe-section"
      style="background-image: url('../../assets/images/s-12.webp')"
    >
      <div class="subscribe-title">
        <h5>Subscribe To Our Newsletter</h5>
        <h4>Subscribe to our newsletter</h4>
        <h4>receive latest news & updates.</h4>
      </div>
      <div class="form-section-1">
        <div class="subscribe-form-section">
          <div class="form-section d-flex">
            <i class="fa fa-whatsapp fa-1x" aria-hidden="true"></i>
            <input type="text" placeholder="Enter your Whatsapp no." />
            <div class="subscribe-btn">
              <button type="submit" class="btn btn-subscribe">
                Subscribe&nbsp; &nbsp;<i
                  class="fa fa-arrow-right"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <app-footer></app-footer>
</div>
