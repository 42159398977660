import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';

@Component({
  selector: 'app-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.css'],
})
export class WatchVideoComponent implements OnInit {
  watchLink: any;
  queryParamsDetails: any;
  watchDetails: any;
  watchVideoLinkParams: any;
  watchParamsApiCall: any;
  watchParamsDetails: any;
  watchDetailsTitle: any;
  watchDetailsDate: any;
  watchDetailsspeakerName: any;
  watchDetailscreatedAt: any;
  watchDetailsDescription: any;
  constructor(
    public router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private Service: PrayerRequestService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.watchLink = this.queryParamsDetails.params.video;
      this.watchVideoLinkParams = params.get('_id');
      this.watchParamsApiCall = this.Service.WatchVideoRequest().subscribe(
        (res) => {
          this.watchDetails = res.data;

          this.watchParamsDetails = this.watchDetails.find(
            (x: any) => x._id == this.watchVideoLinkParams
          );
          console.log(this.watchParamsDetails);
          this.watchDetailsTitle = this.watchParamsDetails.title;
          this.watchDetailscreatedAt = this.watchParamsDetails.createdAt;
          this.watchDetailsspeakerName = this.watchParamsDetails.speakerName;
          this.watchDetailsDate = this.watchParamsDetails.createdAt;
          this.watchDetailsDescription = this.watchParamsDetails.description;
        }
      );
    });
  }
  WatchSlideConfig = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    prevArrow:
      "<img class='a-left control-c prev slick-prev-2' src='../../assets/images/left-1.svg'>",
    nextArrow:
      "<img class='a-right control-c next slick-next-2' src='../../assets/images/w-v-1.svg'>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  videoLink() {
    // window.location.reload();
  }
  share() {
    this.dialog.open(ShareDialogComponent, {
      data: {
        link: this.watchLink,
      },
      disableClose: true,
      // height: '70%',
      // width: '45%',
    });
  }
}
