<div class="main">
  <app-navbar></app-navbar>

  <div class="container">
    <div class="d-flex bd-highlight mb-3">
      <div class="">
        <div class="faq-header">
          <img
            src="../../assets/images/question.webp"
            alt="question-mark"
            class="q-m"
          />
        </div>
      </div>
      <div class="faq-header">
        <h1>Frequently Asked Questions (FAQs)</h1>
      </div>
      <div class="ml-auto pt-3 bd-highlight">
        <div class="">
          <a href="#" class="previous round">&#8249;</a>
          <a href="#" class="next round">&#8250;</a>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex">
      <div class="faq-header mt-3 d-flex">
        <img
          src="../../assets/images/question.webp"
          alt="question-mark"
          class="q-m"
        />
        <h1>Frequently Asked Questions (FAQs)</h1>
      </div>
     
    </div> -->

    <div class="row">
      <div class="col-md-6">
        <div class="accordian-header mt-4 mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <hr />
        <div class="accordian-header mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <hr />
        <div class="accordian-header mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <hr />
        <div class="accordian-header mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div class="col-md-6">
        <div class="accordian-header mt-4 mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <hr />
        <div class="accordian-header mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <hr />
        <div class="accordian-header mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <hr />
        <div class="accordian-header mb-3">
          <mat-accordion>
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
              [expanded]="true"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  How do I pay for the Essentials or Premium
                  plan?</mat-panel-title
                >
                <mat-panel-description>
                  <!-- Currently I am {{ panelOpenState ? "open" : "closed" }} -->
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                bibendum sollicitudin arcu in semper. Donec sagittis rutrum
                pretium. Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
