import { Component, OnInit } from '@angular/core';

declare const scroll_top: any;

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css'],
})
export class CookiePolicyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    scroll_top();
  }
}
