<div class="main">
  <app-navbar class="navbar-request"></app-navbar>

  <div class="container">
    <div class="web-view-tv-ministry">
      <div class="back-section ml-0 mt-2 mt-md-5" (click)="back()">
        <img src="../../assets/images/arrow-left.svg" class="arrow-left_img cursor" />
        <button class="btn back-btn" type="button">Back</button>
      </div>
    </div>
    <div class="mobile-view-tv-ministry">
      <div class="d-flex">
        <div class="back-section ml-0 mt-3 mr-auto p-2" (click)="back()">
          <img src="../../assets/images/arrow-left.svg" class="arrow-left_img cursor" />
          <button class="btn back-btn" type="button">Payment</button>
        </div>
        <div class="scan_qr-1 ml-auto p-2">
          <button type="button" class="scan_btn btn Scan-Code-moblie">
            <img src="../../assets/images/scan-1.svg" class="arrow-left_img cursor" />
            Scan QR Code
          </button>
        </div>
      </div>
      <hr class="back-span-1" />
    </div>

    <div class="payment_name_section d-flex mb-md-5">
      <img src="../../../assets/images/Calvary Temple (9)/Property 1=New Logo@3x.png" class="sbi_img" />
      <div class="payment_bank_name ml-2">
        <h5>Paying to:</h5>
        <h6>Calvary Temple Foundation</h6>
      </div>
    </div>
    <div clas="form-section mb-4 mt-md-5">
      <form [formGroup]="DonateForm">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputPhone">Full name</label>

              <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder="Full name" autocomplete="off" formControlName="name" (keyup)="AlphabetsOnly($event)"
                pattern="\S(.*\S)?" ngxTrim="focusout" />
              <div *ngIf="f.name.errors?.required && submitted" class="invalid-feedback d-block">
                Name is required
              </div>
              <div *ngIf="f.name.errors?.pattern && submitted" class="invalid-feedback d-block">
                space is not allowed
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group phone">
              <label for="exampleInputPhone">Phone number</label>
              <div class="input-wrapper-1">
                <span class="prefix">+91</span>
                <input type="text" class="form-control" id="Amount" aria-describedby="emailHelp"
                  placeholder="Phone number" autocomplete="off" (keyup)="numberOnly($event)" maxlength="10"
                  [(ngModel)]="ZerosAll" formControlName="phoneNumber" ngxTrim="focusout" />
              </div>

              <div *ngIf="f.phoneNumber.errors?.required && submitted" class="invalid-feedback d-block">
                Phone number is required
              </div>
              <div *ngIf="f.phoneNumber.errors?.pattern && submitted" class="invalid-feedback d-block">
                Phone number is invalid
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
        
          <div class="col-md-6"></div>
        </div> -->
      </form>
      <div class="request-prayer mt-3 mb-5">
        <button (click)="ProceedToPay()" [disabled]="donate" class="btn">Proceed to Pay</button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>