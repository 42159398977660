<div class="main">
  <app-navbar></app-navbar>
  <div class="container mt-5">
    <div class="prayers-request mt-md-5 mt-3 pt-md-3">
      <!-- <div (click)="back()" class="back-section back-prayer ml-0">
        <img
          src="../../assets/images/arrow-left.svg"
          class="arrow-left_img cursor"
        />
        <button class="btn back-btn" type="button">Back</button>
        <hr class="back-span" />
      </div> -->
      <!-- <div class="back">
        <h3>Back</h3>
      </div> -->
      <!-- <div class="" *ngIf="errorMessage != null">
        <h4>{{ errorMessage }}</h4>
      </div> -->
      <div class="prayer-request-header">
        <h4>
          Contact Us
        </h4>
        <p>
          Please tell us your problem & get a call back from our Calvary team.
        </p>
      </div>
    </div>

    <div class="form-section-contact-us ">
      <form [formGroup]="contactUsForm">
        <div class="row">
          <div class="col-md-6 mt-4">
            <div class="Email-field">
              <mat-form-field>
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" />
              </mat-form-field>
              <div *ngIf="f.name.errors?.required && submitted" class="invalid-feedback d-block">
                Please enter Name
              </div>
              <!-- <div *ngIf="f.email.errors?.pattern && submitted" class="invalid-feedback d-block">
                Please enter Valid Email
              </div> -->
            </div>

          </div>
          <div class="col-md-6 mt-4">
            <div class="Email-field">
              <mat-form-field>
                <mat-label>Email address</mat-label>
                <input matInput formControlName="email" />
              </mat-form-field>
              <div *ngIf="f.email.errors?.required && submitted" class="invalid-feedback d-block">
                Please enter Email Address
              </div>
              <div *ngIf="f.email.errors?.pattern && submitted" class="invalid-feedback d-block">
                Please enter Valid Email
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          
          <div class="col-md-6 mt-4">
            <div class="Email-field">
              <mat-form-field>
                <mat-label>Mobile Number</mat-label>
                <span matPrefix>&nbsp;+91&nbsp;</span>
                <input matInput maxlength="10" (keyup)="numberOnly($event)" formControlName="phone" />
              </mat-form-field>
              <div *ngIf="f.phone.errors?.required && submitted" class="invalid-feedback d-block">
                Please enter Mobile Number
              </div>
            </div>

          </div>
          <div class="col-md-6 mt-4">
            <mat-form-field>
              <mat-label>Select your purpose</mat-label>
              <mat-select formControlName="reason" (selectionChange)="raiseAnissue($event)">
                <mat-option [value]="issue._id" *ngFor="let issue of raiseAnIssue">{{issue.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="f.reason.errors?.required && submitted" class="invalid-feedback d-block">
              Please Select
            </div>
          </div>
          <div class='spinner-loader' *ngIf="loaderShow">
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

          </div>
        </div>
        <!-- <div class="row">
      <div class="col-md-6 mt-4">
   
      </div>
     </div> -->
        <div class="row">
          
          <div class="col-md-6">
            <!-- <div class="Email-field mt-4 pt-1">
                <mat-form-field >
                  <mat-label>Where did you face this issue?</mat-label>
                  <input matInput formControlName="typeOfBug" (keyup)="prayerRequestValidation($event)"/>
                </mat-form-field>
                <div
            *ngIf="f.typeOfBug.errors?.required && submitted"
            class="invalid-feedback d-block"
          >
            Please Enter The Issue
          </div>  
            </div> -->

          </div>
        </div>
        <div class="radio-button-section  mt-4">
          <div class="issue_header">
            <h5>Where did you face this issue?</h5>
          </div>
          <mat-radio-group formControlName="typeOfBug" (change)="typeOfBugIssue($event)">
            <mat-radio-button *ngFor="let type of issue" [disableRipple]="true" [checked]="type.key === 'Website'"
              value="{{type.value}}" name="Website" class="mr-3">{{type.key}}</mat-radio-button>

          </mat-radio-group>
        </div>

        <div class="attachement-flie-section mt-4 form-group">
          <h5>Attachment</h5>
          <div *ngIf="uploadFlie">
            <input style="display: none" type="file" formControlName="attachment" (change)="onFileSelected($event)"
              #fileInput>
            <div *ngIf="f.attachment.errors?.required && submitted" class="invalid-feedback d-block">
              Please Add The Attachment
            </div>
            <button class="btn btn-upload" (click)="fileInput.click()"> <img src="../../assets/images/upload.svg"
                class="mr-2">Upload Attachment</button>
            <!-- <div
            *ngIf="f.attachment.errors?.required && submitted"
            class="invalid-feedback d-block"
          >
            File is required
          </div> -->
          </div>

        </div>
        <div class="row mt-1" *ngIf="attachmentFile">
          <div class="col-md-6">
            <div class="file-upload-view-section">
              <div class="d-flex">
                <div class="mr-auto">
                  <div class="image-section d-flex">
                    <div class="img-section">
                      <img src="../../assets/images/attach-file.png" class="mr-2 flie-img">

                    </div>

                    <div class="view_details">
                      <h5>{{fileName}}</h5>

                    </div>
                  </div>

                </div>
                <div class="close-img cursor" (click)="close()">
                  <img src="../../assets/images/close-pdf.png" width="30px">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6"></div>
        </div>
        <div class="text-area mt-2">
          <textarea style="resize: none" class="text-area text-option" placeholder="Enter issue in (600 words)"
            id="exampleFormControlTextarea1" (keyup)="contactUsDesc($event)" rows="6" ngxTrim="focusout"
            formControlName="description"></textarea>
          <div *ngIf="f.description.errors?.required && submitted" class="invalid-feedback d-block">
            Please Write The Issue
          </div>
          <div *ngIf="errorLength" class="invalid-feedback d-block">
            Maximum word limit for the description is 600 words.
          </div>
        </div>
      </form>
      <div class="request-prayer mb-5 mt-4">
        <button type="submit" (click)="submit()" class="btn">Submit</button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>



</div>