<div class="main" id="top_scroll">
  <app-navbar></app-navbar>
  <div class="">
    <div class="watch_section_header">
      <!-- <img src="../../assets/images/wathc.png" class="watch_img" /> -->
      <div class="container">
        <div class="">
          <div class="home_to_watch_page watch_page">
            <ul class="d-flex p-0">
              <li routerLink="/Home">
                <h2 class="home_h2">Home</h2>
              </li>
              <li><img src="../../assets/images/v-1.svg " class="v-1" /></li>
              <li>
                <h2>Watch</h2>
              </li>
            </ul>
            <h4>Watch Online</h4>
            <p>
              Experience an inspiring and powerful message, along with uplifting
              music and worship every single week.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container"></div>
    <div class="container">
      <div class="videos_slider">
        <div class="main_video_header">
          <div class="swiper mySwiperSlider">
            <div class="testimonties_main_section">
              <div class="inspired_main d-flex">
                <div class="recent_messages">
                  <p>Recent Messages</p>
                </div>
              </div>
            </div>
            <ngx-slick-carousel
              class="carousel"
              #slickModal="slick-carousel"
              [config]="WatchSlideConfig"
            >
             <div class="no_messeage" *ngIf="this.WatchVideos == ''">
              <h4 class="text-center">There is no message available.</h4>
            </div>
              <div ngxSlickItem *ngFor="let video of WatchVideos" class="slide">
                <div class="card video_header_tag d-flex">
                  <div class="card-body main_video_slider">
                    <div class="testimonies_section">
                      <div class="video-container" id="video-container">
                        <!-- <iframe
                          loading="lazy"
                          class="loaders"
                          poster="../../assets/images/v-tag.png"
                          [src]="
                            video.youtubeVideo.replace('watch?v=', 'embed/')
                              | safePipe
                          "
                        ></iframe> -->
                        <a href="{{ video.youtubeVideo }}" target="_blank" (click)="beforeRedirect(video.title)">
                          <div class="text-center">
                            <img
                              src="../../assets/images/circle.svg"
                              class="images-sliders"
                            />
                          </div>

                          <img *ngIf="video?.thumbnail == null"
                            src="{{
                              'https://img.youtube.com/vi/' +
                                video.youtubeVideo.split('=').pop() +
                                '/hqdefault.webp'
                            }}"
                            class="watch-img"
                          />
                          <img *ngIf="video?.thumbnail != null"
                            [src]="video?.thumbnail"
                            class="watch-img"
                          />

                        </a>
                        <!-- <img
                          src="https://img.youtube.com/vi/oPM2_8xH9KM/hqdefault.webp"
                          class="images-sliders"
                        /> -->
                        <!-- <div class="play-button-wrapper">
                          <div
                            title="Play video"
                            class="play-gif"
                            id="circle-play-b"
                          >
                            <img src="../../assets/images/c-b.svg" />
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <h4 class="mt-3">{{ video.createdAt | date: "MMMM d, y" }}</h4>
                    <h5>{{ video.title }}</h5>
                    <h3 class="mb-2">{{ video.speakerName }}</h3>
                    <div class="d-flex">
                      <a
                        href=""
                        class="mx-0 my-0"
                        routerLink="/Watch/video"
                        [queryParams]="{
                          video: video.youtubeVideo.replace(
                            'watch?v=',
                            'embed/'
                          ),
                          _id: video._id
                        }"
                        >Watch
                      </a>
                      <img src="../../assets/images/ar-l-2.svg" class="pl-1" />
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="videos_slider">
        <!-- testimonies_section  -->
        <div class="main_video_header">
          <div class="swiper mySwiperSlider">
            <div class="testimonties_main_section">
              <div class="inspired_main d-flex">
                <div class="recent_messages">
                  <p>All Recorded Messages</p>
                </div>
              </div>
            </div>
            <form [formGroup]="WatchGroup">
              <div class="watch-section-fliter w-100 mt-3">
                <div class="form-group s-1">
                  <label for="">Speaker</label>

                 
                  <input  class="form-control"
                    id="speakerName-input"
                    readonly
                    [value]="speakerName">
                   
                </div>
                <div class="form-group s-2">
                  <label for="">Topic</label>
                  <select
                    id="inputState"
                    (change)="onChangeTopic($event)"
                    class="form-control"
                    formControlName="select"
                  >
                    <option value="0" selected disabled hidden>Select</option>
                    <option
                      *ngFor="let topicList of TopicList"
                      value="{{ topicList.category }}"
                    >
                      {{ topicList.category }}
                    </option>
                  </select>
                </div>
                <div class="form-group s-3">
                  <label class="label" for="">Date</label>
                  <mat-form-field class="example-full-width" appearance="fill">
                    <input
                      matInput
                      (dateChange)="onChangeDate($event)"
                      [matDatepicker]="picker"
                      placeholder=" {{ myplaceHolder }}"
                      [max]="today"
                      readonly="readonly"
                      formControlName="select"
                    />
                    <mat-datepicker-toggle matSuffix [for]="picker">
                      <mat-icon matDatepickerToggleIcon
                        ><img
                          src="../../assets/images/c-11.svg"
                          class="calendra-img"
                      /></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="text-left reset mt-1" *ngIf="resetBtn">
                  <button type="button" class="btn" (click)="reset()">
                    Reset
                  </button>
                </div>
              </div>
              <!-- <div class="form-row form-group row-eq-spacing-sm">
                <div class="col-sm"></div>
                <div class="col-sm"></div>
                <div class="col-sm"></div>
              </div> -->
            </form>
            <!-- <div class="search_not_found" *ngIf="this.fliteringData == ''">
              <h4 class="text-center">Search not found</h4>
            </div> -->
            <ngx-slick-carousel
              class="carousel"
              #slickModal="slick-carousel"
              [config]="slideConfig"
            >
                
                 <!-- <div class="no_messeage" *ngIf="this.noYoutubeVideo == ''">
              <h4 class="text-center">There is no message available.</h4>
            </div> -->
              <div
                ngxSlickItem
                *ngFor="let video of fliteringData"
                class="slide"
              >
                <div class="card video_header_tag d-flex">
                  <div class="card-body main_video_slider">
                    <div class="testimonies_section">
                      <div class="video-container" id="video-container">
                        <!-- <iframe
                          loading="lazy"
                          class="load-shine"
                          poster="../../assets/images/v-tag.png"
                          [src]="
                            video.youtubeVideo.replace('watch?v=', 'embed/')
                              | safePipe
                          "
                        ></iframe> -->
                        <!-- <iframe
                          loading="lazy"
                          class="load-shine"
                          poster="../../assets/images/v-tag.png"
                          [src]="video.youtubeVideo | slice: 0:32"
                        ></iframe> -->
                        <a href="{{ video.youtubeVideo  }}" target="_blank" (click)="beforeRedirect(video.title)" >
                          <!-- <img
                            src="https://img.youtube.com/vi/oPM2_8xH9KM/hqdefault.webp"
                            class="images-sliders"
                          /> -->
                          <div class="text-center">
                            <img
                              src="../../assets/images/circle.svg"
                              class="images-sliders"
                            />
                          </div>
                           <img *ngIf="video.thumbnail == null"
                            src="{{
                              'https://img.youtube.com/vi/' +
                                video.youtubeVideo.split('=').pop() +
                                '/hqdefault.webp'
                            }}"
                            class="watch-img"
                          />
                          <img *ngIf="video.thumbnail != null"
                            [src]="video.thumbnail"
                            class="watch-img"
                          />
                        </a>

                        <!-- <div class="play-button-wrapper">
                          <div
                            title="Play video"
                            class="play-gif"
                            id="circle-play-b"
                          >
                            <img src="../../assets/images/c-b.svg" />
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <h4 class="mt-3">{{ video.createdAt | date: "MMMM d, y" }}</h4>
                    <h5>{{ video.title }}</h5>
                    <h3 class="mb-2">{{ video.speakerName }}</h3>
                    <div class="d-flex">
                      <a
                        href=""
                        class="mx-0 my-0"
                        routerLink="/Watch/video"
                        [queryParams]="{
                          video: video.youtubeVideo.replace(
                            'watch?v=',
                            'embed/'
                          ),
                          _id: video._id
                        }"
                        >Watch
                      </a>
                      <img src="../../assets/images/ar-l-2.svg" class="pl-1" />
                    </div>
                  </div>
                </div>
              </div>
            </ngx-slick-carousel>
             <div class="no_messeage" *ngIf="this.fliteringData == ''">
              <h4 class="text-center">There is no message available.</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
