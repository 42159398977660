<nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">
  <div class="container">
    <div class="logo-class">
      <a class="navbar-brand mr-4" href="/Home">
        <img src="../../assets/images/Calvary Temple (9)/Property 1=New Logo@3x.png" class="header-logo" />
        <!-- <img src="../../assets/images/navbar-logo-11.svg" class="header-logo" /> -->
      </a>
      <!-- <p class="header-name mt-1 pr-4">
        Calavary <br />
        <span> Temple</span>
      </p> -->
    </div>
  <div class="give-god nav-link give-now-mobile pr-3">
          <button class="btn js-scroll-trigger" routerLink="/make-a-donation">
            GIVE NOW
          </button>
        </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a
          class="nav-link js-scroll-trigger"
          routerLink="/About-us"
         
          id="navItem1"
        >
          About us
        </a>
        <a class="nav-link js-scroll-trigger" routerLink="/Watch" id="navItem2"
          >Watch</a
        >
        <a class="nav-link js-scroll-trigger" routerLink="/make-a-donation" id="navItem3"
          >Give now</a
        >
        <a class="nav-link js-scroll-trigger" routerLink="/sponsor-a-tv-program" id="navItem3"
          >TV Ministry</a
        >
     
        <a class="nav-link" routerLink="/Contact-Us" id="navItem2">Contact</a>
        <a class="nav-link" routerLink="/submit-a-prayer-request" id="navItem2">Request Prayer</a>
        <!-- <a class="nav-link" href="#" id="navItem3">Donate</a> -->
      </div>
      <div class="navbar-nav ml-auto">
        
        <div class="give-god give-now nav-link ">
          <button class="btn js-scroll-trigger" routerLink="/make-a-donation">
            GIVE NOW
          </button>
        </div>
        <div class="give-god nav-link pr-3">
          <button class="btn js-scroll-trigger"  (click)="about()" routerLink="/Home">
             Download Our App
          </button>
        </div>
        <!-- <div class="dropdown pr-3 nav-link">
          <button
            class="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Download Our App
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="#">iOS</a>
            <a class="dropdown-item" href="#">Android</a>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</nav>

<div class="main-header">
  <div class="container">
    <div class="main-header-section d-flex justify-content-center">
      <!-- <button class="btn tv-1" type="submit" routerLink="/Tv-programs">
        <h4>TV Ministry</h4>
      </button>
      <button class="btn tv-1 no-click" type="submit">
        <h4>Bible College</h4>
      </button>
      <button class="btn tv-1 no-click" type="submit">
        <h4>Magazine</h4>
      </button>
      <button class="btn tv-1 no-click" type="submit">
        <h4>Visiting Hours</h4>
      </button>
      <button class="btn tv-1 no-click" type="submit">
        <h4>For Kids</h4>
      </button>
      <button class="btn tv-1 no-click" type="submit">
        <h4>Events</h4>
      </button>
      <button class="btn tv-1 no-click" type="submit">
        <div class="d-flex">
          <h4 class="pr-0 pt-1">Crusades</h4>

          <p>New</p>
        </div>
      </button> -->
    </div>
  </div>
</div>
