<div class="main" id="top_scroll">
  <app-navbar></app-navbar>

  <div class="prayer_request_section">
    <div class="container">
      <!-- <div class="pray_request_header_request">
        <h1>Tell us your problem and we will pray for you </h1>
      </div> -->
    </div>
  </div>

  <div class="container">
      <div class="problem_selection_menu_section">
      <div class="problem_selection mt-md-5 mb-md-4">
        <h4>Please select your problem & submit prayer request</h4>
      </div>

      <div class="prayer_request_name prayer-laptop-screen">
        <div class="row">
          <div
            class="col-xl-3 col-sm-6 col-md-3 col mt-4 mb-4"
            *ngFor="let details of prayerRequestDetails"
          >
            <div class="card" style="width: 100%; position: relative;">
                <img  *ngIf="!details.webImages.includes('https://')"
                class="card_img_request"
                src="{{ this.url }}/{{ details.webImages }}"
                alt="Card image cap"
                />
                <img *ngIf="details.webImages.includes('https://')"
                    class="card_img_request"
                    src="{{ details.webImages }}"
                    alt="Card image cap"
                  /> 

              <div class="card-body marriage_option">
                <h5 class="marriage_req">{{ details.title }}</h5>
                <div class="pray_request_btns text-center">
                  <button
                    [routerLink]="['/submit-a-prayer-request/details']"
                    [queryParams]="{ _id: details._id, title: details.title }"
                    class="btn btn_pray_req"
                  >
                    Request Prayer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="prayer_request_name prayer-mobile-screen">
        <div class="row">
          <div
            class="col-xl-3 col-sm-6 col-md-3 col-6 mt-4 mb-4"
            *ngFor="let details of prayerRequestDetails"
          >
            <div class="" style="width: 100%; ">
              <span>
                <a
                  [routerLink]="['/submit-a-prayer-request/details']"
                  [queryParams]="{ _id: details._id, title: details.title }"
                >
                  <img  *ngIf="!details.webImages.includes('https://')"
                    class="card-img-request-mobile"
                    src="{{ this.url }}/{{ details.webImages }}"
                    alt="Card image cap"
                  />
                  <img  *ngIf="details.webImages.includes('https://')"
                    class="card-img-request-mobile"
                    src="{{ details.webImages }}"
                    alt="Card image cap"
                  />
                 <!-- <img *ngIf="details.webImages.contains('https://')"
                    class="card-img-request-mobile"
                    src="https://www.happierhuman.com/wp-content/uploads/2019/07/difference-religion-spirituality.webp"
                    alt="Card image cap"
                  />  -->

                  <div class="marriage_option-mobile">
                    <h5 class="marriage_req">{{ details.title }}</h5>
                  </div>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prayer_tower_section mt-5 mb-4">
      <h4>Call Our Prayer Towers. Open 24/7</h4>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <!-- <img src="../../assets/images/t-1.svg" class="t_1 mr-2 mt-2" /> -->
             <h4 class="l-icons">అ</h4>
            <h3 class="mt-3 ml-2">Telugu</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">8008008003</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100400800</h4>
            </div>
            <!-- <div class="prayer_languges language_details">
              <h4 class="details_1">9946464146</h4>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <h4 class="l-icons">आ</h4>
            <h3 class="mt-3 ml-2">Hindi</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9000600700</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9000600700</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <img src="../../assets/images/t-3.svg" class="t_1 mr-2 mt-2" />
            <h3 class="mt-3 ml-2">English</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9946464146</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9946464146</h4>
            </div>
            <div class="prayer_languges language_details">
              <h4 class="details_1">9946464146</h4>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <h4 class="l-icons">એ</h4>
            <h3 class="mt-3 ml-2">Gujarati</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100901090</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100901090</h4>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <h4 class="l-icons">ஏ</h4>
            <h3 class="mt-3 ml-2">Tamil</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100600400</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 ">9100600400</h4>
            </div>
       
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                        <h4 class="l-icons">ਏ</h4>

            <h3 class="mt-3 ml-2">Punjabi</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100600300</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100600300</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                       <h4 class="l-icons">ಎ</h4>

            <h3 class="mt-3 ml-2">Kannada</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9000600900</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 ">9000600900</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                       <h4 class="l-icons">ඒ</h4>

            <h3 class="mt-3 ml-2">
             Sinhalese
            </h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100600400</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100600400</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                        <h4 class="l-icons">ए</h4>

            <h3 class="mt-3 ml-2">Marathi</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100800888</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100800888</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <h4 class="l-icons">ক</h4>
            
            <h3 class="mt-3 ml-2">Bengali</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">8008556958</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">8008556958</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                      <h4 class="l-icons">ଏ</h4>

            <h3 class="mt-3 ml-2">Oriya</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100400600</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100400600</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                       <h4 class="l-icons">اے</h4>

            <h3 class="mt-3 ml-2">Urdu</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100600300</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100600300</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                       <h4 class="l-icons">ক</h4>

            <h3 class="mt-3 ml-2">Assamese </h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100901050</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100901050</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                   <h4 class="l-icons">എ</h4>

            <h3 class="mt-3 ml-2">Malayalam</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100600500</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100600500</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                       <h4 class="l-icons">एगो</h4>

            <h3 class="mt-3 ml-2">Bhojpuri</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9100901080</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">9100901080</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                   <h4 class="l-icons">ए</h4>

            <h3 class="mt-3 ml-2">Nepali</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">8125574004</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">8125574004</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
                   <h4 class="l-icons">K</h4>

            <h3 class="mt-3 ml-2">Konkani(ROPHE, GOA)</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">8977009034</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1">8977009034</h4>
            </div>
         
          </div>
        </div>
      </div>
    </div>

  
  </div>
  <app-footer></app-footer>
</div>
