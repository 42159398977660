<div class="main">
  <app-navbar></app-navbar>

  <div class="container-fluid prayers">
    <div class=" ">
      <div class="welcome_chruch justify-content-center" data-aos="fade-right"  data-aos-anchor="#example-anchor"
     data-aos-offset="200"
     data-aos-duration="3000" >
        <h4 >Welcome to the world</h4>
        <h4>second largest church</h4>
        <p class="power-of-god">Experience the power of God</p>
        <!-- <div class="join-btn-section donate-btn ">
          <button type="button" class="btn join-btn" routerLink="/make-a-donation">
            Donate Now
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div #info id="info"></div>

  <div class="man-of-god-section mt-md-5">
    <div class=" ">
      <div class="man-of-god">
        <div class="row flex-column-reverse flex-md-row">
          <div class="col-md-6">
            <div class="man-of-god-image">
              <img
                src="../../assets/images/man-of-god-1.png"
                alt=""
                class="image-god"
                loading="lazy"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="founder-details-section">
              <h1>Pleasure to welcome you to Calvary Temple, India</h1>
              <h4>Man of God</h4>
              <h5>Pastor Rev P Satish Kumar</h5>
              <h3>Founder, Calvary Temple</h3>
              <div>
                <p>
               Dr. Satish Kumar is an Indian and highly speaks of India on international platforms about Indian values, culture, and family structure. He emphasizes, “Service to mankind is service to God, and as Jesus said, “ Love your neighbor as you love yourself. He believes this – thus, he practices.

                
<span class="hidetext" [class.show-text]="this.showtext == true">

Dr. Satish Kumar is Highly esteemed as a great Humanitarian. On behalf of Calvary temple, he has donated 800 tons of food to the poor and needy during the 2020 lockdown. During the Lockdown period, when everyone was afraid even to come out, Dr. Satish Kumar organized hundreds and thousands of volunteers to reach four corners of Andhra and Telangana states, and they delivered Grocery kits to the doorsteps of the needy and suffering; this can kit could feed an entire family of four people for more than one month. These Calvary Temple volunteers have reached close to 40,000 homes with food kits that contain 30 varieties of grocery items.
</span>
<a *ngIf="readMore" class="anchor-tag" (click)="readmore()">Read more</a>
<a *ngIf="readLess" class="anchor-tag" (click)="readLessBtn()">Read less</a>
              </p>
              <div class="man-of-god-btn-section mt-4">
                <div class="watch-btn">
                  <button
                    type="button"
                    routerLink="/Watch"
                    class="btn btn-watch-live"
                  >
                    Watch now
                    <img
                      src="../../assets/images/p-c-1.svg"
                      class="p-cirle-img"
                    />
                  </button>
                </div>
                <div class="Schedule-btn watch-btn">
                  <!-- <button type="button" class="btn btn-Schedule">
                    Schedule
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="week-msg-section mt-md-5">
    <div class="container">
      <div class="this-week-message-section  mb-md-3">
        <div class="row">
          <div class="col-md-6">
            <div class="message_week">
              <!-- <h5>{{ title }}</h5> -->
              <h5>Watch Dr P Satish Kumar’s message</h5>
              <p>
               Watch the latest powerful message from the man of god and be blessed and empowered in your spirit.
              </p>
               <!-- [queryParams]="{
                      video: youtubeVideo,
                      _id: _id
                    }" -->
              <div class="chruch-buttons-section">
                <div class="watch-live-btn">

                  <button
                    type="button"
                    class="btn btn-1-watch cursor timing-section-btns btn-video btn-watch"
                    routerLink="/Watch"
                   
                  >
                    Watch now
                    <img
                      src="../../assets/images/w-l.svg"
                      alt="c-t-1"
                      class="btn-image"
                    />
                  </button>
                </div>
                <div class="msg_btns_2">
                  <div class="get-directions btn-direction-1">
                    <button
                      type="button cursor "
                      routerLink="/Watch"
                      class="btn btn-2-view timing-section-btns"
                    >
                      View all messages
                    </button>
                  </div>
                  <div class="share-btn-chruch btn-share-1">
                    <button
                      type="button"
                      class="btn btn-3-share timing-section-btns"
                      (click)="shareVideo()"
                    >
                      Share
                      <img
                        src="../../assets/images/w-s.svg"
                        alt="c-t-1"
                        class="btn-image hide-share"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 video-tag-1">
            <!-- <div class="" *ngIf="youtubeVideo != null">
              <iframe
                class="loaders"
                poster="../../assets/images/v-tag.png"
                [src]="youtubeVideo | safePipe"
                loading="lazy"
              ></iframe>
            
            </div> -->
            <div class="" >
              <iframe
                class="loaders"
                poster="../../assets/images/v-tag.png"
                [src]="youtubeVideoStatic | safePipe"
                loading="lazy"
              ></iframe>
            
            </div>

        
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-chruch-home">
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-md-6 col-lg-6 col-sm-6">
        <div class="chruch-temple-section">
          <h4>About Calvary Temple</h4>
          <div class="chruhc-img">
            <img
              src="../../assets/images/a-chruch.webp"
              alt="church-image"
              id="church"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6 f-l col-lg-6 col-sm-6">
        <div class="family-section">
          <h4>About Calvary Temple</h4>
          <h1>We're a family of</h1>
          <h2>300,000+ members</h2>
          <hr class="home-page-hr" />
          <div class="members-details d-flex">
            <div class="tv-outreach-section tv-reach">
              <div class="outreach-section d-flex">
                <img
                  src="../../assets/images/mem-1.png"
                  alt="outreach"
                  id="reach-out"
                  loading="lazy"
                />
                <h3>50M+</h3>
              </div>
              <h5>TV Outreach</h5>
              <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                aliquam
              </p> -->
            </div>
            <div class="tv-outreach-section">
              <div class="outreach-section d-flex">
                <img
                  src="../../assets/images/mem-1.png"
                  alt="outreach"
                  id="reach-out"
                />
                <h3>10+</h3>
              </div>
              <h5>Church's</h5>
              <!-- <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
                aliquam
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tv__ministry">
    <div class="container">
      <div class="tv-ministry">
        <div
          class="ministry-section pt-md-5 pb-md-3 d-flex"
        >
          <!-- <img src="../../assets/images/tv-2.png" /> -->
          <h4>Calvary Swaram</h4>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="tv-program">
              <!-- <h4>500+ TV Programs a month</h4> -->
              <h5><span>500+ TV Programs a month</span></h5>
              <h5><span>reaching 100 Million+ souls</span></h5>
              <!-- <p>
                Calvary temple has the most extensive tv programs in the country, producing 100s of programs every week to reach millions of souls. We are committed to bringing as many souls close to god and his grace
              </p> -->
              <div class="sponosor-tv-btn man-of-god-btn-section mt-md-5">
                <div class="tv-sponsor btn-tv-sponsor">
                  <button type="button" routerLink="/sponsor-a-tv-program" class="btn">
                    Sponsor a TV program
                  </button>
                </div>
                <div class="tv-View tv-sponsor">
                  <button
                    type="button"
                    routerLink="/sponsor-a-tv-program"
                    class="btn btn-tv-View"
                  >
                   View Schedule
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="tv-programs-img">
              <img
                src="../../assets/images/tv-1.png"
                alt="tv-1"
                id="tv-1"
                loading="lazy"
              />
              <img
                src="../../assets/images/tv-res.png"
                alt="tv-1"
                id="tv-2"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="images_slider">
    <div class="helping-the-poor-section">
      <h3>The Largest Calvary Crusades in History</h3>
    </div>

    <div class="my-3">
      <div class="">
        <div class="">
          <div class="tabbable-responsive">
            <div class="tabbable">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="first-tab"
                    data-toggle="tab"
                    href="#first"
                    role="tab"
                    aria-controls="first"
                    aria-selected="true"
                    >Calvary Crusades</a
                  >
                </li>

                <!-- <li class="nav-item">
                  <a
                    class="nav-link"
                    id="second-tab"
                    data-toggle="tab"
                    href="#second"
                    role="tab"
                    aria-controls="second"
                    aria-selected="false"
                    >Kerela Flood 2022</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="third-tab"
                    data-toggle="tab"
                    href="#third"
                    role="tab"
                    aria-controls="third"
                    aria-selected="false"
                    >Tsunami</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="fourth-tab"
                    data-toggle="tab"
                    href="#fourth"
                    role="tab"
                    aria-controls="fourth"
                    aria-selected="false"
                    >Child Education</a
                  >
                </li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="tab-content">
            <div
              class="tab-pane fade show active"
              id="first"
              role="tabpanel"
              aria-labelledby="first-tab"
            >
              <div class="swiper mySwiper">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-1.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-2.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-3.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-4.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-5.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-6.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-7.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-8.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-9.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-10.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-11.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-12.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-13.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-14.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-15.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-16.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-17.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-18.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-19.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-20.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-21.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-22.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-23.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-23.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-24.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-25.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-26.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-27.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-28.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-29.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-30.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-31.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-32.webp"
                      class="slider-1"
                    />
                  </div>
                  <div class="swiper-slide">
                    <img
                      src="../../assets/images/calvary-crusades-33.webp"
                      class="slider-1"
                    />
                  </div>
                </div>
                <div class="swiper-button-next" #show1></div>
                <div class="swiper-button-prev" #show></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="helping-the-poor-section man-of-god-header man-of-god">
        <h3 class="w-100">Man of God International Presence</h3>
      </div>
    </div>
    <div [@.disabled]="true">
      <mat-tab-group [disableRipple]="true" mat-stretch-tabs class="w-100">
        <mat-tab label="South Korea" class="mat_tab_1">
          <div class="slider">
            <div class="slide-track" #sliderImagesMain>
              <div
                class="slide imgBox"
                *ngFor="let image of SouthImagesSliders"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div class="slide-track-1">
              <div
                class="slide imgBox"
                *ngFor="let image of SouthImagesSliders1"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="South Africa" class="mat_tab_1">
          <div class="slider">
            <div class="slide-track" #sliderImagesMain>
              <div
                class="slide imgBox"
                *ngFor="let image of KoreaImagesSliders"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div class="slide-track-1">
              <div
                class="slide imgBox"
                *ngFor="let image of KoreaImagesSliders1"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Asian Leader Summit" class="mat_tab_1">
          <div class="slider">
            <div class="slide-track" #sliderImagesMain>
              <div
                class="slide imgBox"
                *ngFor="let image of AsianLeaderImagesSliders"
              >
                <img
                  [src]="image"
                  height="280px"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div class="slide-track-1">
              <div
                class="slide imgBox"
                *ngFor="let image of AsianLeaderImagesSliders1"
              >
                <img
                  [src]="image"
                  height="280px"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Conicom Conference" class="mat_tab_1">
          <div class="slider">
            <div class="slide-track" #sliderImagesMain>
              <div
                class="slide imgBox"
                *ngFor="let image of ConicomImagesSliders"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  height="280px"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="slide-track-1">
              <div
                class="slide imgBox"
                *ngFor="let image of ConicomImagesSliders1"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  height="280px"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="David Jeremiah Chruch" class="mat_tab_1">
          <div class="slider">
            <div class="slide-track" #sliderImagesMain>
              <div
                class="slide imgBox"
                *ngFor="let image of DavidImagesSliders"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div class="slide-track-1">
              <div
                class="slide imgBox"
                *ngFor="let image of DavidImagesSliders1"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Doctorate" class="mat_tab_1">
          <div class="slider">
            <div class="slide-track" #sliderImagesMain>
              <div
                class="slide imgBox"
                *ngFor="let image of DoctorImagesSliders"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  height="280px"
                  loading="lazy"
                />
              </div>
            </div>
            <div class="slide-track-1">
              <div
                class="slide imgBox"
                *ngFor="let image of DoctorImagesSliders1"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  height="280px"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Vice President" class="mat_tab_1">
          <div class="slider">
            <div class="slide-track" #sliderImagesMain>
              <div class="slide imgBox" *ngFor="let image of viceImagesSliders">
                <!-- <p *ngIf="loading">loading...</p> -->
                <img
                  [src]="image"
                  height="281px"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div class="slide-track-1">
              <div
                class="slide imgBox"
                *ngFor="let image of viceImagesSliders1"
              >
                <img
                  [src]="image"
                  class="slider-images-1"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <!-- testimonies_section  -->
    <!-- <app-testimonties-slider></app-testimonties-slider> -->

    <!-- church table timings section -->

    <div class="chruch-timing-main-section">
      <div class="container">
        <div class="chruch-timing-section mt-md-5">
          <div
            class="d-flex chruch-header justify-content-center pt-md-5 pb-md-5"
          >
            <img
              src="../../assets/images/timer.svg"
              alt="c-t-1"
              class="timer-image"
            />
            <h4 class="ml-2">Church Timings</h4>
          </div>
        </div>
      </div>
      <mat-tab-group mat-align-tabs="start" mat-stretch-tabs>
        <mat-tab label="{{time.title}}" *ngFor="let time of tableTimings">
          <div class="timing-section container">
            <table class="table table-striped mt-4 table-responsive">
              <tbody>
                <tr>
                  <td class="weekly-timing-dates_1 m-0">
                    <!-- <h6>25 sep</h6> -->
                    <h4>{{time.prayer[0].title}}</h4>
                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>1st service</h6>
                    <h4>{{time.prayer[0].chruchTimings.services.firstService}}</h4>
                      <h6 *ngIf="time.prayer[0].chruchTimings.services.firstService == null">-</h6>
                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>2nd service</h6>
                    <h4>{{time.prayer[0].chruchTimings.services.secondService}}</h4>
                      <h6 *ngIf="time.prayer[0].chruchTimings.services.secondService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>3rd service</h6>
                    <h4>{{time.prayer[0].chruchTimings.services.thirdService}}</h4>
                      <h6 *ngIf="time.prayer[0].chruchTimings.services.thirdService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>4th service</h6>
                    <h4>{{time.prayer[0].chruchTimings.services.fourthService}}</h4>
                      <h6 *ngIf="time.prayer[0].chruchTimings.services.fourthService == null">-</h6>

                  </td>
                </tr>
                <tr>
                  <td class="weekly-timing-dates_1 m-0">
                    <!-- <h6>25 sep</h6> -->
                    <h4>{{time.prayer[1].title}}</h4>
                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>1st service</h6>
                    <h4>{{time.prayer[1].chruchTimings.services.firstService }}</h4>
                      <h6 *ngIf="time.prayer[1].chruchTimings.services.firstService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>2nd service</h6>
                    <h4>{{time.prayer[1].chruchTimings.services.secondService}}</h4>
                      <h6 *ngIf="time.prayer[1].chruchTimings.services.secondService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>3rd service</h6>
                    <h4>{{time.prayer[1].chruchTimings.services.thirdService}}</h4>
                      <h6 *ngIf="time.prayer[1].chruchTimings.services.thirdService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>4th service</h6>
                    <h4>{{time.prayer[1].chruchTimings.services.fourthService}}</h4>
                      <h6 *ngIf="time.prayer[1].chruchTimings.services.fourthService == null">-</h6>

                  </td>
                </tr>
                <tr>
                  <td class="weekly-timing-dates_1 m-0">
                    <!-- <h6>25 sep</h6> -->
                    <h4>{{time.prayer[2].title}}</h4>
                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>1st service</h6>
                    <h4>{{time.prayer[2].chruchTimings.services.firstService}}</h4>
                      <h6 *ngIf="time.prayer[2].chruchTimings.services.firstService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>2nd service</h6>
                    <h4>{{time.prayer[2].chruchTimings.services.secondService}}</h4>
                      <h6 *ngIf="time.prayer[2].chruchTimings.services.secondService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>3rd service</h6>
                    <h4>{{time.prayer[2].chruchTimings.services.thirdService}}</h4>
                      <h6 *ngIf="time.prayer[2].chruchTimings.services.thirdService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>4th service</h6>
                    <h4>{{time.prayer[2].chruchTimings.services.fourthService}}</h4>
                      <h6 *ngIf="time.prayer[2].chruchTimings.services.fourthService == null">-</h6>

                  </td>
                </tr>
                <tr>
                  <td class="weekly-timing-dates_1 m-0">
                    <!-- <h6>25 sep</h6> -->
                    <h4>{{time.prayer[3].title}}</h4>
                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>1st service</h6>
                    <h4>{{time.prayer[3].chruchTimings.services.firstService}}</h4>
                      <h6 *ngIf="time.prayer[3].chruchTimings.services.firstService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>2nd service</h6>
                    <h4>{{time.prayer[3].chruchTimings.services.secondService}}</h4>
                      <h6 *ngIf="time.prayer[3].chruchTimings.services.secondService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>3rd service</h6>
                    <h4>{{time.prayer[3].chruchTimings.services.thirdService}}</h4>
                      <h6 *ngIf="time.prayer[3].chruchTimings.services.thirdService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>4th service</h6>
                    <h4>{{time.prayer[3].chruchTimings.services.fourthService}}</h4>
                      <h6 *ngIf="time.prayer[3].chruchTimings.services.fourthService == null">-</h6>

                  </td>
                </tr>
                 <tr>
                  <td class="weekly-timing-dates_1 m-0">
                    <!-- <h6>25 sep</h6> -->
                    <h4>{{time.prayer[4].title}}</h4>
                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>1st service</h6>
                    <h4>{{time.prayer[4].chruchTimings.services.firstService }}</h4>
                      <h6 *ngIf="time.prayer[4].chruchTimings.services.firstService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>2nd service</h6>
                    <h4>{{time.prayer[4].chruchTimings.services.secondService}}</h4>
                      <h6 *ngIf="time.prayer[4].chruchTimings.services.secondService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-1 m-0"
                  >
                    <h6>3rd service</h6>
                    <h4>{{time.prayer[4].chruchTimings.services.thirdService}}</h4>
                      <h6 *ngIf="time.prayer[4].chruchTimings.services.thirdService == null">-</h6>

                  </td>
                  <td
                    class="weekly-timing-dates weekly-timing-dates-services-2 m-0"
                  >
                    <h6>4th service</h6>
                    <h4>{{time.prayer[4].chruchTimings.services.fourthService}}</h4>
                      <h6 *ngIf="time.prayer[4].chruchTimings.services.fourthService == null">-</h6>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="chruch-buttons-section pt-md-5 pb-md-5 justify-content-center"
          >
            <div class="watch-live-btn">
          
            </div>
            <div class="chruch_btn_2">
             
              <div class="get-directions">
                <button
                  type="button"
                  class="btn timing-section-btns btn-direction"
                >
                 <a
                    target="_blank"
                    href="http://maps.google.com/?q={{time.coordinates[0]}},{{time.coordinates[1]}}"
                    >Get Directions</a
                  >
                 
                </button>
              </div>
              <div class="share-btn-chruch">
                <button
                  type="button"
                  class="btn timing-section-btns btn-share"
                  (click)="shareHomePage()"
                >
                  Share
                  <img
                    src="../../assets/images/share-12.svg"
                    alt="c-t-1"
                    class="btn-image hide-share"
                  />
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
    
      </mat-tab-group>
    </div>

    <!-- subscribe section -->

    <div class="subscribe_section mt-md-3 mb-md-3">
      <div class="container">
        <div class="subscribe_social_section">
          <h4>Subscribe to our social media</h4>
          <p>Be a part of our family.</p>
          <!-- <div class="social_media_icons pb-5 pt-5 justify-content-center d-flex">
          <i class="fa fa-youtube-play" aria-hidden="true"></i>
          <i class="fa fa-facebook" aria-hidden="true"></i>
          <i class="fa fa-twitter" aria-hidden="true"></i>
          <i class="fa fa-instagram" aria-hidden="true"></i>
          <i class="fa fa-linkedin" aria-hidden="true"></i>
        </div> -->
          <div class="social-menu pt-5">
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCYaE-blRyiy300gLC-yDRUQ"
                  target="blank"
                  ><i class=""><img src="../../assets/images/you-1.svg" /></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/DrSatishKumar.Facebook"
                  target="blank"
                  ><i class=""><img src="../../assets/images/fa-1.svg" /></i
                ></a>
              </li>
              <li>
                <a href="https://twitter.com/CALVARYTEMPLE3" target="blank"
                  ><i class=""><img src="../../assets/images/tw-1.svg" /></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/calvarytempleindia"
                  target="blank"
                  ><i class=""><img src="../../assets/images/in-1.svg" /></i
                ></a>
              </li>
              <!-- <li>
                <a
                  href="https://www.linkedin.com/in/dr-p-satish-kumar-3a5647219/"
                  target="blank"
                  ><i class=""><img src="../../assets/images/lin-1.svg" /></i
                ></a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  <div id="about_page"></div>

    <app-footer></app-footer>

  </div>
</div>
 
