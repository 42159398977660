import {
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SuccessDialogDonateComponent } from '../donate/donate-payment-gateway/success-dialog-donate/success-dialog-donate.component';
import { PrayerRequestService } from '../_services/prayer-request.service';
import { SuccessDialogComponent } from './tv-program-payment-gateway/success-dialog/success-dialog.component';
import { MixpanelService } from '../_services/mixpanel.service';
declare var Razorpay: any;
declare var $: any;

@Component({
  selector: 'app-tv-programs',
  templateUrl: './tv-programs.component.html',
  styleUrls: ['./tv-programs.component.css'],
})
export class TvProgramsComponent implements OnInit {
  Sponsor: any = true;
  SponsorDetails: any = false;
  activePage: any;
  ActivePage: any;
  tvProgramRequestForm: FormGroup;
  tvProgramRequestDetailsForm: FormGroup;
  submitted: boolean = false;
  submittedDetails: boolean = false;
  tableData: any;
  languageDropDownData: any;
  language: any = 'Telugu';
  queryParams: any;
  channelNames: any;
  channelNamesProgramId: any = '';
  tvProgramId: any;
  LanguageData: any;
  tableDataDetails: any;
  ScheduledData: any = [];
  // url: any = 'https://calvary-backend.s3.amazonaws.com/';
  channelName: any;
  saveChannelNames: any;
  numberOfPrograms: any = '1';
  programName: any = '';
  programChannelName: any;
  ProgramPrice: any = '';
  totalAmount: any;
  tabelLanguage: any;
  tabelTimingDetails: any = [];
  tableLanguageSelect: any;
  tableLanguageData: any = [];
  tableDataAfterFilter: any = [];
  tabelTimingDefault: any;
  noOFPrograms: any = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  queryParamsDetails: any;
  SponsorDetailsParams: any;
  zerosAll: any;
  getScreenWidth: any;
  getScreenHeight: any;
  orderId: any;
  rzp1: any;
  Amount: any;
  PhoneNumber: any;
  tvProgramIdPay: any;
  TableData: any;
  razorpay_payment_id: any;
  amountValidate: any;
  tvProgramIdFormValue: any;
  channelIdFormValue: any;
  tableViewDetails: any = [];
  languageSelect: any = 'Telugu';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private Service: PrayerRequestService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private mixpanelService: MixpanelService,
  ) {
    this.tvProgramRequestForm = this.formBuilder.group({
      tvProgramId: [this.language],
      userId: [''],
      name: [''],
      phone: [''],
      membershipId: [''],
      amount: ['', [Validators.required]],
      language: ['', [Validators.required]],
      noOfProgram: ['', [Validators.required]],
      channelId: ['', [Validators.required]],
      email: [''],
    });
    this.tvProgramRequestDetailsForm = this.formBuilder.group({
      tvProgramId: [''],
      userId: [''],
      name: ['', [Validators.required]],
      phone: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      membershipId: [''],
      amount: [''],
      noOfProgram: [''],
      channelId: [''],
      email: [
        '',
        [
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
  }
  Telugu: any = 'Telugu';
  ngOnInit(): void {
    this.trackEvent()
    // this.tvProgramRequestForm
    //   .get('language')
    //   .setValue('63b408358c2d032ab5937b16');
    // this.Service.tvProgramRequestChannel('63b408358c2d032ab5937b16').subscribe(
    //   (res) => {
    //     // console.log(res);
    //     this.channelNames = res.data;
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
    // this.tvProgramRequestForm.get('channelId').setValue('Subhavartha TV');
    // this.tvProgramRequestForm.get('noOfProgram').setValue('2');

    this.tableData = this.Service.tvProgramScheduleRequest().subscribe(
      (res) => {
        console.log(res);
        this.tableDataDetails = res.data;
        console.log(this.tableDataDetails);
        this.tabelTimingDetails = this.tableDataDetails.filter((item: any) => {
          return item.tvProgramId.title == 'Telugu';
        });
        this.tableViewDetails.push(this.tabelTimingDetails);

        // console.log(this.tabelTimingDetails);
        // console.log(this.tableViewDetails);
        this.tabelLanguage = this.tableDataDetails.map((item: any) => {
          this.tableLanguageSelect = item.tvProgramId.title;
          // console.log(this.tableLanguageSelect);
          this.tableLanguageData.push(this.tableLanguageSelect);
          // console.log(this.tableLanguageData);
          let uniqueSubjects = [...new Set(this.tableLanguageData)];
          this.tableDataAfterFilter = uniqueSubjects;
          console.log(this.tableDataAfterFilter);
        });
      }
    );
    // this.router.navigateByUrl('/Tv-programs/success');
    this.route.queryParamMap.subscribe((params) => {
      this.queryParamsDetails = { ...params.keys, ...params };
      this.SponsorDetailsParams = this.queryParamsDetails.params.SponsorDetails;
      console.log(this.SponsorDetailsParams);
      if (this.SponsorDetailsParams == true) {
        this.SponsorDetails = this.SponsorDetailsParams;
        console.log(this.SponsorDetails);
        this.Sponsor = false;
      }
    });

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    this.LanguageData = this.Service.tvProgramRequestTableData().subscribe(
      (res) => {
        // console.log(res);
        this.languageDropDownData = res.data;
        this.language = this.languageDropDownData[0]._id;
        this.Service.tvProgramRequestChannel(this.language).subscribe(
          (res) => {
            this.channelNames = res.data;
            this.programName = 'Subhavartha TV';
            console.log(this.channelNames, 223);
            this.saveChannelNames = this.channelNames.filter((item: any) => {
              return item.channelName == this.programName;
            });
            console.log(this.saveChannelNames);
            this.tvProgramIdFormValue = this.saveChannelNames[0].tvProgramId;
            this.channelIdFormValue = this.saveChannelNames[0]._id;
            this.ProgramPrice = this.saveChannelNames[0].price;
            this.tvProgramIdPay = this.saveChannelNames[0].tvProgramId.toString();
            if (this.numberOfPrograms !== '') {
              this.totalAmount = `${parseFloat(this.ProgramPrice) * parseFloat(this.numberOfPrograms)
                }`;
              console.log(this.ProgramPrice , 'sdsd');
            } else {
              this.numberOfPrograms = 0;
              this.totalAmount = `${parseFloat(this.ProgramPrice) * parseFloat(this.numberOfPrograms)
                }`;
            }
          },
          (error) => {
            console.log(error);
          }
        );

      },
      (error) => {
        console.log(error);
      }
    );
    // this.Service.tvProgramRequestChannel('6358da27e6ff6ce73def938d').subscribe(
    //   (res) => {
    //     // console.log('telugu', res);
    //     this.channelNames = res.data;
    //     console.log(this.channelNames);
    //     // this.languageDropDownData = res.data;
    //     // this.tabelTimingDetails = res.data;
    //     // this.totalAmount = `${'₹'}${
    //     //   parseFloat(this.ProgramPrice) * parseFloat(this.numberOfPrograms)
    //     // }`;
    //     // console.log(this.totalAmount);
    //     // this.channelNamesProgramId = res.data[0].tvProgramId;
    //     // console.log(this.channelNamesProgramId);
    //   },
    //   (error) => {
    //     console.log(error);
    //   }
    // );
  }
  trackEvent() {
    this.mixpanelService.track('TV Program Amount Details Web', { /* optional event properties */ });
  }
  onChangeTable(e: any) {
    // console.log(e.target.value);

    this.TableData = e.target.value;
    this.tableData = this.Service.tvProgramScheduleRequest().subscribe(
      (res) => {
        // console.log(res);

        this.tableDataDetails = res.data;
        this.tabelTimingDetails = this.tableDataDetails.filter((item: any) => {
          return item.tvProgramId.title == this.TableData;
        });
      }
    );
  }
  onChange(e: any) {
    console.log(e.target.value);
    this.language = e.target.value;
    this.channelNames = [];
    this.programName = '';
    this.ProgramPrice = '';
    this.totalAmount = '';
    // this.tableData = this.Service.tvProgramScheduleRequest().subscribe(
    //   (res) => {
    //     console.log(res);

    //     this.tableDataDetails = res.data;
    //     this.languageDropDownData = this.language;

    //     this.tabelTimingDetails = this.tableDataDetails.filter((item: any) => {
    //       return item.tvProgramId.title == this.language;
    //     });
    //   }
    // );

    if (e.target.value != '') {
      this.Service.tvProgramRequestChannel(this.language).subscribe(
        (res) => {
          this.channelNames = res.data;
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.channelNames = [];
      console.log('names', this.channelNames);
    }
  }
  onChangeChannel(e: any) {
    this.programName = e.target.value;
    console.log(this.programName);
    if (e.target.value != '') {
      this.saveChannelNames = this.channelNames.filter((item: any) => {
        // console.log(item);
        return item.channelName == this.programName;
      });
      console.log(this.saveChannelNames);
      this.tvProgramIdFormValue = this.saveChannelNames[0].tvProgramId;
      this.channelIdFormValue = this.saveChannelNames[0]._id;
      // console.log('tv', this.tvProgramIdFormValue);
      // console.log('channel', this.channelIdFormValue);
      this.ProgramPrice = this.saveChannelNames[0].price;
      console.log('====================================');
      console.log(this.ProgramPrice , 'dfdfd');
      console.log('====================================');
      this.tvProgramIdPay = this.saveChannelNames[0].tvProgramId.toString();
      if (this.numberOfPrograms !== '') {
        this.totalAmount = `${parseFloat(this.ProgramPrice) * parseFloat(this.numberOfPrograms)
          }`;
      } else {
        this.numberOfPrograms = 0;
        this.totalAmount = `${parseFloat(this.ProgramPrice) * parseFloat(this.numberOfPrograms)
          }`;
      }
    } else {
      console.log(this.programName);
      this.saveChannelNames = '';
    }
  }
  ProgramChange(e: any) {
    // console.log(e.target.value);

    this.numberOfPrograms = e.target.value;
    if (this.ProgramPrice !== '') {
      this.totalAmount = `${parseFloat(this.ProgramPrice) * parseFloat(this.numberOfPrograms)
        }`;
      console.log(this.totalAmount);
    } else {
      this.ProgramPrice = 0;
      this.totalAmount = `${parseFloat(this.ProgramPrice) * parseFloat(this.numberOfPrograms)
        }`;
      // console.log(this.totalAmount);
    }
  }

  proceed() {
    // this.SponsorDetails = true;
    // this.Sponsor = false;
    if (this.tvProgramRequestForm.invalid) {
      this.submitted = true;

      if (
        this.tvProgramRequestForm.value.amount === '00000000' ||
        this.tvProgramRequestForm.value.amount === '000000' ||
        this.tvProgramRequestForm.value.amount === '00000' ||
        this.tvProgramRequestForm.value.amount === '00000'
      ) {
        this.totalAmount = '';
      }
    } else {
      console.log(this.tvProgramRequestForm.value);
      this.submitted = false;
      this.SponsorDetails = true;
      this.Sponsor = false;
      this.mixpanelService.track('TV Program User Details Web', { /* optional event properties */ });
    }
  }
  ProceedToPayBtn() {
    if (
      this.tvProgramRequestDetailsForm.invalid ||
      this.tvProgramRequestDetailsForm.value.phone === '0000000000' ||
      this.tvProgramRequestDetailsForm.value.phone === '0000000' ||
      this.tvProgramRequestDetailsForm.value.phone === '00000' ||
      this.tvProgramRequestDetailsForm.value.phone === '00000000'
    ) {
      this.submittedDetails = true;
      if (
        this.tvProgramRequestDetailsForm.value.phone === '0000000000' ||
        this.tvProgramRequestDetailsForm.value.phone === '0000000' ||
        this.tvProgramRequestDetailsForm.value.phone === '00000' ||
        this.tvProgramRequestDetailsForm.value.phone === '00000000'
      ) {
        this.zerosAll = '';
      }
    } else {
      this.submittedDetails = false;

      console.log(this.tvProgramRequestDetailsForm.value);
      let obj = {
        tvProgramId: this.tvProgramIdFormValue,
        userId: '',
        name: this.tvProgramRequestDetailsForm.value.name,
        phone: this.tvProgramRequestDetailsForm.value.phone,
        membershipId: '',
        amount: this.tvProgramRequestForm.value.amount?.replace('₹', ''),
        noOfProgram: this.tvProgramRequestForm.value.noOfProgram,
        channelId: this.channelIdFormValue,
        email: this.tvProgramRequestDetailsForm.value.email,
      };
      this.Amount = this.tvProgramRequestForm.value.amount?.replace('₹', '');
      this.PhoneNumber = this.tvProgramRequestDetailsForm.value.phone;
      console.log('pay', this.Amount);
      // this.Service.tvProgramsRequest(obj).subscribe(
      //   (res) => {
      // console.log(res);
      let obj1 = {
        amount: this.Amount,
        userId: '',
        donationId: '',
        tvProgramId: this.tvProgramIdPay,
        type: 'Tv-Program',
        name: this.tvProgramRequestDetailsForm.value.name,
        phoneNumber: this.PhoneNumber,
        noOfProgram: this.tvProgramRequestForm.value.noOfProgram,
        channelId: this.channelIdFormValue,
      };
      // this.tvProgramRequestDetailsForm.reset();
      // this.tvProgramRequestForm.reset();
      // this.Sponsor = true;
      // this.SponsorDetails = false;

      console.log('payobject', obj1);
      this.Service.createOrder(obj1).subscribe(
        (data) => {
          // console.log(data);
          document.body.removeAttribute('style');

          this.orderId = data.data.id;
          // console.log(this.orderId);
          let options = {
            key: `${environment.RAZOR_PAY_ID}`,
            currency: 'INR',
            amount: '',
            name: '',
            description: '',
            tvProgramId: this.tvProgramIdPay,
            image: '',
            order_id: this.orderId,
            handler: function (response: any) { },
            prefill: {
              name: '',
              email: this.tvProgramRequestDetailsForm.value.email,
              contact: this.PhoneNumber,
            },
            notes: {
              address: '',
            },
            theme: {
              color: '#3399cc',
            },
          };
          options['handler'] = this.razorPaySuccessHandler.bind(this);
          options.amount = this.Amount;
          options.tvProgramId = this.tvProgramIdPay;
          this.rzp1 = new Razorpay(options);

          this.rzp1.open();
        },
        (error) => {
          console.log(error);
        }
      );
      console.log('form-data', obj);
      // this.router.navigate(['payment'], { relativeTo: this.route });
    }
  }

  tvProgramRequestAPICall() { }

  public razorPaySuccessHandler(response: any) {
    // console.log('payment-success', response);
    // console.log('payment_id', response.razorpay_payment_id);
    this.razorpay_payment_id = response.razorpay_payment_id;
    localStorage.setItem('tvProgramPaymentID', this.razorpay_payment_id);

    if (this.getScreenWidth >= 480) {
      this.ngZone.run(() =>
        this.dialog.open(SuccessDialogComponent, {
          disableClose: true,
          data: this.razorpay_payment_id,
        })
      );
    } else {
      this.ngZone
        .run(() => this.router.navigateByUrl('/sponsor-a-tv-program/success'))
        .then();
    }
    this.cd.detectChanges();
  }
  // validations for numberOnly
  numberOnly(event: any) {
    let newValue = event.target.value.replace(/[^0-9]/g, '');
    console.log('alphabets', newValue);
    this.tvProgramRequestDetailsForm.controls['phone'].setValue(newValue);
  }
  AmountValidations(event: any) {
    this.amountValidate = event.target.value;
    console.log('key', event.key);
    let newValue = event.target.value.replace(/[^0-9]/g, '');
    console.log('alphabets', newValue);
    if (event.target.value.length === 0 && event.key === '0') {
      event.preventDefault();
      // this.submitted = true;
      // this.totalAmount = '';
    } else {
      this.tvProgramRequestForm.controls['amount'].setValue(newValue);
    }
  }
  AlphabetsOnly(event: any) {
    let newValue = event.target.value.replace(/[^a-zA-Z ]/g, '');
    console.log('alphabets', newValue);
    this.tvProgramRequestDetailsForm.controls['name'].setValue(newValue);
  }

  // errors message controls
  get f(): { [key: string]: AbstractControl } {
    return this.tvProgramRequestForm.controls;
  }
  get e(): { [key: string]: AbstractControl } {
    return this.tvProgramRequestDetailsForm.controls;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    console.log(this.getScreenWidth, this.getScreenHeight);
  }
}
