import { FooterRowOutlet } from '@angular/cdk/table';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactusComponent } from './contactus/contactus.component';
import { SuccessdialogContactmobileComponent } from './contactus/successdialog-contactmobile/successdialog-contactmobile.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { DisclamerPolicyComponent } from './disclamer-policy/disclamer-policy.component';
import { DonatePaymentGatewayComponent } from './donate/donate-payment-gateway/donate-payment-gateway.component';
import { MobileSuccessViewDonateComponent } from './donate/donate-payment-gateway/mobile-success-view-donate/mobile-success-view-donate.component';
import { DonateComponent } from './donate/donate.component';
import { LearmoreComponent } from './donate/learmore/learmore.component';
import { FAQComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { MobileSuccessViewComponent } from './prayersrequest/prayer-request-details/mobile-success-view/mobile-success-view.component';
import { PrayerRequestDetailsComponent } from './prayersrequest/prayer-request-details/prayer-request-details.component';
import { PrayersrequestComponent } from './prayersrequest/prayersrequest.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { MobileSuccessViewTvMinistryComponent } from './tv-programs/tv-program-payment-gateway/mobile-success-view-tv-ministry/mobile-success-view-tv-ministry.component';
import { TvProgramPaymentGatewayComponent } from './tv-programs/tv-program-payment-gateway/tv-program-payment-gateway.component';
import { TvProgramsComponent } from './tv-programs/tv-programs.component';
import { WatchVideoComponent } from './watch/watch-video/watch-video.component';
import { WatchComponent } from './watch/watch.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'Home', component: HomePageComponent },
  {
    path: 'sponsor-a-tv-program',
    component: TvProgramsComponent,
  },
  {
    path: 'sponsor-a-tv-program',
    children: [
      {
        path: 'payment',
        component: TvProgramPaymentGatewayComponent,
      },
      {
        path: 'success',
        component: MobileSuccessViewTvMinistryComponent,
      },
    ],
  },

  { path: 'make-a-donation', component: DonateComponent },
  {
    path: 'make-a-donation',
    children: [
      {
        path: 'details',
        component: DonatePaymentGatewayComponent,
      },
      {
        path: 'success',
        component: MobileSuccessViewDonateComponent,
      },
    ],
  },
  { path: 'Watch', component: WatchComponent },
  {
    path: 'Watch',
    children: [
      {
        path: 'video',
        component: WatchVideoComponent,
      },
    ],
  },
  { path: 'footer', component: FooterComponent },
  { path: 'FAQ', component: FAQComponent },
  { path: 'submit-a-prayer-request', component: PrayersrequestComponent },
  {
    path: 'submit-a-prayer-request',
    children: [
      { path: 'details', component: PrayerRequestDetailsComponent },
      { path: 'success', component: MobileSuccessViewComponent },
    ],
  },

  // { path: 'About-Us', component: AboutUsComponent },
  { path: 'Contact-Us', component: ContactusComponent },
  {
    path: 'Contact-Us',
    children: [
      { path: 'success', component: SuccessdialogContactmobileComponent },
    ],
  },
  { path: 'Learn-More', component: LearmoreComponent },
  { path: 'About-us', component: AboutUsComponent },
  { path: 'Privacy-Policy', component: PrivacyPolicyComponent },
  { path: 'Term-Of-Service', component: TermsOfUseComponent },
  { path: 'Payment-Policy', component: CookiePolicyComponent },
  { path: 'Disclaimer-Policy', component: DisclamerPolicyComponent },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
