import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';

@Component({
  selector: 'app-mobile-success-view-tv-ministry',
  templateUrl: './mobile-success-view-tv-ministry.component.html',
  styleUrls: ['./mobile-success-view-tv-ministry.component.css'],
})
export class MobileSuccessViewTvMinistryComponent implements OnInit {
  GreetingCardName: any;
  details: any;
  Services: any = 'Service';
  title: any;
  tvProgramPaymentID: string;
  constructor(
    private router: Router,
    private Service: PrayerRequestService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.tvProgramPaymentID = localStorage.getItem('tvProgramPaymentID');
    console.log(this.tvProgramPaymentID);
    this.Service.prayerRequestBanner(this.Services).subscribe(
      (response: any) => {
        console.log(response);
        this.GreetingCardName = response.data.buttonName;
        this.details = response.data.details;
        this.title = response.data.title;
      }
    );
  }
  backToPrograms() {
    document.body.removeAttribute('style');
    this.ngZone
      .run(() => this.router.navigateByUrl('/sponsor-a-tv-program'))
      .then();
    localStorage.removeItem('tvProgramPaymentID');
  }
}
