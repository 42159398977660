<div class="main">
  <div class="container">
    <div class="congrats">
      <img src="../../assets/images/thank.gif" class="success_Img" />
    </div>
    <div class="thank_you-section">
      <!-- <h4>Prayer Request Submitted Successfully</h4> -->
      <span>Dear {{data.name}}</span>
      <span>We received your prayer request </span>
      <span> మీ ప్రార్ధన అవసరత మాకు అందింది </span>
      <p>
        Your prayer request regarding <strong>{{data.titleN}}</strong> is sent to the Calvary temple prayer warriors.They shall begin to pray for your problem now.May the LORD shall help you!</p>
      <p> మీ ప్రార్ధన అవసరత కొరకు కల్వరి టెంపుల్ ప్రేయర్ వారియర్ టీం ప్రార్ధన చెయ్యడం ప్రారంభిస్తారు.దేవుడు మీకు త్వరలోసహాయము చేయును </p>
      <div class="reponse_details mb-2">
        <!-- <h6>Pray Request ID: {{ data | slice: 0:6 }}</h6> -->
        <!-- <div class="response_details_1">
          <h5 class="pt-2 mt-0">
            While you keep waiting for the response to your prayer request - We
            encourage you to watch the following message by Bro.Satish Kumar
          </h5>
          <h5 class="p-0 pb-2"></h5>
        </div> -->
      </div>
    </div>
    <div class="greeting_card_section mt-1">
      <!-- <div
        class="greeting_card_details_main"
        style="background-image: url(../../assets/images/Banner.png)"
      >
        <div class="greeting_card_details">
          <h5>“{{ title }}”</h5>
          <p>{{ details }}.</p>
          <div class="greeting_card_btn">
            <button class="btn">{{ GreetingCardName }}</button>
          </div>
        </div>
      </div> -->
      <!-- <div class="">
        <iframe
          class="loaders"
          poster="../../assets/images/v-tag.png"
          [src]="youtubeVideo | safePipe"
          loading="lazy"
        ></iframe>
      </div> -->
      <div class="text-center mt-4 mb-2 request-prayer pr-3">
        <button class="btn proceed" routerLink="/submit-a-prayer-request" (click)="backTOPrayerRequest()">
          <img src="../../assets/images/arr-left-1.svg" class="arr_left" /> Back
          to Prayer Request
        </button>
      </div>
    </div>
  </div>
</div>