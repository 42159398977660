import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { MixpanelService } from 'src/app/_services/mixpanel.service';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';

@Component({
  selector: 'app-success-dialog-donate',
  templateUrl: './success-dialog-donate.component.html',
  styleUrls: ['./success-dialog-donate.component.css'],
})
export class SuccessDialogDonateComponent implements OnInit {
  GreetingCardName: any;
  details: any;
  title: any;
  Amount: any;
  Services: any = 'Service';
  data: any;
  dataloading = false;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: any,
    private Service: PrayerRequestService,
    private ngZone: NgZone,
    private mixpanelService : MixpanelService
  ) {
    this.data = data._id;
  }
  options: AnimationOptions = {
    path: '../../assets/images/congrats2.json',
  };

  ngOnInit(): void {
    this.trackEvent()
    this.Service.prayerRequestBanner(this.Services).subscribe(
      (response: any) => {
        console.log(response);
        this.GreetingCardName = response.data.buttonName;
        this.details = response.data.details;
        this.title = response.data.title;
      }
    );

    this.Amount = localStorage.getItem('amount');
  }
  trackEvent() {
    this.mixpanelService.track('Give to God Payment Success Web', { /* optional event properties */ });
  }
  backToDonate() {
    this.router.navigate(['/make-a-donation']);
    // var element = document.getElementsByTagName('body') ;
    // element.style.removeProperty('overflow');
    document.body.removeAttribute('style');
    // this.ngZone
    //   .run(() => this.router.navigateByUrl('/Donate'))
    //   .then();

    // this.router.navigate(['/']);
    localStorage.removeItem('amount');
    localStorage.removeItem('Donate_id');
    this.dialog.closeAll();
  }
}
