<div class="main" id="top_scroll" >
  <app-navbar></app-navbar>
 

  <div class="select_donation_section">
    <!-- <div class="">
        <img src="../../assets/images/Donate.png" class="donate_img" />
      </div> -->

    <div class="payment_select_cards_section">
      <div class="payment_section_details">
        <!-- <h3>Give a hand to lift up in need</h3> -->
        <div class="card card_donate">
          <div class="card-body">
            <h5>GIVE NOW</h5>
            <!-- <p>Give To God</p> -->
            <form [formGroup]="DonateModalForm">
              <div class="form-group">
                <select
                 
                  formControlName="DonateTitle"
                  placeholder="Language"
                  (change)="onChange($event)"
                >
                  <option value="" selected disabled hidden>Select (Optional)</option>
                  <option
                    value="{{ title._id }}"
                    *ngFor="let title of DonatetitleData"
                    
                  >
                    {{ title.title }}
                  </option>
                </select>

                <!-- <div
                  *ngIf="f.DonateTitle.errors?.required && submitted"
                  class="invalid-feedback d-block"
                >
                  Please select Type
                </div> -->
              </div>
              <!-- <div
              class="btn-group btn_option_single btn-group-toggle toggle_2"
              data-toggle="buttons"
            >
              <label class="btn select_payment_option">
                <div class="d-flex">
                  <input
                    type="radio"
                    name="Amounts"
                    id="option1"
                    class="mr-2 checked-1"
                  />

                  <h1>One Time Payment</h1>
                </div>
              </label>
              <label class="btn select_payment_option">
                <div class="d-flex">
                  <input
                    type="radio"
                    name="Amounts"
                    id="option2 "
                    class="mr-2 checked-1"
                  />
                  <h1>Monthly</h1>
                </div>
              </label>
            </div> -->
              <p>Choose Amount</p>
              <div class="btn_multiple_option">
                <div class="btn-toogle-11">
                  <input
                    id="50"
                    type="radio"
                    class="radio-custom"
                    formControlName="Amount"
                    value="50"
                    (change)="changeHandlers($event)"
                  />

                  <label for="50" class="btn toggle_btns">
                    <p>₹50</p>
                  </label>
                </div>
                <div class="btn-toogle-11">
                  <input
                    id="500"
                    type="radio"
                    class="radio-custom"
                    formControlName="Amount"
                    value="500"
                    (change)="changeHandlers($event)"
                  />
                  <label class="btn toggle_btns" for="500">
                    <p>₹500</p>
                  </label>
                </div>
                <div class="btn-toogle-11">
                  <input
                    id="1000"
                    type="radio"
                    class="radio-custom"
                    formControlName="Amount"
                    value="1000"
                    (change)="changeHandlers($event)"
                  />

                  <label for="1000" class="btn toggle_btns">
                    <p>₹1000</p>
                  </label>
                </div>
                <div class="btn-toogle-11">
                  <input
                    id="5000"
                    type="radio"
                    class="radio-custom"
                    formControlName="Amount"
                    value="5000"
                    (change)="changeHandlers($event)"
                  />

                  <label for="5000" class="btn toggle_btns">
                    <p>₹5000</p>
                  </label>
                </div>
                <div class="btn-toogle-11">
                  <input
                    id="10000"
                    type="radio"
                    class="radio-custom"
                    formControlName="Amount"
                    value="10000"
                    (change)="changeHandlers($event)"
                  />

                  <label for="10000" class="btn toggle_btns">
                    <p>₹10000</p>
                  </label>
                </div>
                <div class="btn-toogle-11">
                  <input
                    id="25000"
                    type="radio"
                    class="radio-custom"
                    formControlName="Amount"
                    value="25000"
                    (change)="changeHandlers($event)"
                  />

                  <label for="25000" class="btn toggle_btns">
                    <p>₹25000</p>
                  </label>
                </div>
              </div>

              <!-- <input
                id="female"
                type="radio"
                class="custom-control-input"
                value="female"
                name="Amount"
                formControlName="Amount"
              />femlae -->
              <!-- <label class="custom-control-label" for="female">Female</label> -->
              <!-- <div
                class="btn-group btn_multiple_option btn-group-toggle toggle_1"
                data-toggle="buttons"
              >
                <label class="btn toggle_btns">
                  <input
                    type="radio"
                    formControlName="Amount"
                    name="Amount"
                    id="option3"
                    value="1000"
                  />
                  <p>₹1000</p>
                </label>

                <label class="btn toggle_btns">
                  <input
                    type="radio"
                    name="Amount"
                    formControlName="Amount"
                    id="option4"
                    value="5000"
                  />
                  <p>₹5000</p>
                </label>
                <label class="btn toggle_btns">
                  <input
                    type="radio"
                    name="Amount"
                    formControlName="Amount"
                    id="option5"
                  />
                  <p>₹15000</p>
                </label>
                <label class="btn toggle_btns">
                  <input
                    type="radio"
                    name="Amount"
                    value="15000"
                    formControlName="Amount"
                    id="option6"
                  />
                  <p>₹25000</p>
                </label>
              </div> -->

              <div class="form-group mt-4">
                <p class="mb-0">Enter Other Amount</p>
                <input
                  type="text"
                  class="form-control-1 mt-1"
                  id="Amount"
                  aria-describedby="emailHelp"
                  placeholder="Between ₹1 and ₹500000"
                  autocomplete="off"
                  (keyup)="numberOnly($event)"
                  
                  formControlName="Amount"
                  [(ngModel)]="Value"
                  maxlength="6"
                />
                <div
                  *ngIf="f.Amount.errors?.required && submitted"
                  class="invalid-feedback d-block"
                >
                  Amount is required
                </div>
               
                <div
                  *ngIf="DonateModalForm.value.Amount >= 500001"
                  class="invalid-feedback d-block"
                >
                  Between ₹1 and ₹500000 only allowed
                </div>
              </div>
              <div class="btn-pray">
                <button type="submit" class="btn pay_btn" (click)="proceed()">
                  Proceed to Give
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

 <div class="container">
    <div class="scan_qr_Section mt-md-4">
      <div class="row">
        <div class="col-md-7">
          <div class="scan_qr_details">
            <h4>Scan QR & directly give to the Calvary Temple Foundation.</h4>
            <div class="alternative_payments_btn">
              <!-- <button type="button" class="btn another_payment_btn btn_another">
                Another Payment method
              </button> -->
              <!-- <button
                type="button"
                class="btn another_payment_btn btn_learn"
                routerLink="/Learn-more"
              >
                Learn more
              </button> -->
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="scan_qr-Img_section">
            <img [src]="QRScan" class="qr_img" />
          </div>
          <!-- <div class="alternative_payments_btn_1 mb-4">
            
            <button
              type="button"
              class="btn another_payment_btn btn_learn"
              routerLink="/Learn-more"
            >
              Learn more
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="container account_details_top">
    <div class="prayer_tower_section prayer_tower_Donate mt-5 mb-4">
      <h4 class="text-left">Our Bank Account Details</h4>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <img src="../../assets/images/c-4.svg" class="t_1 mr-2 mt-2" />
            <h3 class="mt-3">CALVARY TEMPLE FOUNDATION</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">59100411230411</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">HDFC0003777</h4>
            </div>
            <div class="prayer_languges language_details">
              <h4 class="details_1">Hydernagar</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <img src="../../assets/images/c-1.svg" class="t_1 mr-2 mt-2" />
            <h3 class="mt-3">Calvary Temple Foundation</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">1119 1110 001</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">UBIN0811190</h4>
            </div>
            <div class="prayer_languges language_details">
              <h4 class="details_1">Kukatpally</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <img src="../../assets/images/c-2.svg" class="t_1 mr-2 mt-2" />
            <h3 class="mt-3">Calvary Temple Foundation</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">6312 1110 701</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">ICIC000631</h4>
            </div>
            <div class="prayer_languges language_details">
              <h4 class="details_1">Kukatpally</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <img src="../../assets/images/c-3.svg" class="t_1 mr-2 mt-2" />
            <h3 class="mt-3">Calvary Temple Foundation</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">9140 1001 668</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">UTIB0000193</h4>
            </div>
            <div class="prayer_languges language_details">
              <h4 class="details_1">Kukatpally</h4>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="telugu_prayer_tower">
      <div class="row">
        <div class="col-md-5">
          <div class="col d-flex prayer_languges">
            <img src="../../assets/images/c-4.svg" class="t_1 mr-2 mt-2" />
            <h3 class="mt-3">VOC Foundation</h3>
          </div>
        </div>
        <div class="col-md-7">
          <div class="d-flex">
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right">50200047532370</h4>
            </div>
            <hr class="line-hor" />
            <div class="prayer_languges language_details">
              <h4 class="details_1 border_right"> HDFC0003777</h4>
            </div>
            <div class="prayer_languges language_details">
              <h4 class="details_1">Hydernagar</h4>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- <div class="container">
    <div class="payment-gateway">
      <div class="payment_selection_methods_section">
        <div class="payment_selection_name mb-4">
          <h5>Choose a payment method</h5>
        </div>
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="disable_ripple">
              <div class="net_banking d-flex">
                <i class="card-1" aria-hidden="true"
                  ><img src="../../assets/images/card-2.svg"
                /></i>
                <div class="net_banking_selection">
                  <h5>Netbanking</h5>
                  <h6>All indian banks</h6>
                </div>
              </div>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="disable_ripple">
              <div class="net_banking d-flex">
                <i class="card-1" aria-hidden="true"
                  ><img src="../../assets/images/card-1.svg"
                /></i>
                <div class="net_banking_selection">
                  <h5>Card</h5>
                  <h6>Visa, Mastercard, RuPay, and more</h6>
                </div>
              </div>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="disable_ripple">
              <div class="net_banking d-flex">
                <img src="../../assets/images/upi-1.svg" class="upi" />
                <div class="net_banking_selection">
                  <h5>UPI</h5>
                  <h6>Google Pay, PhonePe, and more</h6>
                </div>
              </div>
            </mat-expansion-panel-header>
            
            <div class="online_payment-section">
              
              <input
                class="form-check-input mr-3"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="option1"
              />
              <div class="d-flex online_payment-btns ml-4">
                <img
                  src="../../assets/images/p-11.svg"
                  class="phone_pe_icons"
                />
                <label for="exampleRadios1" class="cursor">
                  <h4>PhonePe</h4></label
                >
              </div>
            
            </div>
            <div class="online_payment-section">
              
              <input
                class="form-check-input mr-3 cursor"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="option1"
              />
              <div class="d-flex online_payment-btns ml-4">
                <img
                  src="../../assets/images/p-22.svg"
                  class="phone_pe_icons"
                />

                <label for="exampleRadios2" class="cursor">
                  <h4>Google Pay</h4></label
                >
              </div>
            
            </div>
            <div class="online_payment-section">
              
              <input
                class="form-check-input mr-3"
                type="radio"
                name="exampleRadios"
                id="exampleRadios3"
                value="option1"
              />
              <div class="d-flex online_payment-btns ml-4">
                <img
                  src="../../assets/images/p-33.svg"
                  class="phone_pe_icons"
                />
                <label for="exampleRadios3" class="cursor">
                  <h4>PayTm</h4></label
                >
              </div>
            
            </div>
            <p id="upi_id">Or Enter UPI ID</p>
            <div class="online_payment-section mb-4">
              <input placeholder="Enter UPI Id" />
            </div>
           </mat-radio-group>  
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="d-flex">
        <div class="request-prayer mr-2">
          <button class="btn proceed">Proceed</button>
        </div>
        <div class="request-prayer qr-btn ml-2">
          <button class="btn">Scan QR <span class="ml-1"> Code</span></button>
        </div>
      </div>
    </div>
  </div> -->

 
  <app-footer></app-footer>
</div>
