import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { NotallzerosDirective } from '../_helpers/notallzeros.directive';
import { MixPanelService } from '../_services/mix-panel.service';
import { PrayerRequestService } from '../_services/prayer-request.service';
import { MixpanelService } from '../_services/mixpanel.service';
declare const notAllzeros: any;
declare const onlynumbers: any;
@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.css'],
})
export class DonateComponent implements OnInit {
  fontStyleControl = new FormControl('');
  fontStyle?: string;
  checked: any = false;
  panelOpenState = false;
  DonateModalForm: FormGroup;
  submitted: any = false;
  DonateToggleBtns: any;
  Value: any;
  QRScan: any;
  rzp1: any;
  DonatetitleData: any;
  QrScan: any;
  QRScanFilter: any;
  titleSelected: any;
  constructor(
    private route: Router,
    private formBuilder: FormBuilder,
    private router: ActivatedRoute,
    private el: ElementRef,
    private Services: PrayerRequestService,
    private mixpanelService: MixpanelService,
    private zone: NgZone
  ) {
    this.DonateModalForm = this.formBuilder.group({
      Amount: ['', [Validators.required]],
      DonateTitle: '',
    });
  }

  ngOnInit(): void {
    this.trackEvent()
    // this.titleSelected = 'Special Offering';
    this.DonateModalForm.get('DonateTitle').setValue(
      '6364ad420d28c3840e2fc770'
    );
    notAllzeros();
    onlynumbers();
    this.Services.donateQrCode().subscribe((response) => {
      console.log(response);
      this.QrScan = response.QR_code_url;
      this.QRScanFilter = this.QrScan.filter((item: any) => {
        return item.type == 'Donation';
      });
      console.log(this.QRScanFilter[0].imageUrl);
      this.QRScan = this.QRScanFilter[0].imageUrl;
    });
    this.Services.donateTitle().subscribe((res) => {
      console.log(res);
      this.DonatetitleData = res.data;
    });
  }
  trackEvent() {
    this.mixpanelService.track('Give to God Amount Details Web', { /* optional event properties */ });
  }
  onChange(e: any) {
    console.log(e.target.value);
  }
  proceed() {
    // console.log(this.DonateModalForm.value);
    // console.log(this.DonateModalForm);

    if (
      this.DonateModalForm.invalid ||
      this.DonateModalForm.value.Amount >= 500001 ||
      this.DonateModalForm.value.Amount === '000000' ||
      this.DonateModalForm.value.Amount === '0000' ||
      this.DonateModalForm.value.Amount === '000' ||
      this.DonateModalForm.value.Amount === '00'
    ) {
      this.submitted = true;
      if (
        this.DonateModalForm.value.Amount === '000000' ||
        this.DonateModalForm.value.Amount === '0000' ||
        this.DonateModalForm.value.Amount === '000' ||
        this.DonateModalForm.value.Amount === '00'
      ) {
        this.submitted = true;
      }
    } else {
      this.submitted = false;

      localStorage.setItem('amount', this.DonateModalForm.value.Amount);
      localStorage.setItem('Donate_id', this.DonateModalForm.value.DonateTitle);

      this.route.navigate(['details'], { relativeTo: this.router });
    }
  }
  notAllzeros(event: any) {
    console.log(event);
    if (event.target.value.length === 0 && event.key === '0') {
      event.preventDefault();
      console.error(event.target.value);
    } else {
      console.log(event.target.value);
    }
  }
  changeHandlers(event: any) {
    console.log(event.target.value);
    this.DonateToggleBtns = event.target.value;
    this.Value = this.DonateModalForm.value.Amount;
    console.log(this.Value);
  }
  AmountChangeEvent(e: any) {
    this.checked = false;

    let toggle50 = document.getElementById('50') as unknown as HTMLInputElement;
    let toggle500 = document.getElementById(
      '500'
    ) as unknown as HTMLInputElement;
    let toggle1000 = document.getElementById(
      '1000'
    ) as unknown as HTMLInputElement;
    let toggle5000 = document.getElementById(
      '5000'
    ) as unknown as HTMLInputElement;
    let toggle10000 = document.getElementById(
      '10000'
    ) as unknown as HTMLInputElement;
    let toggle25000 = document.getElementById(
      '25000'
    ) as unknown as HTMLInputElement;
    if (
      toggle50 != null ||
      toggle500 != null ||
      toggle1000 != null ||
      toggle5000 != null ||
      toggle10000 != null ||
      toggle25000 != null
    ) {
      toggle50.checked = false;
      toggle500.checked = false;
      toggle1000.checked = false;
      toggle5000.checked = false;
      toggle10000.checked = false;
      toggle25000.checked = false;
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.DonateModalForm.controls;
  }
  // validations for numberOnly
  // numberOnly(event: any): boolean {
  //   let patt = /^([0-9])$/;
  //   let result = patt.test(event.key);
  //   return result;
  // }
  numberOnly(event: any) {
    let newValue = event.target.value.replace(/[^0-9]/g, '');
    console.log('alphabets', newValue);
    this.DonateModalForm.controls['Amount'].setValue(newValue);
  }
}
