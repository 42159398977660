import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrayerRequestService } from 'src/app/_services/prayer-request.service';

@Component({
  selector: 'app-prayer-request-success-dialog',
  templateUrl: './prayer-request-success-dialog.component.html',
  styleUrls: ['./prayer-request-success-dialog.component.css'],
})
export class PrayerRequestSuccessDialogComponent implements OnInit {
  data: any;
  Services: any = 'Service';
  GreetingCardName: any;
  details: any;
  title: any;
  queryParams: any;
  weekMessge: any;
  youtubeVideo: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    public dialog: MatDialog,
    public Service: PrayerRequestService
  ) {
    this.data = data;
    console.log('Received data in dialog:', data);
  }

  ngOnInit(): void {
    console.log(this.data);
    this.Service.prayerRequestBanner(this.Services).subscribe(
      (response: any) => {
        console.log(response);
        this.GreetingCardName = response.data.buttonName;
        this.details = response.data.details;
        this.title = response.data.title;
      }
    );
    this.Service.WatchVideoRequestFliter(this.queryParams == null).subscribe(
      (response) => {
        console.log(response);

        this.weekMessge = response.data[0];
        // console.log(this.weekMessge);
        // this._id = this.weekMessge._id;
        // this.title = this.weekMessge.title;

        this.youtubeVideo = this.weekMessge?.youtubeVideo.replace(
          'watch?v=',
          'embed/'
        );
      }
    );
  }
  backTOPrayerRequest() {
    this.dialog.closeAll();
  }
}
