import { Injectable } from '@angular/core';
declare const mixpanel: any; 

let token = 'dcce274777ed7e9fb61456a9ec73fcb3';
// let token = '68d8841d19051efc5ca5a6dc2d57a9b6'; //prod

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() {
    this.initialize(token) 
   }

   initialize(token: string) {
    mixpanel.init(token); // Initialize Mixpanel with your project token
  }


  track(eventName: string, properties?: any) {
    const updatedProperties = { ...properties, '$os': 'Web' }
    mixpanel.track(eventName, updatedProperties); // Track an event with optional properties
  }
}
