<div class="main">
  <div class="container">
    <div class="congrats">
      <img src="../../assets/images/thank.gif" class="success_Img" />
    </div>

    <div class="thank_you-section">
      <h4>Thankyou!</h4>
      <p>
        Your amount of ₹{{ Amount }} has been successfully donated to Calvary
        Temple Foundation.
      </p>
      <h5 class="text-center">Payment Id: {{ data }}</h5>
    </div>

    <div class="greeting_card_section">
      <!-- <img src="../../assets/images/card-image-1.png" class="greeting_card" /> -->

      <div
        class="greeting_card_details_main"
        style="background-image: url(../../assets/images/donate-baaner.webp)"
      >
        <!-- <div class="greeting_card_details">
          <h5>“{{ title }}”</h5>
          <p>{{ details }}.</p>
          <div class="greeting_card_btn">
            <button class="btn">{{ GreetingCardName }}</button>
          </div>
        </div> -->
        <!-- <div class="greeting_card_details" *ngIf="!title">
          <h5>Loading...</h5>
        </div> -->
      </div>
    </div>

    <div class="text-centre mt-5 mb-3 request-prayer">
      <button class="btn back_to_btn" (click)="backToDonate()">
        <img src="../../assets/images/arr-left-1.svg " class="arr_left" />
        Back to Donate
      </button>
    </div>
  </div>
</div>
